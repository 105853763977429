import React from "react";
import CommunityPosts from "./CommunityPosts";
import AddPost from "./AddPost";

export default function CommunityLayout() {
  return (
    <section className="flex flex-col gap-5 items-center">
      <AddPost />
      <CommunityPosts />
    </section>
  );
}
