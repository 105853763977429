import React, { useEffect, useState } from "react"
import ScrapbookTabs from "./ScrapbookTabs";
import { Outlet, useLocation } from "react-router-dom";

const ScrapbookTemplate = () => {
    const { pathname } = useLocation();
    const [activeLink, setActiveLink] = useState(pathname);
    useEffect(() => {
        setActiveLink(pathname.split("/")[3]);
    }, [pathname, activeLink]);
    return (
        <section className="scrapbook-images">
            <ScrapbookTabs actions={activeLink === "images" ? ["Delete", "Add Image"] : activeLink === "notes" ? ["Add Note"] : activeLink === "voice-memos" ? ["Add Voice Memo"] : activeLink === "links" ? ["Add Link"] : null} />
            <Outlet />
        </section>
    )
}

export default ScrapbookTemplate;