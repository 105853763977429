import { createContext, useState } from "react";

export let DeleteProjectsContext = createContext();

export default function DeleteProjectsContextProvider({ children }) {
    const [openDeleteCheckbox, setOpenDeleteCheckbox] = useState(false);
    const [deleteProjects, setDeleteProjects] = useState([]);

    return (
        <DeleteProjectsContext.Provider value={{ openDeleteCheckbox, setOpenDeleteCheckbox, deleteProjects, setDeleteProjects }}>
            {children}
        </DeleteProjectsContext.Provider>
    );
}
