import { AuthenticateWithRedirectCallback } from "@clerk/clerk-react";
import { AuthContext } from "Context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MainLoader = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  const [isSignUp, setIsSignUp] = useState(false);

  useEffect(() => {
    if (pathname === "/sso-callback" || pathname === "/sso-callback#_=_") {
      setIsSignUp(true);
    }
  }, [pathname]);

  return (
    <>
      {isSignUp && (
        <AuthenticateWithRedirectCallback
          afterSignInUrl="/loading"
          afterSignUpUrl="/loading"
        />
      )}
      <div
        className={`loading fixed inset-0 z-[9999999999] bg-white flex justify-center items-center transition-all duration-500`}
      >
        <div className="load w-[75px] h-[75px] flex justify-center items-center relative">
          <div className="spinner"></div>
          <div className="child-load"></div>
        </div>
      </div>
    </>
  );
};

export default MainLoader;
