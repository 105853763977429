import React from "react";
import PostLayout from "./PostLayout";
import linkImg from "../../Assets/landing/feedbackCardImg.svg";
import postImg from "../../Assets/community/post-img.svg";

export default function CommunityPosts() {
  return (
    <div className="bg-white rounded-4xl flex flex-col gap-5 border-2 border-solid border-grayBorder p-10 w-full no-focus">
      <PostLayout
        userImg={linkImg}
        userName={"Maya Rodriguez"}
        time={"21 minutes"}
        content={
          "I’m testing what a community post is and here’s the example..."
        }
        likesCount={28}
        commentsCount={7}
      />
      <PostLayout
        userImg={linkImg}
        userName={"Maya Rodriguez"}
        time={"21 minutes"}
        content={
          "I’m testing what a community post is and here’s the example..."
        }
        likesCount={28}
        commentsCount={7}
        postImg={postImg}
      />
    </div>
  );
}
