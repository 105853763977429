import { Input } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import emailIcon from "../../Assets/auth/sms.svg";
import passIcon from "../../Assets/auth/lock.svg";
import visibleEye from "../../Assets/auth/eye.svg";
import hiddenEye from "../../Assets/auth/eye-slash.svg";
import { Link, useNavigate } from "react-router-dom";

import * as Yup from "yup";
import { useFormik } from "formik";
import { AuthContext } from "Context/AuthContext";
import {
  useSignIn,
  GoogleOneTap,
  SignUp,
  useSignUp,
  useClerk,
} from "@clerk/clerk-react";
import axios from "axios";
import { useUser } from "@clerk/clerk-react";
import { Error500Context } from "Context/Error500Context";

const Login = () => {
  const socialIcons = require.context("../../Assets/auth/social", true);
  const socialIconsList = socialIcons.keys().map((image) => socialIcons(image));

  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);
  const { isLoaded, isSignedIn, user } = useUser();

  const authInfo = [
    { method: "oauth_google", label: "Google" },
    { method: "oauth_facebook", label: "Facebook" },
    // {method: "oauth_twitter", label: "Twitter"},
    { method: "oauth_apple", label: "Apple" },
  ];

  //integration part

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { signUp } = useSignUp();

  const navigate = useNavigate();
  const { signOut } = useClerk();

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email required"),
    password: Yup.string()
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>\/?`~\-])/,
        "Password must contain small, capital, number and special character"
      )
      .min(8, "Weak password")
      .max(30, "Password is too long")
      .required("Password required"),
  });

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: callLogin,
  });

  const { session } = useClerk();

  const { setToken } = useContext(AuthContext);
  const { signIn, setActive } = useSignIn();
  const { setIsServerError } = useContext(Error500Context);

  async function callLogin(reqBody) {
    if (session) {
      session?.end();
      signOut({ redirectUrl: "/login" });
    }
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);
    try {
      const signInAttempt = await signIn.create({
        identifier: reqBody.email,
        password: reqBody.password,
      });

      // If sign-in process is complete, set the created session as active
      // and redirect the user
      if (signInAttempt.status === "complete") {
        navigate("/loading");
        await setActive({ session: signInAttempt.createdSessionId });

        await axios
          .post("/user", {
            name:
              signInAttempt.userData.firstName +
              " " +
              signInAttempt.userData.lastName,
            email: reqBody.email,
            password: reqBody.email,
            img_url: user.imageUrl,
          })
          .then((response) => {
            let authToken = localStorage.setItem(
              "token",
              response.data.response.data.token
            );
            setToken(response.data.response.data.token);

            axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
            setIsLoading(false);
            console.log(user.imageUrl);
          })
          .catch((err) => {
            if (err?.response?.status === 500) {
              setIsServerError(true);
              navigate("/server-error");
            } else {
              setErrorMessage(err.errors ? err.errors[0].message : err.message);
            }
          });
      } else {
        // If the status is not complete, check why. User may need to
        // complete further steps.
        setErrorMessage(signInAttempt);
        setIsLoading(false);
      }
    } catch (error) {
      // See https://clerk.com/docs/custom-flows/error-handling
      // for more info on error handling
      setErrorMessage(error.errors ? error.errors[0].message : error.message);
      setIsLoading(false);
    }
    setIsDisabled(false);
  }

  //OAuth

  const signInWith = async (strategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/loading",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="login xl:h-[calc(100vh-85px)] flex justify-center xl:items-center relative overflow-x-hidden xl:py-0 pb-[100px] pt-[15px]">
      <div className="absolute bottom-0 left-0 w-[450px] h-[412px] bg-authImg1 bg-cover bg-no-repeat z-0"></div>
      <div className="absolute top-[30px] right-0 w-[214px] h-[287px] bg-authImg2 bg-cover bg-no-repeat z-0"></div>
      <div className="container xl:px-44 sm:px-6 px-4 mx-auto relative z-10">
        <div className="box sm:mx-auto sm:w-[395px] w-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white">
          <h2 className="font-bold text-[20.72px] mb-6 text-center">
            Welcome Back!
          </h2>
          <div className="login-form mb-9">
            {errorMessage && (
              <div className="text-white bg-cardColor py-3 mb-10 text-center rounded-15px text-sm">
                <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                  {errorMessage}
                </span>
              </div>
            )}
            <form onSubmit={loginForm.handleSubmit}>
              <div className="mb-1.5">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  variant="bordered"
                  placeholder="Enter your email"
                  labelPlacement="outside"
                  startContent={<img src={emailIcon} />}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.email}
                  isInvalid={loginForm.errors.email}
                  errorMessage={
                    loginForm.errors.email && loginForm.touched.email
                      ? loginForm.errors.email
                      : null
                  }
                />
              </div>
              <div className="text-right mb-10">
                <Link
                  to={"/forget-password"}
                  className="text-[#424C9B] text-[13.81px] font-normal relative top-5"
                >
                  Forgot Password?
                </Link>
                <Input
                  name="password"
                  label="Password"
                  variant="bordered"
                  labelPlacement="outside"
                  placeholder="Enter your password"
                  startContent={<img src={passIcon} />}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <img src={hiddenEye} />
                      ) : (
                        <img src={visibleEye} />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={loginForm.handleChange}
                  onBlur={loginForm.handleBlur}
                  value={loginForm.values.password}
                  isInvalid={loginForm.errors.password}
                  errorMessage={
                    loginForm.errors.password && loginForm.touched.password
                      ? loginForm.errors.password
                      : null
                  }
                />
              </div>
              <button
                type="submit"
                disabled={!(loginForm.isValid && loginForm.dirty) || isDisabled}
                className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center text-white">
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </span>
                ) : (
                  "Log In"
                )}
              </button>
            </form>
          </div>
          <div className="continue text-center flex gap-8 items-center justify-center mb-8">
            <span className="bg-cardGrayColor h-[0.86px] flex-grow"></span>
            <span className="text-cardGrayColor text-[13.81px]">
              Or continue with
            </span>
            <span className="bg-cardGrayColor h-[0.86px] flex-grow"></span>
          </div>
          <div className="social flex sm:flex-nowrap flex-wrap justify-center items-center gap-4 mb-8">
            {socialIconsList.map((icon, idx) => (
              <button
                className="w-[66px] h-[66px]"
                onClick={() => signInWith(authInfo[idx].method)}
                key={idx}
              >
                <div className="icon flex justify-center items-center p-[17.26px] rounded-[8.63px] bg-socialIconBg">
                  <img src={icon} alt={authInfo[idx].label} />
                </div>
              </button>
            ))}
          </div>

          <div className="sign-up text-center text-[13.81px]">
            Don’t have an account?
            <Link
              to={"/signup"}
              className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
            >
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
