import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import RemoveComponent from "../../pages/CreateAFrond/RemoveComponent";
import "./modalStyle.css";
import { AuthContext } from "Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import locationIcon from "../../Assets/create-frond/location.svg";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import debounce from "lodash.debounce";
import tempMap from "../../Assets/create-frond/temp-map.png";

const EditLocationModal = ({
  isOpen,
  onClose,
  frondData,
  getFrondData,
  isModalOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [address, setAddress] = useState(frondData.editLocation);
  const [location, setLocation] = useState({
    latitude: frondData?.editLatitude || null,
    longitude: frondData?.editLongitude || null,
  });

  const [city, setCity] = useState(frondData?.editCity || "");
  const [country, setCountry] = useState(frondData?.editCountry || "");

  useEffect(() => {
    console.log(frondData);
  }, [frondData]);

  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);
  const [mapLoaded, setMapLoaded] = useState(false);

  const labelHtml = `Location <span class='text-xs text-cardGrayColor'>(Optional)</span>`;

  useLayoutEffect(() => {
    setAddress(frondData.editLocation);
    if (frondData.editLocation) {
      setValue("location", frondData.editLocation);
    }
  }, [frondData.editLocation]);

  useLayoutEffect(() => {
    setAddress(address);
    if (address) {
      setValue("location", address);
    }
  }, [address]);

  const provider = new OpenStreetMapProvider();

  useEffect(() => {
    if (address) {
      if (!location.latitude || !location.longitude) {
        handleSearch(address);
      } else {
        setPosition([location.latitude, location.longitude]);
        handleSearch(address);
      }
    } else {
      setPosition([0, 0]);
      setIsShowMap(true);
    }
  }, [address]);

  useEffect(() => {
    if (frondData.editLatitude && frondData.editLongitude) {
      setPosition([frondData.editLatitude, frondData.editLongitude]);
    }
  }, [frondData, frondData.editLocation]);

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  const handleSearch = useCallback(
    debounce(async (query) => {
      const results = await provider.search({ query });
      if (results.length > 0) {
        const { x, y } = results[0];
        setPosition([y, x]);
        setLocation({ latitude: y, longitude: x });
        setIsShowMap(false);
      } else {
        setIsShowMap(true);
      }
    }, 500),
    []
  );

  const handleInputChange = (e) => {
    const query = e.target.value;
    setAddress(query);
    if (query.length > 3) {
      handleSearch(query);
    } else {
      setIsShowMap(true);
    }
  };

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  useEffect(() => {
    if (!frondData.editLocation && !address) {
      getLocation();
    }
  }, [frondData.editLocation]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      toast("Geolocation is not supported by this browser.", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const showPosition = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    setLocation({ latitude, longitude });

    try {
      const response = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
      );
      setCity(response?.data?.city);
      setCountry(response?.data?.countryName);
      setAddress(response?.data?.city);
      setPosition([latitude, longitude]);
      setIsShowMap(false);
    } catch (error) {
      setIsShowMap(true);
      toast("Error fetching country name", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        break;
      case error.POSITION_UNAVAILABLE:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("Location information is unavailable.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      case error.TIMEOUT:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("The request to get user location timed out.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      case error.UNKNOWN_ERROR:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("An unknown error occurred.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
      default:
        setCity("");
        setCountry("");
        setAddress("");
        setPosition([0, 0]);
        setValue("location", "");
        toast("An unknown error occurred.", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
        break;
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: frondData?.editLocation,
    },
  });

  const { token } = useContext(AuthContext);

  async function editFrond(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    if (country && city) {
      try {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/frond-location/${frondData.editId}?_method=PUT`,
          {
            longitude: position[1] ? position[1] : null,
            latitude: position[0] ? position[0] : null,
            location: reqBody.location,
            country: country ? country : "Country",
            city: city ? city : "City",
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 200) {
          toast("Location Edited Successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          getFrondData();
          onClose();
          reset();
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    } else {
      getCityCountry(position[0], position[1]);
      try {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/frond-location/${frondData.editId}?_method=PUT`,
          {
            longitude: position[1] ? position[1] : null,
            latitude: position[0] ? position[0] : null,
            location: reqBody.location,
            country: country ? country : "Country",
            city: city ? city : "City",
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 200) {
          toast("Location Edited Successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          getFrondData();
          onClose();
          reset();
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  const getCityCountry = async (position) => {
    const latitude = position[0];
    const longitude = position[1];

    console.log(latitude, longitude);

    try {
      const apiKey = "4ca6d111075d4f3baf96605ac6a8a96b";
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}&language=en`
      );
      console.log(response);

      const result = response?.data?.results[0];
      if (result) {
        const city =
          result.components.city ||
          result.components.town ||
          result.components.village ||
          result.components.hamlet;
        const country = result.components.country;
        console.log(`City: ${city}, Country: ${country}`);
        setCity(city);
        setCountry(country);
      } else {
        toast("Location not found", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    } catch (error) {
      toast("Error fetching location data", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (position.length > 0) {
      getCityCountry([position[0], position[1]]);
    }
  }, [position]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Edit Location
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(editFrond)}>
                <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                  <ModalBody className="w-[100%] scroll-inner">
                    <div className="flex flex-col gap-2.5 items-center">
                      <div className="sm:min-h-[224px] sm:max-h-[224px] min-h-[150px] max-h-[150px] sm:w-[310px] w-[100%] relative map-box">
                        <MapContainer
                          center={position}
                          zoom={12}
                          dragging={false}
                          doubleClickZoom={false}
                          zoomControl={false}
                          scrollWheelZoom={false}
                          style={{
                            height: "289.6px",
                            width: "100%",
                            position: "relative",
                            zIndex: "20",
                          }}
                          whenCreated={(map) => {
                            map.on("load", handleMapLoad);
                          }}
                        >
                          <TileLayer
                            eventHandlers={{
                              tileload: handleTileLoad,
                            }}
                            attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
                          />
                          <CustomMarker />
                        </MapContainer>
                        {!mapLoaded && (
                          <div
                            className={`bg-gray-400 rounded-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                              mapLoaded ? "opacity-0 z-10" : "opacity-100 z-30"
                            }`}
                          >
                            <span className="flex gap-2 justify-center items-center text-white">
                              <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                            </span>
                          </div>
                        )}
                        {isShowMap ? (
                          <div
                            className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-[20px] ${
                              isShowMap ? "opacity-100 z-30" : "opacity-0 z-10"
                            }`}
                          >
                            <Image
                              classNames={{ wrapper: "min-w-full h-full" }}
                              src={tempMap}
                              alt="Map"
                              className="size-full"
                            />
                          </div>
                        ) : null}
                      </div>
                      <Controller
                        name="location"
                        control={control}
                        rules={{
                          validate: {
                            noTrailingSpace: (value) =>
                              !/\s$/.test(value) ||
                              "Location shouldn't end with a space",
                          },
                          pattern: {
                            value:
                              /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                            message:
                              "Location may contain characters, numbers and special characters",
                          },
                          required: "Location is required",
                        }}
                        render={({ field }) => (
                          <div className="about-location-wrapper relative group">
                            <div className="absolute left-[-20px] right-[-20px] top-[-10px] z-30 flex justify-center items-center bg-white rounded-2xl p-[5px] border-1 border-solid border-grayBorder transition-all duration-300 opacity-0 group-hover:opacity-100">
                              <span className="text-[13.8px] font-normal text-textColor">
                                Location may contain country, city, town or
                                postcode
                              </span>
                            </div>
                            <Input
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              {...field}
                              type="text"
                              variant="bordered"
                              placeholder="Search Location"
                              labelPlacement="outside"
                              onInput={handleInputChange}
                              startContent={
                                <Image
                                  src={locationIcon}
                                  className="min-w-[18px] max-w-[18px] h-[18px] me-1"
                                />
                              }
                              classNames={{
                                base: "input-noMargin h-[68px] location-field w-full",
                                label: "text-textColor font-normal text-sm",
                                input: "border-none px-[18px]",
                                mainWrapper: "h-[42px]",
                                innerWrapper: "h-[42px] px-[18px]",
                                inputWrapper: `border-[0.86px] p-0 border-solid ${
                                  errors.location?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] h-[42px]`,
                              }}
                              label={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: labelHtml,
                                  }}
                                />
                              }
                              isInvalid={
                                errors.location?.message ? true : false
                              }
                              errorMessage={errors.location?.message}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow">
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] sm:w-[234px] w-[117px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default EditLocationModal;
