import { Image } from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import playIcon from "../../Assets/intro-video/play.svg";
import videoPoster from "../../Assets/frond-works/mainImg.svg";
import introVideo from "../../Assets/intro-video/intro-video.mp4";

const HowFrondWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabsIconsActive = require.context(
    "../../Assets/frond-works/icons/active",
    true
  );
  const tabsIconsNonActive = require.context(
    "../../Assets/frond-works/icons/nonactive",
    true
  );
  const tabsIconsActiveList = tabsIconsActive
    .keys()
    .map((image) => tabsIconsActive(image));
  const tabsIconsNonActiveList = tabsIconsNonActive
    .keys()
    .map((image) => tabsIconsNonActive(image));

  const cardImgs = require.context("../../Assets/frond-works/imgs", true);

  const cardImgsList = cardImgs.keys().map((image) => cardImgs(image));
  const tabsData = [
    {
      name: "Register",
    },
    {
      name: "Create",
    },
    {
      name: "Discover",
    },
    {
      name: "Share",
    },
  ];

  const cardsData = [
    "Setting up a frond account is free and easy. Register a new account and unlock the full potential of available features to help with your personal planning activities. Your account will store your preferences and allow you to create, share and manage all your personal projects on the go.",
    "It only takes a few seconds to create your first project in frond, allowing you to  store and update all the important information, tasks, notes, images, links and voice memos that you cannot lose and need to keep track of to make your plans a success. All in one place!",
    "frond has built-in AI to recommend hidden gems and the best deals on offer using the Discover feature. The more you start using frond to plan, the better the recommendations become, frond starts to incorporate your interests to suggest hotels, flights,  restaurants, exciting events, and unique experiences that you just cannot miss. Make frond your personal planning assistant and start planning your next adventure today.",
    "Turn your personal plans into experiences that you can share with family and friends. Keep everyone in the loop, and invite them to collaborate in real-time, providing updates, assigning tasks and ideas along the way. Share experiences as part of the frond Community to inspire others as part of the journey.",
  ];

  const [activeTab, setActiveTab] = useState("Register");
  const [fade, setFade] = useState(false);

  const handleTabChange = (tab) => {
    setFade(true);
    setTimeout(() => {
      setActiveTab(tab);
      setFade(false);
    }, 300);
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    videoRef.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => {};

  const handleVideoEnd = () => {
    videoRef.current.load();
    setIsPlaying(false);
  };

  return (
    <section className="how-frond-works relative">
      <div className="absolute left-0 bottom-0 md:w-[671px] sm:[w-630px] w-[80%] h-[614px] z-10 bg-frondWorksBuble"></div>
      <div className="intro bg-mainGradiant py-24">
        <h2 className="text-[58px] font-bold text-white text-center lg:w-1/2 md:w-3/4 mx-auto px-4">
          How frond works
        </h2>
      </div>
      <div className="content py-12 relative z-20">
        <div className="container xl:px-36 md:px-3 px-6 mx-auto mb-12">
          <div className="tabs grid md:grid-cols-4 grid-cols-2 items-center justify-center gap-14 mb-12 w-fit mx-auto">
            {tabsData.map((item, idx) => (
              <button key={idx} onClick={() => handleTabChange(item.name)}>
                <div className="flex flex-col items-center gap-y-3">
                  <div
                    className={`flex justify-center items-center rounded-[24px] w-[78px] h-[78px] transition-all ${
                      item.name === activeTab
                        ? "bg-darkBlue shadow-frondWorksDark"
                        : "bg-offWhite shadow-frondWorksLight"
                    }`}
                    style={{
                      transition: "background 0.3s",
                    }}
                  >
                    <img
                      src={tabsIconsActiveList[idx]}
                      className={`min-w-[38px] min-h-[38px] max-w-[38px] max-h-[38px] absolute transition-opacity ${
                        item.name === activeTab ? "opacity-100" : "opacity-0"
                      }`}
                    />
                    <img
                      src={tabsIconsNonActiveList[idx]}
                      className={`min-w-[38px] min-h-[38px] max-w-[38px] max-h-[38px] absolute transition-opacity ${
                        item.name === activeTab ? "opacity-0" : "opacity-100"
                      }`}
                    />
                  </div>
                  <span
                    className={`text-2xl block w-[100px] text-center ${
                      item.name === activeTab
                        ? "text-darkBlue font-bold"
                        : "text-lightBlue font-normal"
                    }`}
                  >
                    {item.name}
                  </span>
                </div>
              </button>
            ))}
          </div>
          <div
            className={`create-frond rounded-4xl bg-white border-2 border-solid border-cardColor py-10 px-[60px] shadow-mainShadow transition-opacity duration-300 ${
              fade ? "opacity-0" : "opacity-100"
            }`}
          >
            <div className="flex md:flex-nowrap flex-wrap items-center md:justify-between justify-center gap-7">
              <div className="info">
                <h3 className="bg-clip-text text-transparent bg-mainGradiant text-38px mb-4 font-bold">
                  {activeTab}
                </h3>
                <p className="text-lg leading-[25.2px]">
                  {activeTab === "Register" && cardsData[0]}
                  {activeTab === "Create" && cardsData[1]}
                  {activeTab === "Discover" && cardsData[2]}
                  {activeTab === "Share" && cardsData[3]}
                </p>
              </div>
              <div className="sm:min-w-[292px] h-[219px]">
                {activeTab === "Register" && (
                  <Image src={cardImgsList[0]} alt="How Frond Works" />
                )}
                {activeTab === "Create" && (
                  <Image src={cardImgsList[1]} alt="How Frond Works" />
                )}
                {activeTab === "Discover" && (
                  <Image src={cardImgsList[2]} alt="How Frond Works" />
                )}
                {activeTab === "Share" && (
                  <Image src={cardImgsList[3]} alt="How Frond Works" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container xl:px-44 px-16 mx-auto text-center">
          <div className="relative w-[100%] h-[529px] rounded-4xl border-1 border-solid border-grayBorder overflow-hidden">
            {!isPlaying && (
              <button
                className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] z-30"
                onClick={handlePlay}
              >
                <img
                  src={playIcon}
                  className="w-[100px] h-[100px]"
                  alt="Play"
                />
              </button>
            )}

            <video
              ref={videoRef}
              className="absolute inset-0 w-full h-full object-cover z-10"
              src={introVideo}
              poster={videoPoster}
              onPause={handlePause}
              onEnded={handleVideoEnd}
              controls={isPlaying}
            ></video>

            {!isPlaying && (
              <div className="absolute inset-0 bg-black opacity-40 rounded-4xl z-20"></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowFrondWorks;
