import { Image, Input, ModalFooter } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import urlIcon from "../../../../Assets/scrapbook/urlIcon.svg";
import deleteLinkIcon from "../../../../Assets/scrapbook/delete-link.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";

const EditImageLinkTab = ({ frondId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  let authToken = localStorage.getItem("token");
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [invalidUrlMsg, setInvalidUrlMsg] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: "",
    },
  });

  const [urlValue, setUrlValue] = useState("");

  const [frondData, setFrondData] = useState([]);

  const [isDataLoad, setIsDataLoad] = useState(false);

  const { setIsAddScrapbookUrl, setCloseUrlImageModal } =
    useContext(PopUpContext);

  useEffect(() => {
    if (urlValue.length > 0) {
      setInvalidUrlMsg("");
      setInvalidUrl(false);
    }
  }, [urlValue]);

  async function addImage(data) {
    setInvalidUrl(false);
    setInvalidUrlMsg("");
    if (
      urlValue.match(
        /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
      )
    ) {
      setIsLoading(true);
      setIsDisabled(true);
      console.log(urlValue);
      try {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/scrapbook-images-url/${frondId}`,
          {
            image_url: urlValue,
          },
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (data.status === 200) {
          toast("URL created successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          getScrapbookImages();
          setUrlValue("");
          setIsAddScrapbookUrl(true);
          setTimeout(() => {
            setIsAddScrapbookUrl(false);
          }, 1000);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
      setIsDisabled(false);
      setIsLoading(false);
    } else {
      setInvalidUrl(true);
      setInvalidUrlMsg("Invalid URL");
    }
  }

  async function deleteImage(imgId) {
    // setIsLoading(true);
    setIsDisabled(true);

    console.log(imgId);
    console.log(frondId);

    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}/${imgId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (data.status === 200) {
        getScrapbookImages();
        toast("URL deleted successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
        setIsAddScrapbookUrl(true);
        setTimeout(() => {
          setIsAddScrapbookUrl(false);
        }, 1000);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    // setIsLoading(false);
  }

  const getScrapbookImages = async () => {
    setIsDataLoad(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
      console.log(data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoad(false);
  };

  useEffect(() => {
    getScrapbookImages();
  }, []);

  return (
    <form onSubmit={handleSubmit(addImage)}>
      <div className="scrapbook-edit-img-link sm:min-h-[396.550px] sm:max-h-[396.550px] min-h-[396.550px] max-h-[396.550px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%] mb-5">
        <div className="flex items-start gap-2.5 mb-5">
          <Input
            startContent={
              <Image
                src={urlIcon}
                alt="Enter url"
                className="ms-[15px] w-[18px] h-[18px]"
              />
            }
            onInput={(e) => {
              setUrlValue(e.target.value);
            }}
            type="text"
            value={urlValue}
            variant="bordered"
            placeholder="Image URL"
            classNames={{
              base: "input-noMargin h-[39px]",
              label: "text-textColor font-normal phone:text-sm text-[10px]",
              input: "border-none px-[9px] phone:text-base text-[10px]",
              mainWrapper: "h-[39px]",
              innerWrapper: "h-[39px]",
              inputWrapper: `border-[0.86px] p-0 border-solid ${
                errors.url?.message ? "invalid-text" : "border-[#E5E4EB]"
              } rounded-[8px] h-[39px]`,
            }}
            isInvalid={invalidUrl}
            errorMessage={invalidUrl && invalidUrlMsg}
          />
          <button
            disabled={!urlValue.length > 0 || invalidUrl}
            type="button"
            onClick={() => {
              addImage();
            }}
            className={`w-[75px] h-[39px] flex justify-center items-center rounded-[6px] py-[11px] px-[18px] text-white ${
              urlValue ? "bg-mainGradiant" : "bg-[#EBEBEB]"
            }`}
          >
            Add
          </button>
        </div>
        {frondData?.url?.length > 0 && (
          <div className="links flex flex-col gap-5">
            {frondData?.url?.length > 0 &&
              frondData?.url?.map((item, idx) => (
                <div
                  key={idx}
                  className={`link flex items-start justify-between gap-2.5 ${
                    idx === frondData?.url?.length - 1 && "mb-[75px]"
                  }`}
                >
                  <div className="flex items-center gap-[12px]">
                    <Image
                      src={item?.image}
                      alt="Link Image"
                      classNames={{ wrapper: "rounded-[10px]" }}
                      className="min-w-[85px] min-h-[85px] max-w-[85px] max-h-[85px] rounded-[10px]"
                    />
                    <Link
                      to={item?.image}
                      target="_blank"
                      className="text-mainBlue text-[14px] leading-[22px] break-all"
                    >
                      {item?.image?.length > 35
                        ? item?.image?.slice(0, 35)
                        : item?.image}
                    </Link>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      deleteImage(item?.id);
                    }}
                  >
                    <Image
                      src={deleteLinkIcon}
                      alt="Delete Link"
                      className="min-w-[24px] min-h-[24px]"
                    />
                  </button>
                </div>
              ))}
          </div>
        )}
      </div>
      <ModalFooter className="shadow-modalShadow">
        <button
          disabled={isDisabled}
          onClick={() => {
            setCloseUrlImageModal(true);
            setTimeout(() => {
              setCloseUrlImageModal(false);
            }, 1000);
          }}
          type="button"
          className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
        >
          {isLoading ? (
            <span className="flex justify-center items-center text-white">
              <i className="fa-solid fa-spinner fa-spin"></i>
            </span>
          ) : (
            "Save"
          )}
        </button>
      </ModalFooter>
    </form>
  );
};

export default EditImageLinkTab;
