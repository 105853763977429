import React from "react";
import cross from "../../Assets/create-frond/cross.svg";

export default class RemoveComponent extends React.Component {
  render() {
    const { className, onRemove } = this.props;
    return (
      <button onClick={onRemove} className={className}>
        <img src={cross} className="min-w-[16px] h-[16px]" />
      </button>
    );
  }
}
