import React, { useState } from "react";
import TasklistTabs from "./TasklistTabs";
import addIcon from "../../../Assets/filter/plus-squared.svg";
import { Image } from "@nextui-org/react";
import { Outlet, useParams } from "react-router-dom";
import AddTask from "./AddTask";

export default function TasklistTemplate() {
  const [openAddTask, setOpenAddTask] = useState(false);
  const { frondId } = useParams();
  return (
    <>
      <section className="tasklist">
        <div className="actions flex gap-2.5 justify-between xl:flex-nowrap flex-wrap mb-[15px]">
          <TasklistTabs />
          <button
            type="button"
            onClick={() => {
              setOpenAddTask(true);
            }}
            className="flex justify-center items-center gap-2.5 bg-mainGradiant py-1.5 px-2.5 rounded-4xl w-[111px] h-[30px]"
          >
            <Image src={addIcon} alt="Add Task" className="w-[18px] h-[18px]" />
            <span className="text-base text-white leading-[18.4px]">
              Add task
            </span>
          </button>
        </div>
        <Outlet />
      </section>
      <AddTask
        frondId={frondId}
        isOpen={openAddTask}
        onClose={() => {
          setOpenAddTask(false);
        }}
        isModalOpen={true}
      />
    </>
  );
}
