import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import dots from "../../Assets/community/dots-vertical.svg";
import postImg from "../../Assets/community/post-img.svg";
import grayLikesIcon from "../../Assets/community/gray-likes.svg";
import grayCommentsIcon from "../../Assets/community/gray-comments.svg";
import { LoadingContext } from "Context/LoadingContext";

export default function Trending() {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);

  const { isTrendingLoaded, setIsTrendingLoaded } = useContext(LoadingContext);

  useLayoutEffect(() => {
    if (!isTrendingLoaded) {
      setTimeout(() => {
        setIsTrendingLoaded(true);
      }, 2000);
    }
  }, [isTrendingLoaded]);

  useEffect(() => {
    if (itemKey === "delete") {
      setItemKey(null);
    } else if (itemKey === "edit") {
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <section
      className={`trending hidden lg:block p-5 right-5 lg:min-w-[317px] lg:max-w-[317px] bg-white rounded-4xl border-2 border-solid border-grayBorder fixed z-20 no-focus transition-all duration-400 ${
        !isTrendingLoaded ? "opacity-0" : "opacity-100"
      }`}
    >
      <div>
        <h3 className="text-textColor leading-[20.7px] font-bold text-lg mb-[15px] pb-[15px] border-b-1 border-solid border-grayBorder">
          Top Trending Posts
        </h3>
        <div className="flex flex-col gap-2.5 mb-7">
          <p className="text-textColor leading-[20.7px] text-lg bg-white transition-all duration-300 hover:bg-lightWhite py-2.5 px-[15px] rounded-[10px]">
            #Hashtag_example_one
          </p>
          <p className="text-textColor leading-[20.7px] text-lg bg-white transition-all duration-300 hover:bg-lightWhite py-2.5 px-[15px] rounded-[10px]">
            #Hashtag_example_one
          </p>
          <p className="text-textColor leading-[20.7px] text-lg bg-white transition-all duration-300 hover:bg-lightWhite py-2.5 px-[15px] rounded-[10px]">
            #Hashtag_example_one
          </p>
        </div>
      </div>
      <div>
        <h3 className="text-textColor leading-[20.7px] font-bold text-lg mb-[15px] pb-[15px] border-b-1 border-solid border-grayBorder">
          Most Liked Posts
        </h3>
        <div className="flex flex-col">
          <div className="post border-b-1 border-solid border-grayBorder mb-5 pb-5">
            <div className="flex items-start justify-between gap-2.5 mb-2.5">
              <div className="flex items-center gap-2.5">
                <Image
                  src={postImg}
                  alt="Post Title"
                  className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] object-cover rounded-full"
                />
                <div>
                  <h5 className="mb-[3px] text-textColor font-bold text-base leading-[22px]">
                    Maya Rodriguez
                  </h5>
                  <p className="text-cardGrayColor text-sm leading-[16.1px]">
                    21 minutes
                  </p>
                </div>
              </div>
              <Dropdown
                classNames={{
                  content:
                    "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                }}
                placement="left-start"
              >
                <DropdownTrigger>
                  <button
                    className="w-[24px] h-[24px]"
                    onClick={handleDotsClick}
                  >
                    <img
                      src={dots}
                      alt="Dots"
                      className="min-w-[24px] min-h-[24px]"
                    />
                  </button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Post Actions"
                  onAction={(key) => openModal(key, 1)}
                >
                  <DropdownItem
                    textValue="edit post"
                    key="edit"
                    className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    key="delete"
                    className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                    color="danger"
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <p className="mb-2.5 text-textColor leading-[22px]">
              I’m testing what a community post is and here’s the example...
            </p>
            <div className="flex justify-between mx-auto items-center w-5/6">
              <div className="flex items-center gap-1.5">
                <Image
                  src={grayLikesIcon}
                  alt="Likes"
                  className="w-[18px] h-[18px]"
                />
                <p className="text-sm text-cardGrayColor leading-[16.1px]">
                  28
                </p>
              </div>
              <div className="flex items-center gap-1.5">
                <Image
                  src={grayCommentsIcon}
                  alt="Comments"
                  className="w-[18px] h-[18px]"
                />
                <p className="text-sm text-cardGrayColor leading-[16.1px]">7</p>
              </div>
            </div>
          </div>
          <div className="post">
            <div className="flex items-start justify-between gap-2.5 mb-2.5">
              <div className="flex items-center gap-2.5">
                <Image
                  src={postImg}
                  alt="Post Title"
                  className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] object-cover rounded-full"
                />
                <div>
                  <h5 className="mb-[3px] text-textColor font-bold text-base leading-[22px]">
                    Maya Rodriguez
                  </h5>
                  <p className="text-cardGrayColor text-sm leading-[16.1px]">
                    21 minutes
                  </p>
                </div>
              </div>
              <Dropdown
                classNames={{
                  content:
                    "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                }}
                placement="left-start"
              >
                <DropdownTrigger>
                  <button
                    className="w-[24px] h-[24px]"
                    onClick={handleDotsClick}
                  >
                    <img
                      src={dots}
                      alt="Dots"
                      className="min-w-[24px] min-h-[24px]"
                    />
                  </button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Post Actions"
                  onAction={(key) => openModal(key, 1)}
                >
                  <DropdownItem
                    textValue="edit post"
                    key="edit"
                    className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    key="delete"
                    className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                    color="danger"
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
            <p className="mb-2.5 text-textColor leading-[22px]">
              I’m testing what a community post is and here’s the example...
            </p>
            <div className="flex justify-between mx-auto items-center w-5/6">
              <div className="flex items-center gap-1.5">
                <Image
                  src={grayLikesIcon}
                  alt="Likes"
                  className="w-[18px] h-[18px]"
                />
                <p className="text-sm text-cardGrayColor leading-[16.1px]">
                  28
                </p>
              </div>
              <div className="flex items-center gap-1.5">
                <Image
                  src={grayCommentsIcon}
                  alt="Comments"
                  className="w-[18px] h-[18px]"
                />
                <p className="text-sm text-cardGrayColor leading-[16.1px]">7</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
