import React, { useState } from "react";
import planingImg1 from "../../Assets/landing/planing/1.svg";
import planingImg2 from "../../Assets/landing/planing/2.svg";
import planingImg3 from "../../Assets/landing/planing/3.svg";
import planingImg4 from "../../Assets/landing/planing/4.svg";
import { Link } from "react-router-dom";

const Planing = () => {
  const tabsIconsActive = require.context(
    "../../Assets/landing/icons/active",
    true
  );
  const tabsIconsNonActive = require.context(
    "../../Assets/landing/icons/nonActive",
    true
  );
  const tabsIconsActiveList = tabsIconsActive
    .keys()
    .map((image) => tabsIconsActive(image));
  const tabsIconsNonActiveList = tabsIconsNonActive
    .keys()
    .map((image) => tabsIconsNonActive(image));
  const tabsData = [
    {
      name: "Home",
    },
    {
      name: "Ventures",
    },
    {
      name: "Events",
    },
    {
      name: "Escapes",
    },
  ];
  const [activeTab, setActiveTab] = useState("Home");
  const [fade, setFade] = useState(false);

  const handleTabChange = (tab) => {
    setFade(true);
    setTimeout(() => {
      setActiveTab(tab);
      setFade(false);
    }, 300);
  };

  return (
    <section className="planing pb-0">
      <div className="container xl:px-20 px-6 mx-auto">
        <div className="tabs grid lg:grid-cols-4 sm:grid-cols-2 items-center justify-center gap-6 mb-12 w-fit mx-auto">
          {tabsData.map((item, idx) => (
            <button
              key={idx}
              onClick={() => handleTabChange(item.name)}
              className={`flex gap-4 justify-center items-center rounded-15px py-3 h-[59.2px] w-[200px] text-2xl transition-all no-focus ${
                item.name === activeTab
                  ? "bg-mainGradiant"
                  : "bg-white gradiantBorder"
              }`}
              style={{
                transition: "background 0.3s, color 0.2s",
              }}
            >
              <div className="relative w-[24px] h-[24px]">
                <img
                  src={tabsIconsActiveList[idx]}
                  className={`absolute w-full h-full transition-opacity ${
                    item.name === activeTab ? "opacity-100" : "opacity-0"
                  }`}
                />
                <img
                  src={tabsIconsNonActiveList[idx]}
                  className={`absolute w-full h-full transition-opacity ${
                    item.name === activeTab ? "opacity-0" : "opacity-100"
                  }`}
                />
              </div>
              <span
                className={`${
                  item.name === activeTab
                    ? "text-white font-bold"
                    : "bg-clip-text text-transparent bg-mainGradiant font-normal"
                }`}
              >
                {item.name}
              </span>
            </button>
          ))}
        </div>
        <div
          className={`transition-opacity duration-300 ${
            fade ? "opacity-0" : "opacity-100"
          }`}
        >
          <Link
            to={"/home"}
            className={`${
              activeTab === "Home" ? "opacity-100 visible" : "opacity-0 hidden"
            } transition-all duration-300`}
          >
            <div className="card grid md:grid-cols-2 p-10 gap-8 items-center rounded-4xl border-2 border-solid border-cardColor shadow-mainShadow">
              <div className="card-img xl:w-[460px] xl:h-[307px] h-full rounded-4xl">
                <img
                  src={planingImg1}
                  alt="Planing"
                  className="size-full rounded-4xl"
                />
              </div>
              <div className="card-content">
                <h3 className="text-2xl font-bold leading-HeadingNormalHeight mb-4">
                  Your Home Renovation Journey Starts with frond Plan It, Build
                  It
                </h3>
                <p className="text-textColor text-lg leading-textHeight mb-6">
                  Outgrowing your home? Frond helps families plan for the future
                  Dream bigger: Manage renovations and expansions seamlessly to
                  create a space that grows with your family
                </p>
                <p className="text-textColor text-lg leading-textHeight">
                  Stay organised: Centralise plans, budgets, and contractor
                  details for a stress-free project.
                  <span className="bg-clip-text text-transparent bg-mainGradiant ms-1 relative after:absolute after:start-0 after:end-0 after:bottom-[-1px] after:h-[1px] after:bg-mainGradiant">
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </Link>
          <Link
            to={"/ventures"}
            className={`${
              activeTab === "Ventures"
                ? "opacity-100 visible"
                : "opacity-0 hidden"
            } transition-all duration-300`}
          >
            <div className="card grid md:grid-cols-2 p-10 gap-8 items-center rounded-4xl border-2 border-solid border-cardColor shadow-mainShadow">
              <div className="card-content">
                <h3 className="text-2xl font-bold leading-HeadingNormalHeight mb-4">
                  From Idea to Reality, Plan Your Venture with frond
                </h3>
                <p className="text-textColor text-lg leading-textHeight mb-6">
                  Turn your business ideas into reality with frond Plan like a
                  pro: Organize your business ideas, track progress, and never
                  miss a detail
                </p>
                <p className="text-textColor text-lg leading-textHeight">
                  Collaborate with ease: Share ideas, assign tasks, plan your
                  marketing strategy and keep everyone on the same page.
                  <span className="bg-clip-text text-transparent bg-mainGradiant ms-1 relative after:absolute after:start-0 after:end-0 after:bottom-[-1px] after:h-[1px] after:bg-mainGradiant">
                    Read More
                  </span>
                </p>
              </div>
              <div className="card-img xl:w-[460px] xl:h-[307px] h-full rounded-4xl ms-auto">
                <img
                  src={planingImg2}
                  alt="Planing"
                  className="size-full rounded-4xl"
                />
              </div>
            </div>
          </Link>
          <Link
            to={"/events"}
            className={`${
              activeTab === "Events"
                ? "opacity-100 visible"
                : "opacity-0 hidden"
            } transition-all duration-300`}
          >
            <div className="card grid md:grid-cols-2 p-10 gap-8 items-center rounded-4xl border-2 border-solid border-cardColor shadow-mainShadow">
              <div className="card-img xl:w-[460px] xl:h-[307px] h-full rounded-4xl">
                <img
                  src={planingImg3}
                  alt="Planing"
                  className="size-full rounded-4xl"
                />
              </div>
              <div className="card-content">
                <h3 className="text-2xl font-bold leading-HeadingNormalHeight mb-4">
                  Turn event planning chaos into organised joy with frond
                </h3>
                <p className="text-textColor text-lg leading-textHeight mb-6">
                  Turn event planning chaos into organised joy with frond
                </p>
                <p className="text-textColor text-lg leading-textHeight">
                  Are you or someone you know planning their wedding and want to
                  ensure that everything comes together perfectly for the
                  special day? Are you juggling your time between looking at
                  venues, guest lists, sorting out the caterer, photographer and
                  make up artist, and not enough time to looking for the perfect
                  dress?
                  <span className="bg-clip-text text-transparent bg-mainGradiant ms-1 relative after:absolute after:start-0 after:end-0 after:bottom-[-1px] after:h-[1px] after:bg-mainGradiant">
                    Read More
                  </span>
                </p>
              </div>
            </div>
          </Link>
          <Link
            to={"/escape"}
            className={`${
              activeTab === "Escapes"
                ? "opacity-100 visible"
                : "opacity-0 hidden"
            } transition-all duration-300`}
          >
            <div className="card grid md:grid-cols-2 p-10 gap-8 items-center rounded-4xl border-2 border-solid border-cardColor shadow-mainShadow">
              <div className="card-content">
                <h3 className="text-2xl font-bold leading-HeadingNormalHeight mb-4">
                  Planning adventures shouldn't be an adventure in itself
                </h3>
                <p className="text-textColor text-lg leading-textHeight mb-6">
                  Simplify your travel planning with frond's task planning,
                  Effortless task planning: Avoid conflicts! Frond helps create
                  a task plan ensuring your dream itinerary fit
                </p>
                <p className="text-textColor text-lg leading-textHeight">
                  Build your perfect trip: Create a detailed itinerary with all
                  the must-see towns and cities right within the app
                  <span className="bg-clip-text text-transparent bg-mainGradiant ms-1 relative after:absolute after:start-0 after:end-0 after:bottom-[-1px] after:h-[1px] after:bg-mainGradiant">
                    Read More
                  </span>
                </p>
              </div>
              <div className="card-img xl:w-[460px] xl:h-[307px] h-full rounded-4xl ms-auto">
                <img
                  src={planingImg4}
                  alt="Planing"
                  className="size-full rounded-4xl"
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Planing;
