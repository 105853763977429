import React, { useContext, useEffect, useState } from "react";
import deleteIcon from "../../Assets/filter/delete-folder.svg";
import addIcon from "../../Assets/filter/plus-squared.svg";
import EditScrapbookImgModal from "./Scrapbook/PopUp/EditScrapbookImgModal";
import AddNote from "./Scrapbook/PopUp/AddNote";
import VoiceModal from "./Scrapbook/PopUp/VoiceModal";
import AddLink from "./Scrapbook/PopUp/AddLink";
import { useParams } from "react-router-dom";
import { DeleteScrapbookImagesContext } from "Context/DeleteScrapbookImagesContext";
import { PopUpContext } from "Context/PopUpContext";
import axios from "axios";
import { toast } from "react-toastify";

const DetailsActions = ({ actions }) => {
  const [editScrapbookImgModal, setEditScrapbookImgModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addVoiceModal, setAddVoiceModal] = useState(false);
  const [addLinkModal, setAddLinkModal] = useState(false);

  const { frondId } = useParams();
  let authToken = localStorage.getItem("token");

  const { setIsAddScrapbookImage } = useContext(PopUpContext);
  const {
    openDeleteCheckbox,
    setOpenDeleteCheckbox,
    deleteProjects,
    setDeleteProjects,
  } = useContext(DeleteScrapbookImagesContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  async function deleteImage() {
    setIsLoading(true);
    setIsDisabled(true);
    for (let i = 0; i < deleteProjects.length; i++) {
      try {
        const { data } = await axios.delete(
          `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}/${deleteProjects[i]}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (i === deleteProjects.length - 1) {
          setIsAddScrapbookImage(true);
          setTimeout(() => {
            setIsAddScrapbookImage(false);
          }, 1000);
          toast("Images deleted successfully", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          setDeleteProjects([]);
          setOpenDeleteCheckbox(false);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  return (
    <>
      <div className="flex items-center phone:justify-start justify-center phone:w-auto w-full gap-2.5">
        {actions &&
          actions.map((item, idx) =>
            item === "Delete" ? (
              <button
                disabled={isDisabled}
                onClick={() => {
                  if (openDeleteCheckbox && deleteProjects.length > 0) {
                    deleteImage();
                  } else {
                    setOpenDeleteCheckbox(!openDeleteCheckbox);
                  }
                }}
                key={idx}
                className={`flex items-center justify-center gap-2.5 py-[6px] min-w-[95px] min-h-[36px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px]
                 ${
                   openDeleteCheckbox
                     ? "bg-darkBlue text-white"
                     : "bg-clip-text text-transparent bg-mainGradiant"
                 }`}
              >
                {isLoading ? (
                  <span className="flex justify-center items-center text-white">
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </span>
                ) : (
                  <>
                    {!openDeleteCheckbox && (
                      <img
                        src={deleteIcon}
                        alt="Delete Image"
                        className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                      />
                    )}
                    <span>{item}</span>
                  </>
                )}
              </button>
            ) : (
              <button
                onClick={() => {
                  if (item === "Add Image") {
                    setEditScrapbookImgModal(true);
                  } else if (item === "Add Note") {
                    setAddNoteModal(true);
                  } else if (item === "Add Voice Memo") {
                    setAddVoiceModal(true);
                  } else if (item === "Add Link") {
                    setAddLinkModal(true);
                  }
                }}
                key={idx}
                className={`flex items-center gap-2.5 py-[6px] phone:px-2.5 px-[8px] rounded-4xl phone:text-base text-[12px] font-normal leading-[18.4px] ${
                  item === "Add Image" ||
                  item === "Add Note" ||
                  item === "Add Voice Memo" ||
                  item === "Add Link"
                    ? "bg-mainGradiant text-white shadow-editImgShadow"
                    : "bg-clip-text text-transparent bg-mainGradiant"
                } `}
              >
                <img
                  src={addIcon}
                  alt={item}
                  className="phone:w-[18px] phone:h-[18px] w-[12px] h-[12px]"
                />
                <span>{item}</span>
              </button>
            )
          )}
      </div>

      {editScrapbookImgModal && (
        <EditScrapbookImgModal
          isOpen={editScrapbookImgModal}
          onClose={() => {
            setEditScrapbookImgModal(false);
          }}
          isModalOpen={true}
        />
      )}

      {addNoteModal && (
        <AddNote
          isOpen={addNoteModal}
          onClose={() => {
            setAddNoteModal(false);
          }}
          frondId={frondId}
          isModalOpen={true}
        />
      )}

      {addVoiceModal && (
        <VoiceModal
          isOpen={addVoiceModal}
          onClose={() => {
            setAddVoiceModal(false);
          }}
          frondId={frondId}
        />
      )}

      {addLinkModal && (
        <AddLink
          isOpen={addLinkModal}
          onClose={() => {
            setAddLinkModal(false);
          }}
          frondId={frondId}
          isModalOpen={true}
        />
      )}
    </>
  );
};

export default DetailsActions;
