import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React from "react";

import closeIcon from "../../Assets/popup/cross-square.svg";

const ContactUsModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center pb-2",
        body: "border-b-1 border-solid border-grayBorder pb-8 pt-6",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <ModalHeader className="flex flex-col gap-1">
              <button
                onClick={closeModal}
                className="absolute top-[25px] right-[25px]"
                aria-label="Close"
              >
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody>
              <p className="text-cardGrayColor font-bold text-center leading-[18.4px] sm:w-[292px] sm:mx-auto">
                Thank you for contacting us! Someone from our team will get back
                to you shortly!
              </p>
            </ModalBody>
            <ModalFooter>
              <button
                onClick={closeModal}
                className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[120px] h-[39px]"
              >
                Continue
              </button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ContactUsModal;
