import AuthNavbar from "components/AuthNavbar/AuthNavbar";
import MainFooter from "components/MainFooter/MainFooter";
import NavbarComponent from "components/NavbarComponent/NavbarComponent";
import Homepage from "pages/Homepage/Homepage";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

const Layout = () => {
  const { pathname } = useLocation();
  const path = pathname.split("/")[1];

  useEffect(() => {}, [pathname]);

  return (
    <>
      {path === "login" ||
      path === "signup" ||
      path === "forget-password" ||
      path === "verify-password" ||
      path === "reset-password" ? (
        <AuthNavbar
          info={
            path === "login" || path === "forget-password" ? "signUp" : "login"
          }
        />
      ) : (
        <NavbarComponent />
      )}
      <Outlet />
      {path === "" ||
      path === "home" ||
      path === "events" ||
      path === "ventures" ||
      path === "escape" ||
      path === "download" ||
      path === "contact-us" ||
      path === "how-frond-works" ||
      path === "about-frond" ||
      path === "faq" ? (
        <MainFooter />
      ) : null}
    </>
  );
};

export default Layout;
