import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import L from "leaflet";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import tempMap from "../../Assets/create-frond/temp-map.png";
import editPen from "../../Assets/frond-details/edit-pen.svg";
import { Image } from "@nextui-org/react";
import { LoadingContext } from "Context/LoadingContext";

export default function ForYouSideNav() {
  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);
  const [frondData, setFrondData] = useState([]);

  const { isForYouLoaded, setIsForYouLoaded } = useContext(LoadingContext);

  useLayoutEffect(() => {
    if (!isForYouLoaded) {
      setTimeout(() => {
        setIsForYouLoaded(true);
      }, 2000);
    }
  }, [isForYouLoaded]);

  useEffect(() => {}, [position]);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setTimeout(() => {
        handleLoad();
      }, 1000);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);
  return (
    <div
      className={`hidden lg:block p-5 right-5 lg:min-w-[317px] lg:max-w-[317px] bg-white rounded-4xl border-2 border-solid border-grayBorder fixed z-20 no-focus transition-all duration-400 ${
        !isForYouLoaded ? "opacity-0" : "opacity-100"
      }`}
    >
      <h3 className="text-textColor leading-[20.7px] text-lg font-bold mb-[15px]">
        For You
      </h3>
      {isPageLoaded && (
        <div className="map min-w-full h-[198px] rounded-[20px] relative mb-2.5">
          <div className="w-full h-[198px] relative">
            <MapContainer
              className="discover-map"
              center={position}
              zoom={12}
              dragging={false}
              doubleClickZoom={false}
              zoomControl={false}
              scrollWheelZoom={false}
              style={{
                height: "198px",
                width: "100%",
                position: "relative",
                zIndex: "20",
              }}
              whenCreated={(map) => {
                map.on("load", handleMapLoad);
              }}
            >
              <TileLayer
                eventHandlers={{
                  tileload: handleTileLoad,
                }}
                attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
              />
              <CustomMarker />
            </MapContainer>
            {!mapLoaded && (
              <div
                className={`bg-gray-400 rounded-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                  mapLoaded ? "opacity-0 z-10" : "opacity-100 z-40"
                }`}
              >
                <span className="flex gap-2 justify-center items-center text-white">
                  <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                </span>
              </div>
            )}
            {isShowMap ? (
              <div
                className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-[20px] ${
                  isShowMap ? "opacity-100 z-40" : "opacity-0 z-10"
                }`}
              >
                <Image
                  classNames={{
                    wrapper: "w-full h-full min-w-full",
                    img: "rounded-[20px]",
                  }}
                  src={tempMap}
                  alt="Map"
                  className="size-full rounded-[20px]"
                />
              </div>
            ) : null}
          </div>
        </div>
      )}
      <p className="text-textColor font-bold text-lg leading-[22px] mb-2.5">
        Larissa, Greece
      </p>
      <p className="mb-2.5">
        <span className="text-cardGrayColor leading-[22px] me-[5px]">
          Currency
        </span>
        <span className="text-textColor leading-[22px]">Euro</span>
      </p>
      <p className="mb-2.5">
        <span className="text-cardGrayColor leading-[22px] me-[5px]">
          Local time
        </span>
        <span className="text-textColor leading-[22px]">Tuesday, 7:21 PM</span>
      </p>
      <p className="mb-2.5">
        <span className="text-cardGrayColor leading-[22px] me-[5px]">
          Postal code
        </span>
        <span className="text-textColor leading-[22px]">28001 - 28080</span>
      </p>
      <p>
        <span className="text-cardGrayColor leading-[22px] me-[5px]">
          Weather
        </span>
        <span className="text-textColor leading-[22px]">
          18°C, Wind W at 3 km/h, 63% Humidity
        </span>
      </p>
    </div>
  );
}
