import {
  Avatar,
  DatePicker,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../../Assets/popup/cross-square.svg";
import { AuthContext } from "Context/AuthContext";
import { Controller, useForm, useWatch } from "react-hook-form";
import addTaskIcon from "../../../Assets/frond-details/tasklist/tasklist-icon.svg";
import userIcon from "../../../Assets/frond-details/tasklist/user.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import up from "../../../Assets/create-frond/up.svg";
import calendar from "../../../Assets/create-frond/calendar.svg";
import { parseDate } from "@internationalized/date";

const AddTask = ({ isOpen, onClose, frondId, isModalOpen, taskId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedUser, setSelectedUser] = useState(new Set([]));
  const [users, setUsers] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      date: null,
      title: "",
      desc: "",
      status: "",
    },
  });

  useEffect(() => {
    if (frondId) {
      getMembers();
    }
  }, [frondId]);

  const dateValue = watch("date");
  const statusValue = watch("status");

  useEffect(() => {}, [selectedUser]);

  const [descLength, setDescLength] = useState(0);

  const getTaskData = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/tasks/${taskId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.status === 200) {
        const dateArr = data?.response?.data?.task?.due_date.split("/");

        setValue("desc", data?.response?.data?.task?.description);
        setValue("title", data?.response?.data?.task?.title);
        if (data?.response?.data?.task?.status === "pending") {
          setValue("status", "Pending");
        } else if (data?.response?.data?.task?.status === "in_progress") {
          setValue("status", "In Progress");
        } else if (data?.response?.data?.task?.status === "completed") {
          setValue("status", "Completed");
        }
        setValue("name", data?.response?.data?.assigned_to?.name);

        setValue(
          "date",
          parseDate(
            `${dateArr[2]}-${dateArr[1].padStart(2, "0")}-${dateArr[0].padStart(
              2,
              "0"
            )}`
          )
        );
        setDescLength(data?.response?.data?.task?.description.length || 0);
        setSelectedUser([data?.response?.data?.assigned_to?.name]);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (isOpen && taskId) {
      getTaskData();
    }
  }, [isOpen, taskId]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen, isOpen]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  const { token } = useContext(AuthContext);
  const { setIsAddTask } = useContext(PopUpContext);

  async function addTask(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    const targetUserId = users.find((item) => {
      if (item.name === selectedUser.values().next().value) {
        return item.id;
      }
    });

    let statusType = "";
    if (reqBody.status === "Pending") {
      statusType = "pending";
    } else if (reqBody.status === "In Progress") {
      statusType = "in_progress";
    } else if (reqBody.status === "Completed") {
      statusType = "completed";
    }

    const payload = {
      title: reqBody.title,
      assigned_to: targetUserId.id,
      description: reqBody.desc,
      due_date: `${reqBody.date.day}/${reqBody.date.month}/${reqBody.date.year}`,
    };

    if (statusType) {
      payload.status = statusType;
    }

    try {
      if (taskId) {
        const { data } = await axios.put(
          `https://frond-admin.code-minds.tech/api/tasks/${taskId}`,
          payload,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        toast("Updates saved", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      } else {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/projects/${frondId}/tasks`,
          payload,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        toast("Task created successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      }

      setIsAddTask(true);
      setTimeout(() => {
        setIsAddTask(false);
      }, 1000);
      reset();
      onClose();
    } catch (error) {
      toast(error.response?.data?.message || "An error occurred", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }

    setIsDisabled(false);
    setIsLoading(false);
  }

  const getMembers = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/membersassign`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setUsers(data);
    } catch (error) {
      toast(error.response.data.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  useEffect(() => {}, [getValues("date")]);

  const statusList = ["Pending", "In Progress", "Completed"];

  useEffect(() => {
    handleResize();
  }, [descLength]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        onClose();
        setTimeout(() => {
          reset();
        }, 1000);
      }}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  {taskId ? "Edit Task" : "Add New Task"}
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(addTask)}>
                <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                  <ModalBody className="w-[100%] scroll-inner">
                    <div className="relative about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                      <div className="content flex flex-col gap-5 items-center">
                        <Image
                          src={addTaskIcon}
                          className="w-[96px] h-[96px]"
                          alt="Add Note"
                        />

                        <Controller
                          name="name"
                          control={control}
                          rules={{
                            required: "User is required",
                          }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isInvalid={errors.name?.message ? true : false}
                              selectedKeys={field.value ? [field.value] : []}
                              errorMessage={errors.name?.message}
                              onSelectionChange={(selected) => {
                                setSelectedUser(selected);
                              }}
                              selectorIcon={
                                <img
                                  src={up}
                                  alt="Arrow"
                                  className="w-[12px] h-[12px]"
                                />
                              }
                              items={users}
                              startContent={
                                !selectedUser.values().next().value &&
                                field.value === "" ? (
                                  <img
                                    src={userIcon}
                                    alt="User Icon"
                                    className="w-[18px] h-[18px]"
                                  />
                                ) : null
                              }
                              placeholder="Assigned to"
                              variant="bordered"
                              classNames={{
                                base: `${
                                  errors.name?.message ? "h-[68px]" : "h-[39px]"
                                }`,
                                trigger: `rounded-[8px] px-4 border-[0.86px] border-solid ${
                                  errors.name?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                }`,
                                innerWrapper: "user-select-input",
                                value:
                                  "text-cardGrayColor phone:text-base text-[10px]",
                              }}
                              renderValue={(items) => {
                                return items.map((item) => (
                                  <div
                                    key={item.key}
                                    className="flex items-center gap-2"
                                  >
                                    <Avatar
                                      alt={item.data.name}
                                      className="flex-shrink-0"
                                      size="sm"
                                      src={item.data.img_url}
                                    />
                                    <div className="flex flex-col">
                                      <span>{item.data.name}</span>
                                    </div>
                                  </div>
                                ));
                              }}
                            >
                              {(user) => (
                                <SelectItem
                                  key={user.name}
                                  textValue={user.name}
                                >
                                  <div className="flex gap-2 items-center">
                                    <Avatar
                                      alt={user.name}
                                      className="flex-shrink-0"
                                      size="sm"
                                      src={user.img_url}
                                    />
                                    <div className="flex flex-col">
                                      <span className="text-small">
                                        {user.name}
                                      </span>
                                    </div>
                                  </div>
                                </SelectItem>
                              )}
                            </Select>
                          )}
                        />

                        <div className="date-wrapper relative w-full">
                          <div
                            className={`absolute left-[45px] ${
                              errors.date?.message
                                ? "top-0 translate-y-[33%]"
                                : "top-[50%] translate-y-[-50%]"
                            } text-cardGrayColor phone:text-base text-[10px] font-normal ${
                              dateValue ? "hidden" : "block"
                            }`}
                          >
                            Due Date
                          </div>
                          <Controller
                            name="date"
                            control={control}
                            rules={{
                              required: "Date is required",
                            }}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                variant="bordered"
                                selectorIcon={
                                  <Image
                                    src={calendar}
                                    className="w-[18px] h-[18px]"
                                  />
                                }
                                isInvalid={!!errors.date?.message}
                                errorMessage={errors.date?.message}
                                classNames={{
                                  selectorButton:
                                    "order-first min-w-[18px] max-h-[18px] h-[18px] me-0",
                                }}
                                dateInputClassNames={{
                                  base: `mt-0 w-full ${
                                    errors.date?.message
                                      ? "h-[68px]"
                                      : "h-[39px]"
                                  }`,
                                  inputWrapper: `rounded-[8px] border-[0.86px] p-0 border-solid ${
                                    errors.date?.message
                                      ? "invalid-text"
                                      : "border-[#E5E4EB]"
                                  }`,
                                  innerWrapper:
                                    "px-[13px] h-full gap-x-[2px] no-action",
                                  segment: `date-picker-frond phone:text-base text-[10px] font-normal ${
                                    dateValue ? "block" : "hidden"
                                  }`,
                                }}
                              />
                            )}
                          />
                        </div>

                        <Controller
                          name="title"
                          control={control}
                          rules={{
                            required: "Title is required",
                            minLength: {
                              value: 3,
                              message: "Minimum number of characters is 3",
                            },
                            maxLength: {
                              value: 100,
                              message: "Maximum number of characters is 100",
                            },
                            validate: {
                              noTrailingSpace: (value) =>
                                !/\s$/.test(value) ||
                                "Title shouldn't end with a space",
                            },
                            pattern: {
                              value:
                                // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                                /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                              message:
                                "Title may contain characters, numbers and special characters",
                            },
                          }}
                          render={({ field }) => (
                            <Input
                              onKeyDown={(e) => {
                                e.key === "Enter" && e.preventDefault();
                              }}
                              {...field}
                              type="text"
                              variant="bordered"
                              placeholder="*Task Title"
                              isInvalid={errors.title?.message ? true : false}
                              errorMessage={errors.title?.message}
                              classNames={{
                                base: `input-noMargin ${
                                  errors.title?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                label:
                                  "text-textColor font-normal phone:text-sm text-[10px]",
                                input:
                                  "border-none px-[18px] phone:text-base text-[10px]",
                                mainWrapper: `${
                                  errors.title?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                innerWrapper: "h-[39px]",
                                inputWrapper: `border-[0.86px] p-0 border-solid ${
                                  errors.title?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] h-[39px]`,
                              }}
                            />
                          )}
                        />

                        <Controller
                          name="status"
                          control={control}
                          render={({ field }) => (
                            <Select
                              className="w-[100%]"
                              {...field}
                              variant="bordered"
                              labelPlacement="outside"
                              selectedKeys={field.value ? [field.value] : []}
                              placeholder="Select Status"
                              classNames={{
                                base: `input-noMargin ${
                                  errors.status?.message
                                    ? "h-[68px]"
                                    : "h-[39px]"
                                }`,
                                label: "text-textColor font-normal text-sm",
                                mainWrapper: "h-[42px]",
                                innerWrapper: "h-[42px] px-[18px]",
                                trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                                  errors.status?.message
                                    ? "invalid-text"
                                    : "border-[#E5E4EB]"
                                } rounded-[8px] flex items-center justify-between`,
                                value: `${
                                  statusValue
                                    ? "text-textColor"
                                    : "text-cardGrayColor"
                                } phone:text-base text-[10px] font-normal`,
                                selectorIcon: "w-[18px] h-[18px]",
                              }}
                              selectorIcon={<img src={up} />}
                              isInvalid={errors.status?.message ? true : false}
                              errorMessage={errors.status?.message}
                            >
                              {statusList.map((item) => (
                                <SelectItem key={item}>{item}</SelectItem>
                              ))}
                            </Select>
                          )}
                        />

                        <div className="w-full mb-5">
                          <Controller
                            name="desc"
                            control={control}
                            rules={{
                              required: "Description content is required",
                              minLength: {
                                value: 2,
                                message: "Minimum number of characters is 2",
                              },
                              maxLength: {
                                value: 255,
                                message: "Maximum number of characters is 255",
                              },
                              pattern: {
                                value:
                                  /^[a-zA-Z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\/\\"]+(\s[A-Za-z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\\/\\"]+)*$/gi,
                                message:
                                  "Task description may contain characters, numbers or special characters and shouldn't end with space",
                              },
                            }}
                            render={({ field }) => (
                              <Textarea
                                {...field}
                                onInput={(e) =>
                                  setDescLength(e.target.value.length)
                                }
                                maxLength={255}
                                type="text"
                                variant="bordered"
                                placeholder="*Add a task description..."
                                classNames={{
                                  base: "input-noMargin",
                                  label:
                                    "text-textColor font-normal phone:text-sm text-[10px]",
                                  input:
                                    "border-none px-[18px] min-h-[102px] phone:text-base text-[10px] overflow-y-auto",
                                  mainWrapper: "h-[102px]",
                                  innerWrapper: "h-[102px] overflow-y-auto",
                                  inputWrapper: `border-[0.86px] p-0 border-solid ${
                                    errors.desc?.message
                                      ? "invalid-text"
                                      : "border-[#E5E4EB]"
                                  } rounded-[8px] h-[102px] overflow-y-auto`,
                                }}
                                isInvalid={errors.desc?.message ? true : false}
                                errorMessage={errors.desc?.message}
                              />
                            )}
                          />
                          {!errors.desc?.message && 255 - descLength > 0 ? (
                            <p className="text-[.75rem] text-mainBlue mt-1">
                              {255 - descLength} remaining characters
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow">
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default AddTask;
