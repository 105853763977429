import { AuthContext } from "Context/AuthContext";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedVerify = (props) => {
  const { isVerified } = useContext(AuthContext);
  if (isVerified === false) {
    return props.children;
  } else {
    if (localStorage.getItem("token") !== null) {
      return <Navigate to={"/"}></Navigate>;
    } else {
      return <Navigate to={"/"}></Navigate>;
    }
  }
};

export default ProtectedVerify;
