import React, { useEffect } from "react";
import CardsTemplate from "./CardsTemplate";

const Escape = () => {
  const cardsImgs = require.context("../../Assets/template/escape", true);
  const cardsImgsList = cardsImgs.keys().map((image) => cardsImgs(image));
  const cardOne = {
    title: "Simplify your travel planning with frond's task planning",
    content: [
      {
        space: true,
        info: "Effortless task planning",
        desc: "Avoid conflicts! Frond helps create a task plan ensuring your dream itinerary fit",
      },
      {
        space: true,
        info: "Build your perfect trip",
        desc: "Create a detailed itinerary with all the must-see towns and cities right within the app",
      },
      {
        info: "Stay organised, explore more",
        desc: "frond keeps everything centralised, so you can focus on the adventure, not the logistics",
      },
    ],
    img: cardsImgsList[0],
  };
  const cardTwo = {
    title: "Turn travel dreams into reality, together",
    content: [
      {
        space: true,
        info: "Scrapbook your dream trip",
        desc: "Fuel your wanderlust with photos, links, pins, IG reels, restaurant confirmations and recommendations – all in one centralised space",
      },
      {
        space: true,
        info: "Plan together, effortlessly",
        desc: "Share ideas, collaborate on your itinerary, and build an unforgettable experience together",
      },
      {
        info: "From inspiration to reality",
        desc: "frond helps you transform travel dreams into a seamless, organized plan",
      },
    ],
    img: cardsImgsList[1],
  };
  const cardThree = {
    title:
      "keeps all your trip details secure and easily accessible from your phone",
    content: [
      {
        space: true,
        info: "Stress-free organization",
        desc: "Store everything in one place - flight details, taxi pick up contacts, accommodation info, rental car contacts, even passport scans",
      },
      {
        space: true,
        info: "Say goodbye to last-minute scrambles",
        desc: "No more frantic searches for lost documents. Access all your trip essentials with a tap",
      },
      {
        info: "Focus on the fun",
        desc: "frond empowers you to relax and enjoy your vacation, knowing everything is organized and at your fingertips",
      },
    ],
    img: cardsImgsList[2],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <CardsTemplate
      secName="escape"
      mainTitle={"Planning adventures shouldn't be an adventure in itself"}
      cardOne={cardOne}
      cardTwo={cardTwo}
      cardThree={cardThree}
      subTitle={"Turn travel dreams into reality"}
    />
  );
};

export default Escape;
