import React, { useEffect } from "react";
import largePhone from "../../Assets/landing/largePhone.svg";
import smallPhone from "../../Assets/landing/smallPhone.svg";
import googlePlay from "../../Assets/landing/googlePlay.svg";
import appStore from "../../Assets/landing/appStore.svg";

const Download = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="download relative">
      <div className="intro bg-mainGradiant py-24">
        <h2 className="text-[58px] font-bold text-white text-center lg:w-1/2 md:w-3/4 mx-auto px-4">
          It’s always simple from anywhere
        </h2>
      </div>
      <div className="container xl:px-44 px-6 mx-auto relative z-20">
        <div className="goals-card grid md:grid-cols-2 md:gap-10 items-start">
          <div className="card-img relative bottom-[85px]">
            <img src={largePhone} alt="Phone" className="relative z-20" />
            <img
              src={smallPhone}
              alt="Phone"
              className="absolute md:start-[29%] sm:start-[27%] start-[17%] bottom-0 z-10 small-phone-img"
            />
          </div>
          <div className="card-content md:mt-[60px] lg:mt-[75px] md:pb-0 pb-[60px]">
            <h3 className="bg-clip-text text-transparent bg-mainGradiant font-bold sm:text-38px text-3xl mb-8 leading-HeadingHeight">
              frond is always here for you...
            </h3>
            <p className="font-normal text-lg leading-textHeight text-black opacity-75 mb-10">
              Frond can help you keep track of your ideas and inspirations,
              manage timelines and budgets add collaborators, and share your
              projects with friends and family to make your plans come to life.
            </p>
            <div className="download flex gap-4 items-center sm:flex-nowrap flex-wrap">
              <img src={googlePlay} alt="Google play" />
              <img src={appStore} alt="App store" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Download;
