import React, { useEffect } from "react";
import mainImg from "../../Assets/frond-works/mainImg.svg";
import ask from "../../Assets/faq/ask.svg";
import cantFind from "../../Assets/faq/cantFind.svg";
import { Accordion, AccordionItem, Image } from "@nextui-org/react";
import upArrow from "../../Assets/faq/up.svg";
import downArrow from "../../Assets/faq/down.svg";
import { Link } from "react-router-dom";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const faqData = [
    {
      title: "What is frond?",
      content:
        "Frond is a personal project management app that brings your plans to life. Whether you're organising the trip of a lifetime, planning a home-renovation or organising a wedding, Frond can help you keep track of your ideas and inspirations, manage timelines and budgets add collaborators, and share your projects with friends and family to make your plans come to life.",
    },
    {
      title: "What can frond do?",
      content:
        "Frond can help keep you organised when you are planning your next personal project, especially when you have so much to juggle as part of work and life. When you have a plan in place, you can focus on completing each task efficiently and effectively, without worrying about what comes next. Someone once told me to prepare for every eventuality, and it stuck for many years. Of course, frond was intended to be a fun and exciting personal planning app, taking away the seriousness and the deadlines we are used to on a professional level. Think of frond as your virtual planning assistant, without having to hire anyone.",
    },
    {
      title: "How to install frond app on my phone?",
      content:
        "Frond is available and accessible from a Webapp, Android and iOS. Most Android devices use the Google Play Store, while iPhones and iPads use the App Store.",
      list: [
        `Locate the app store: It's typically pre-installed and found on your home screen. It might be labelled "Play Store" or "App Store".`,
        `Search for the app: Use the search bar in the app store to find frond – alternatively you can use the QR to automatically install the application without the need to search for it on the app store.`,
        `Install the app: Once you find the app, tap "Install". Frond is a free app.`,
        `You might need to enter your password or fingerprint to confirm the installation.`,
        `Follow any on-screen instructions: Some apps might require additional setup steps after installation.`,
      ],
      desc: [
        `From a computer`,
        `To access frond from a laptop or desk top, type in the url www.thefrondapp.com and click on the Sign Up button, located on the top right hand section. If you have already signed up previously, then you can just sign in and start using frond right away!`,
      ],
    },
    {
      title: "How to create an account?",
      content:
        "Creating an account is easy, you can choose to create a new account using your personal email address, or by logging in with your Facebook, Gmail account, Apple ID or X account. The usual authentication steps will need to be followed in order to allow you to access frond.",
      desc: [
        `Please also remember to accept the frond Terms & Conditions, and have a read about how we use and process your date, nothing serious, just important to know. We want to make sure that you are happy for us to store your data, and help make recommendations based on your interests.`,
      ],
    },
    {
      title: "How to create a new frond?",
      content:
        "Creating a new frond is straightforward. Navigate to the “Create a frond” tab, and fill out the screen with the required information. Start off by giving it a name, select the Start and End Date, and select the relevant category for the project. The other fields are not required, so you can decide to Save the project and edit it later once you have a better idea of the priorities. Take some time to have a look at some of the pre-built templates for inspiration on how to build your first project. We will be continuously updating the template library with new ideas to inspire your next project.",
    },
    {
      title: "How to invite people to my frond?",
      content:
        "Adding people to your projects/fronds is easy. Once you have created a new project, you can navigate to the People tab and choose to add members to your project. You can invite users by adding their email address, or if they have a frond username, you can that instead.",
      desc: [
        `Frond will send the initiations to the project team members, who have the option to Accept or Decline the invitation.`,
      ],
    },
    {
      title: "How to edit, archive or delete your frond?",
      content:
        "Editing a project is quick and easy. By clicking on the project card, in the My fronds section, will display the project details, allowing you to update the fields and make the relevant changes needed.",
      desc: [
        `Archiving or deleting a project can be done by clicking on the three dots (ellipsis) visible on each project card, or by clicking on the check box on the project card, and selecting the Archive or Delete button available on the right hand section of the menu, if you decide to archive or delete multiple projects at the same time, to reduce the number of clicks.`,
      ],
    },
  ];

  const renderIndicator = ({ isOpen }) => {
    return <Image src={isOpen ? upArrow : downArrow} alt="arrow" />;
  };

  return (
    <section className="faq relative">
      <div className="intro bg-mainGradiant py-16">
        <div className="container xl:px-20 md:px-3 px-6 mx-auto mb-12">
          <div className="flex md:justify-between justify-center gap-x-7 gap-y-9 items-center md:flex-nowrap flex-wrap">
            <div className="text-white font-bold">
              <h2 className="text-[58px] mb-8">Ask Erda & Mia</h2>
              <p className="text-2xl leading-[27.6px]">
                Erda and Mia know everything about frond, they’ve heard us
                brainstorm and work endlessly to make frond pawfect. They will
                happily answer the FAQs and will feature in the chat with us
                feature…
              </p>
            </div>
            <Image
              src={ask}
              alt="Ask Frond"
              className="lg:min-w-[398px] md:min-w-[300px] sm:min-w-[398px] min-h-[217px]"
            />
          </div>
        </div>
      </div>
      <div className="faq-content py-14 relative">
        <div className="absolute left-0 top-0 sm:w-[446.13px] w-[80%] h-[499px] z-10 bg-faqBuble"></div>
        <div className="absolute right-0 bottom-[5%] sm:w-[285px] w-[80%] h-[381px] z-10 bg-faqPrint"></div>
        <div className="container xl:px-56 lg:px-40 md:px-10 px-6 mx-auto mb-14">
          <Accordion
            defaultExpandedKeys={["0"]}
            className="border-2 border-solid border-cardColor bg-white relative z-20 shadow-mainShadow rounded-4xl sm:px-10 px-5 sm:py-[30px] py-[15px]"
            itemClasses={{
              trigger: `${"py-0"}`,
              title:
                "font-bold text-black sm:text-2xl leading-[27.6px] text-lg",
              content:
                "text-[#404040] text-lg font-normal leading-[25.2px] px-4 mt-4",
            }}
          >
            {faqData.map((item, idx) => (
              <AccordionItem
                key={idx}
                aria-label={item.title}
                title={item.title}
                className={`${idx === faqData.length - 1 ? "mb-0" : "mb-5"} ${
                  idx === 0 ? "mt-0" : "mt-5"
                }`}
                disableIndicatorAnimation
                indicator={renderIndicator}
              >
                <p>{item.content}</p>
                {item.list && (
                  <ul className="ps-8 list-disc mb-6">
                    {item.list.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                )}
                {item.desc &&
                  item.desc.map((item, idx) => <p key={idx}>{item}</p>)}
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="container xl:px-40 lg:px-20 md:px-3 px-6 mx-auto">
          <div className="contact flex md:justify-between justify-center items-center gap-x-28 gap-y-8 md:flex-nowrap flex-wrap">
            <Image
              src={cantFind}
              alt="Cant't find"
              className="lg:min-w-[398px] sm:min-w-[300px] min-h-[217px]"
            />
            <div className="info">
              <h3 className="text-38px font-bold mb-8 text-textColor">
                Can't find what are you looking for?
              </h3>
              <Link
                to={"/contact-us"}
                className="text-white text-lg font-bold bg-mainGradiant rounded-[35px] w-[136px] h-[51px] flex justify-center items-center"
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
