import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

import closeIcon from "../../Assets/popup/cross-square.svg";
import { DeleteProjectsContext } from "Context/DeleteProjectsContext";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import { useNavigate } from "react-router-dom";

export default function DeleteModal({
  isOpen,
  onClose,
  stopClosePropagation,
  frondId,
  projects,
  flag,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { setDeleteProjects } = useContext(DeleteProjectsContext);

  const handleClose = (e) => {
    if (stopClosePropagation) {
    }
    setDeleteProjects([]);
    onClose();
  };

  const authToken = localStorage.getItem("token");

  const { setIsDeleteProject } = useContext(PopUpContext);

  const navigate = useNavigate();

  const deleteProject = async () => {
    setIsLoading(true);
    setIsDisabled(true);
    console.log(frondId);
    if (!frondId) return;
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/delete`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setIsDeleteProject(true);
      onClose();
      setTimeout(() => {
        setIsDeleteProject(false);
      }, 1000);
      if (flag) {
        navigate("/");
      }
      toast("Project deleted successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
    setIsDisabled(false);
  };

  const deleteProjects = async () => {
    setIsLoading(true);
    setIsDisabled(true);

    for (let i = 0; i < projects.length; i++) {
      try {
        const { data } = await axios.delete(
          `https://frond-admin.code-minds.tech/api/projects/${projects[i]}/delete`,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (i === projects.length - 1) {
          toast("Projects deleted", {
            autoClose: 1500,
            position: "bottom-right",
            hideProgressBar: true,
            className: "rounded-4xl custom-toast success-toast",
          });
          setIsDeleteProject(true);
          setTimeout(() => {
            setIsDeleteProject(false);
          });
          handleClose();
          setDeleteProjects([]);
        }
      } catch (error) {
        toast(error?.response?.data?.message, {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      }
    }
    setIsLoading(false);
    setIsDisabled(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        onClose();
        setDeleteProjects([]);
      }}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center py-5",
        body: "border-b-1 border-solid border-grayBorder pt-2 pb-4",
        closeButton: "hidden",
        base: "p-5 confirm-modal",
      }}
      hideCloseButton={true}
      onClick={(e) => e.stopPropagation()}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-end">
              <button onClick={handleClose} className="" aria-label="Close">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody>
              <p className="text-cardGrayColor font-bold text-center leading-[18.4px] sm:w-[256px] mx-auto">
                {projects?.length > 0
                  ? projects?.length > 1
                    ? "Are you sure you want to delete those projects?"
                    : "Are you sure you want to delete this project?"
                  : "Are you sure you want to delete this project?"}
              </p>
            </ModalBody>
            <ModalFooter className="flex items-center justify-center gap-2.5">
              <button
                onClick={() => {
                  handleClose();
                  setDeleteProjects([]);
                }}
                className="text-base font-bold leading-[18.4px] flex justify-center items-center rounded-[27px] py-[11px] px-[25px] w-[100px] h-[39px] gradiantBorder no-focus smallBorder"
              >
                <span className="text-transparent bg-mainGradiant bg-clip-text font-normal text-base">
                  Cancel
                </span>
              </button>
              <button
                disabled={isDisabled}
                onClick={() => {
                  if (projects?.length > 0) {
                    deleteProjects();
                  } else {
                    deleteProject();
                  }
                }}
                className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[100px] h-[39px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center text-white">
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </span>
                ) : (
                  "Delete"
                )}
              </button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
