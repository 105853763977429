import React from "react";
import Logo from "../../Assets/Images/logo.png";
import { Link } from "react-router-dom";
import { Image } from "@nextui-org/react";

const AuthNavbar = ({ info }) => {
  return (
    <div className="py-[16.5px]">
      <div className="container xl:px-44 sm:px-6 px-4 mx-auto relative z-20">
        <div className="content flex justify-between items-center">
          <div className="logo">
            <Link to={"/"}>
              <Image
                src={Logo}
                style={{ width: "125px", maxHeight: "35px" }}
                alt="Logo"
              />
            </Link>
          </div>
          <div className="auth-info">
            {info === "signUp" ? (
              <div className="flex items-center gap-4">
                <h4 className="text-lg font-normal hidden sm:flex">
                  Don’t have an account ?
                </h4>
                <Link
                  to={"/signup"}
                  className={`flex justify-center items-center rounded-4xl w-[109px] py-3 text-lg font-bold text-white bg-mainGradiant`}
                >
                  Sign Up
                </Link>
              </div>
            ) : (
              <div className="flex items-center gap-4">
                <h4 className="text-lg font-normal hidden sm:flex">
                  Already have an account ?
                </h4>
                <Link
                  to={"/login"}
                  className={`flex justify-center items-center rounded-4xl w-[109px] py-3 text-lg font-bold text-white bg-mainGradiant`}
                >
                  Log In
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthNavbar;
