// import React, { useState } from "react";

// // Router redirection
// import { Link, Redirect } from "react-router-dom";

// // redux connect with react
// import { connect } from "react-redux";
// import MainTitle from "components/MainTitle";
// import Footer from "components/Navbar/Footer";
// import { Image } from "@nextui-org/react";

// import GirlImage from "../../Assets/Images/girl.png";
// import ParisImage from "../../Assets/Images/ParisImage.png";
// import AirbnbImage from "../../Assets/Images/airbnb.png";
// import SlackImage from "../../Assets/Images/slack.png";
// import TripadvisorImage from "../../Assets/Images/trip advisor.png";
// import BookingImage from "../../Assets/Images/booking.png";
// import DesignhomeImage from "../../Assets/Images/designhome.png";
// import VentureImage from "../../Assets/Images/venture.png";
// import EscapeImage from "../../Assets/Images/escape.png";
// import CelebrateImage from "../../Assets/Images/celebrate.png";
// import WhattheysayImage from "../../Assets/Images/whattheysay.png";
// import { Button } from "@nextui-org/react";
// import { Divider } from "@nextui-org/react";

// import {
//   GooglePlayButton,
//   ButtonsContainer,
//   AppStoreButton,
// } from "react-mobile-app-button";

// import { TypeAnimation } from "react-type-animation";

// import Logo from "../../Assets/Images/Frond_Logo_White.png";

// const HomepageApp = ({ isAuthenticated }) => {
//   // const capFirstLetter = (text) =>{
//   //   return text.
//   // }

//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [message, setMessage] = useState("");

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//   };

//   const handleSubmit = e => {
//     e.preventDefault();
//     // Handle form submission logic here, such as sending data to a server
//     console.log(`Name: ${name}, Email: ${email}, Message: ${message}`);
//     // Clear form fields after submission
//     setName("");
//     setEmail("");
//     setMessage("");
//   };

//   return (
//     <div className=" font-helvetica">
//       <section class="py-12 md:py-24">
//         <div class="w-full max-w-6xl mx-auto px-4 sm:px-6 md:px-8 relative">
//           <div class="shadow rounded-xl">
//             <div class="grid overflow-hidden text-white shadow-xl md:grid-cols-2 bg-RoyalBlue rounded-xl">
//               <aside class="p-8 space-y-4 md:p-16">
//                 <h2 class="text-xl font-bold tracking-tight md:text-4xl font-headline">
//                   <Image src={Logo} width={120} className=" rounded-none " />
//                   {/* Ready to dive in? Start your free trial today. */}
//                 </h2>

//                 <MainTitle
//                   title2={"Plan your experiences."}
//                   title3={"share your plans."}
//                 />
//                 <p class="font-medium text-blue-100 md:text-2xl">
//                   Complete your personal projects and realise your goals with
//                   frond
//                 </p>
//                 <div>
//                   <Divider className="my-3 bg-White" />
//                   <div className="mt-3 flex flex-wrap gap-2 justify-center">
//                     <AppStoreButton
//                       theme={"dark"}
//                       // width={180}
//                       title=""
//                       className={"text-xs"}
//                     />
//                     <GooglePlayButton
//                       // width={180}
//                       title=""
//                       theme={"dark"}
//                       className={"text-xs"}
//                     />
//                   </div>
//                 </div>
//               </aside>

//               <aside class="relative hidden md:block">
//                 <img
//                   class="absolute inset-0 object-cover object-left-top w-full h-full mt-8 -mr-16 rounded-tl-lg"
//                   src={ParisImage}
//                   alt="Discover our beautiful panel"
//                 />
//               </aside>
//             </div>
//           </div>
//         </div>
//       </section>
//       {/* <div class="bg-Grey  ">
//         <div class=" p-8 max-w-screen-xl px-8 mx-auto flex flex-col lg:flex-row items-start justify-center align-middle">
//           <div class="flex flex-col w-full lg:w-6/12 justify-center lg:pt-24 lg:items-start text-center lg:text-left mb-5 md:mb-0">
//             <Image src={Logo} width={120} className=" rounded-none " />
//             <MainTitle
//               title2={"Plan your experiences."}
//               title3={"share your plans."}
//             />

//             <p
//               data-aos="fade-down"
//               data-aos-once="true"
//               data-aos-delay="300"
//               class="leading-normal text-2xl  text-ChalkBlack "
//             >
//               Complete your personal projects and realise your goals with frond
//             </p>
//             <div
//               data-aos="fade-up"
//               data-aos-once="true"
//               data-aos-delay="700"
//               class="w-full md:flex items-center justify-center lg:justify-start md:space-x-5"
//             ></div>
//             <div className="mt-3">
//               <ButtonsContainer>
//                 <AppStoreButton
//                   theme={"dark"}
//                   width={180}
//                   className={"custom-style"}
//                 />
//                 <GooglePlayButton
//                   width={180}
//                   theme={"dark"}
//                   className={"custom-style"}
//                 />
//               </ButtonsContainer>
//             </div>
//           </div>
//           <div data-aos="fade-left" class="sm:w-1/2  sm:mt-0">
//             <img
//               class="rounded-xl w-full z-20 relative"
//               src={ParisImage}
//               alt=""
//             />
//           </div>
//         </div>
//       </div> */}
//       <div className="max-w-4xl mt-16 mx-auto flex flex-col items-center justify-center">
//         <h1 className="text-center mb-3 text-gray-400 font-medium">
//           Our Top Integrations
//         </h1>
//         <div className="flex flex-row flex-wrap justify-center items-center gap-10">
//           <img className="w-25 h-12" src={AirbnbImage} alt="AirBnb" />
//           <img className="w-25 h-12" src={SlackImage} alt="Slack" />
//           <img
//             className="w-25 h-12"
//             src={TripadvisorImage}
//             alt="trip advisor"
//           />
//           <img className="w-25 h-12" src={BookingImage} alt="Booking" />
//         </div>
//       </div>

//       <div class="container px-4 lg:px-8 mx-auto max-w-screen-xl text-ChalkBlack overflow-x-hidden ">
//         <div data-aos="flip-down" class="text-center max-w-screen-md mx-auto">
//           <h1 class="text-3xl font-bold mb-4">
//             What is<span class="text-ChalkBlack"> frond?</span>
//           </h1>
//           <p class="text-ChalkBlack">
//             Frond is a personal project management app that brings your plans to
//             life. Whether you’re organising the trip of a lifetime, planning a
//             home-renovation or organising a wedding, frond can help you keep
//             track of your ideas and inspirations, manage timelines and budgets
//             add collaborators, and share your projects with friends and family
//             to make your plans come to life.
//           </p>
//         </div>

//         <div data-aos="flip-up" class="max-w-xl mx-auto text-center mt-24">
//           <h1 class="font-bold text-ChalkBlack my-3 text-2xl">
//             Start planing <span class="text-ChalkBlack">with frond.</span>
//           </h1>
//         </div>

//         <div class="grid md:grid-cols-4 gap-14 md:gap-5 mt-20">
//           <div
//             data-aos="fade-up"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#5B72EE",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 33 46"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               YOUR HOME
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               Get on top of your build, renovation, redecoration and extension
//               planning with frond.
//             </p>
//           </div>
//           <div
//             data-aos="fade-up"
//             data-aos-delay="150"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#F48C06",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 48 48"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 23.9999H34.2857C33.3389 23.9999 32.5714 24.7674 32.5714 25.7142C32.5714 26.661 33.3389 27.4285 34.2857 27.4285H37.7143C38.6611 27.4285 39.4286 26.661 39.4286 25.7142C39.4286 24.7674 38.661 23.9999 37.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 30.8571H10.2857C9.33889 30.8571 8.57138 31.6246 8.57138 32.5714C8.57138 33.5182 9.33889 34.2857 10.2857 34.2857H13.7142C14.661 34.2857 15.4285 33.5182 15.4285 32.5714C15.4285 31.6246 14.661 30.8571 13.7142 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 30.8571H22.2857C21.3389 30.8571 20.5714 31.6246 20.5714 32.5714C20.5714 33.5182 21.3389 34.2857 22.2857 34.2857H25.7143C26.6611 34.2857 27.4286 33.5182 27.4286 32.5714C27.4286 31.6246 26.6611 30.8571 25.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 30.8571H34.2857C33.3389 30.8571 32.5714 31.6246 32.5714 32.5714C32.5714 33.5182 33.3389 34.2857 34.2857 34.2857H37.7143C38.6611 34.2857 39.4286 33.5182 39.4286 32.5714C39.4285 31.6246 38.661 30.8571 37.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 37.7142H10.2857C9.33889 37.7142 8.57138 38.4817 8.57138 39.4286C8.57138 40.3754 9.33889 41.1428 10.2857 41.1428H13.7142C14.661 41.1428 15.4285 40.3753 15.4285 39.4284C15.4285 38.4816 14.661 37.7142 13.7142 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 37.7142H22.2857C21.3389 37.7142 20.5714 38.4817 20.5714 39.4285C20.5714 40.3754 21.3389 41.1429 22.2857 41.1429H25.7143C26.6611 41.1429 27.4286 40.3754 27.4286 39.4285C27.4286 38.4817 26.6611 37.7142 25.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 37.7142H34.2857C33.3389 37.7142 32.5714 38.4817 32.5714 39.4285C32.5714 40.3754 33.3389 41.1429 34.2857 41.1429H37.7143C38.6611 41.1429 39.4286 40.3754 39.4286 39.4285C39.4286 38.4817 38.661 37.7142 37.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               YOUR VENTURES
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               Use frond to plan new businesses and ventures. However big or
//               small, you can store your ideas, track your progress and share
//               with others.
//             </p>
//           </div>
//           <div
//             data-aos="fade-up"
//             data-aos-delay="300"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#29B9E7",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 55 44"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.25 19.25C11.2836 19.25 13.75 16.7836 13.75 13.75C13.75 10.7164 11.2836 8.25 8.25 8.25C5.21641 8.25 2.75 10.7164 2.75 13.75C2.75 16.7836 5.21641 19.25 8.25 19.25ZM46.75 19.25C49.7836 19.25 52.25 16.7836 52.25 13.75C52.25 10.7164 49.7836 8.25 46.75 8.25C43.7164 8.25 41.25 10.7164 41.25 13.75C41.25 16.7836 43.7164 19.25 46.75 19.25ZM49.5 22H44C42.4875 22 41.1211 22.6102 40.1242 23.5984C43.5875 25.4977 46.0453 28.9266 46.5781 33H52.25C53.7711 33 55 31.7711 55 30.25V27.5C55 24.4664 52.5336 22 49.5 22ZM27.5 22C32.8195 22 37.125 17.6945 37.125 12.375C37.125 7.05547 32.8195 2.75 27.5 2.75C22.1805 2.75 17.875 7.05547 17.875 12.375C17.875 17.6945 22.1805 22 27.5 22ZM34.1 24.75H33.3867C31.5992 25.6094 29.6141 26.125 27.5 26.125C25.3859 26.125 23.4094 25.6094 21.6133 24.75H20.9C15.4344 24.75 11 29.1844 11 34.65V37.125C11 39.4023 12.8477 41.25 15.125 41.25H39.875C42.1523 41.25 44 39.4023 44 37.125V34.65C44 29.1844 39.5656 24.75 34.1 24.75ZM14.8758 23.5984C13.8789 22.6102 12.5125 22 11 22H5.5C2.46641 22 0 24.4664 0 27.5V30.25C0 31.7711 1.22891 33 2.75 33H8.41328C8.95469 28.9266 11.4125 25.4977 14.8758 23.5984Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               YOUR ESCAPES
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               Store all your tickets, reservations, useful information,
//               excursion and entertainment plans with frond’s easy to use
//               interface.
//             </p>
//           </div>
//           <div
//             data-aos="fade-up"
//             data-aos-delay="300"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#29B9E7",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 55 44"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M8.25 19.25C11.2836 19.25 13.75 16.7836 13.75 13.75C13.75 10.7164 11.2836 8.25 8.25 8.25C5.21641 8.25 2.75 10.7164 2.75 13.75C2.75 16.7836 5.21641 19.25 8.25 19.25ZM46.75 19.25C49.7836 19.25 52.25 16.7836 52.25 13.75C52.25 10.7164 49.7836 8.25 46.75 8.25C43.7164 8.25 41.25 10.7164 41.25 13.75C41.25 16.7836 43.7164 19.25 46.75 19.25ZM49.5 22H44C42.4875 22 41.1211 22.6102 40.1242 23.5984C43.5875 25.4977 46.0453 28.9266 46.5781 33H52.25C53.7711 33 55 31.7711 55 30.25V27.5C55 24.4664 52.5336 22 49.5 22ZM27.5 22C32.8195 22 37.125 17.6945 37.125 12.375C37.125 7.05547 32.8195 2.75 27.5 2.75C22.1805 2.75 17.875 7.05547 17.875 12.375C17.875 17.6945 22.1805 22 27.5 22ZM34.1 24.75H33.3867C31.5992 25.6094 29.6141 26.125 27.5 26.125C25.3859 26.125 23.4094 25.6094 21.6133 24.75H20.9C15.4344 24.75 11 29.1844 11 34.65V37.125C11 39.4023 12.8477 41.25 15.125 41.25H39.875C42.1523 41.25 44 39.4023 44 37.125V34.65C44 29.1844 39.5656 24.75 34.1 24.75ZM14.8758 23.5984C13.8789 22.6102 12.5125 22 11 22H5.5C2.46641 22 0 24.4664 0 27.5V30.25C0 31.7711 1.22891 33 2.75 33H8.41328C8.95469 28.9266 11.4125 25.4977 14.8758 23.5984Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack lg:h-14 pt-3">
//               YOUR EVENTS
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               Dates, venues, guest-lists, menus, entertainment, transport,
//               caterers.
//             </p>
//           </div>
//         </div>

//         <div class="mt-28">
//           <div
//             data-aos="fade-up"
//             class="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-6 lg:space-x-10 mt-7"
//           ></div>
//         </div>

//         <div class="sm:flex items-center sm:space-x-8 mt-36">
//           <div data-aos="fade-right" class="sm:w-1/2 relative">
//             <div class="bg-Orange rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
//             <h1 class="font-semibold text-2xl relative z-50 text-ChalkBlack lg:pr-10">
//               Plan your home,{" "}
//               <span class="text-ChalkBlack">you can do with frond</span>
//             </h1>
//             <p class="py-5 lg:pr-32">
//               How much do we do in our homes?To our homes? With our homes? We
//               spend the largest slice of our income on them and spend as much
//               time as life allows there. Shouldn’t we plan what, when and how we
//               manage them?
//             </p>
//           </div>

//           <div data-aos="fade-left" class="sm:w-1/2 relative mt-10 sm:mt-0">
//             <div
//               style={{
//                 backgroundColor: "#23BDEE",
//               }}
//               class="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"
//             ></div>
//             <img
//               class="rounded-xl z-20 relative"
//               src={DesignhomeImage}
//               alt=""
//             />

//             <div class="bg-Orange w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
//           </div>
//         </div>
//         <div class="grid md:grid-cols-2 gap-14 md:gap-5 mt-20">
//           <div
//             data-aos="fade-up"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#5B72EE",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 33 46"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Planning together
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               The first problem Caroline and David faced was co-ordinating their
//               own ideas and plans. While David managed the relocation of his job
//               to Yorkshire he spent four days a week in London and Caroline, who
//               had found a new teaching job in nearby Halifax, stayed locally.
//             </p>
//           </div>
//           <div
//             data-aos="fade-up"
//             data-aos-delay="150"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#F48C06",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 48 48"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 23.9999H34.2857C33.3389 23.9999 32.5714 24.7674 32.5714 25.7142C32.5714 26.661 33.3389 27.4285 34.2857 27.4285H37.7143C38.6611 27.4285 39.4286 26.661 39.4286 25.7142C39.4286 24.7674 38.661 23.9999 37.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 30.8571H10.2857C9.33889 30.8571 8.57138 31.6246 8.57138 32.5714C8.57138 33.5182 9.33889 34.2857 10.2857 34.2857H13.7142C14.661 34.2857 15.4285 33.5182 15.4285 32.5714C15.4285 31.6246 14.661 30.8571 13.7142 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 30.8571H22.2857C21.3389 30.8571 20.5714 31.6246 20.5714 32.5714C20.5714 33.5182 21.3389 34.2857 22.2857 34.2857H25.7143C26.6611 34.2857 27.4286 33.5182 27.4286 32.5714C27.4286 31.6246 26.6611 30.8571 25.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 30.8571H34.2857C33.3389 30.8571 32.5714 31.6246 32.5714 32.5714C32.5714 33.5182 33.3389 34.2857 34.2857 34.2857H37.7143C38.6611 34.2857 39.4286 33.5182 39.4286 32.5714C39.4285 31.6246 38.661 30.8571 37.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 37.7142H10.2857C9.33889 37.7142 8.57138 38.4817 8.57138 39.4286C8.57138 40.3754 9.33889 41.1428 10.2857 41.1428H13.7142C14.661 41.1428 15.4285 40.3753 15.4285 39.4284C15.4285 38.4816 14.661 37.7142 13.7142 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 37.7142H22.2857C21.3389 37.7142 20.5714 38.4817 20.5714 39.4285C20.5714 40.3754 21.3389 41.1429 22.2857 41.1429H25.7143C26.6611 41.1429 27.4286 40.3754 27.4286 39.4285C27.4286 38.4817 26.6611 37.7142 25.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 37.7142H34.2857C33.3389 37.7142 32.5714 38.4817 32.5714 39.4285C32.5714 40.3754 33.3389 41.1429 34.2857 41.1429H37.7143C38.6611 41.1429 39.4286 40.3754 39.4286 39.4285C39.4286 38.4817 38.661 37.7142 37.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Storing everything in one place
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               "I am notorious for keeping notebooks, pin-boards and Post-It
//               notes stuck to my monitor with thoughts and numbers and prices all
//               over them", admits David. "Having everything in frond meant never
//               having to scrabble around my desk trying to find the one crucial
//               number that Caroline needed.
//             </p>
//           </div>
//         </div>

//         <div class="md:flex mt-40 md:space-x-10 items-start">
//           <div data-aos="fade-down" class="md:w-7/12 relative">
//             <div
//               style={{
//                 backgroundColor: "#33EFA0",
//               }}
//               class="w-32 h-32 rounded-full absolute z-0 left-4 -top-12 animate-pulse"
//             ></div>
//             <div
//               style={{
//                 backgroundColor: "#33D9EF",
//               }}
//               class="w-5 h-5 rounded-full absolute z-0 left-36 -top-12 animate-ping"
//             ></div>
//             <img class="relative  w-25 h-25" src={VentureImage} alt="" />

//             <div
//               style={{
//                 backgroundColor: "#F56666",
//               }}
//               class="w-5 h-5 rounded-full absolute z-0 right-52 bottom-1 animate-ping"
//             ></div>
//           </div>
//           <div
//             data-aos="fade-down"
//             class="md:w-5/12 mt-20 md:mt-0 text-ChalkBlack"
//           >
//             <h1 class="text-2xl font-semibold text-ChalkBlack lg:pr-5">
//               <span class="text-ChalkBlack">Plan your venture</span> you can do
//               with frond
//             </h1>
//             <div class="flex items-center space-x-5 my-5">
//               <p>
//                 Not all planning is for the fun things in life! Meet Oliver and
//                 Alice. Together they run MyDesign - a young, innovative agency
//                 bringing their talents for design, software engineering and
//                 product development to a range of clients across Europe.
//                 Planning and keeping their meetings, development sprints,
//                 images, updates and timelines in frond allows them to always be
//                 in the loop with their projects.
//               </p>
//             </div>
//           </div>
//         </div>
//         <div class="grid md:grid-cols-2 gap-14 md:gap-5 mt-20">
//           <div
//             data-aos="fade-up"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#5B72EE",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 33 46"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Planning together
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               Collaboration lies at the heart of frond. Sharing ideas, sharing
//               information, sharing tasks, sharing news. Working with the people
//               that matter.
//               <br />
//               <br />
//               Invite your key people to your plans. Let them see what you're
//               doing. Let them contribute details, ideas, information and gain
//               the benefits of multi-valent input.
//             </p>
//           </div>

//           <div
//             data-aos="fade-up"
//             data-aos-delay="150"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#F48C06",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 48 48"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 23.9999H34.2857C33.3389 23.9999 32.5714 24.7674 32.5714 25.7142C32.5714 26.661 33.3389 27.4285 34.2857 27.4285H37.7143C38.6611 27.4285 39.4286 26.661 39.4286 25.7142C39.4286 24.7674 38.661 23.9999 37.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 30.8571H10.2857C9.33889 30.8571 8.57138 31.6246 8.57138 32.5714C8.57138 33.5182 9.33889 34.2857 10.2857 34.2857H13.7142C14.661 34.2857 15.4285 33.5182 15.4285 32.5714C15.4285 31.6246 14.661 30.8571 13.7142 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 30.8571H22.2857C21.3389 30.8571 20.5714 31.6246 20.5714 32.5714C20.5714 33.5182 21.3389 34.2857 22.2857 34.2857H25.7143C26.6611 34.2857 27.4286 33.5182 27.4286 32.5714C27.4286 31.6246 26.6611 30.8571 25.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 30.8571H34.2857C33.3389 30.8571 32.5714 31.6246 32.5714 32.5714C32.5714 33.5182 33.3389 34.2857 34.2857 34.2857H37.7143C38.6611 34.2857 39.4286 33.5182 39.4286 32.5714C39.4285 31.6246 38.661 30.8571 37.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 37.7142H10.2857C9.33889 37.7142 8.57138 38.4817 8.57138 39.4286C8.57138 40.3754 9.33889 41.1428 10.2857 41.1428H13.7142C14.661 41.1428 15.4285 40.3753 15.4285 39.4284C15.4285 38.4816 14.661 37.7142 13.7142 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 37.7142H22.2857C21.3389 37.7142 20.5714 38.4817 20.5714 39.4285C20.5714 40.3754 21.3389 41.1429 22.2857 41.1429H25.7143C26.6611 41.1429 27.4286 40.3754 27.4286 39.4285C27.4286 38.4817 26.6611 37.7142 25.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 37.7142H34.2857C33.3389 37.7142 32.5714 38.4817 32.5714 39.4285C32.5714 40.3754 33.3389 41.1429 34.2857 41.1429H37.7143C38.6611 41.1429 39.4286 40.3754 39.4286 39.4285C39.4286 38.4817 38.661 37.7142 37.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Storing everything in one place
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               With frond there is no more digging through emails, notebooks and
//               text messages for that crucial phone number, email address, name,
//               contact, photo.
//               <br />
//               <br />
//               Track your work projects from initial meeting to wrap-up lunch
//               with every task, step and milestone accessible at your fingertips.
//             </p>
//           </div>
//         </div>
//         <div class="flex flex-col md:flex-row items-center md:space-x-10 mt-16">
//           <div data-aos="fade-right" class="md:w-1/2 lg:pl-14">
//             <h1 class="text-ChalkBlack font-semibold text-3xl lg:pr-56">
//               <span class="text-ChalkBlack">Plan</span> your escape
//             </h1>
//             <p class="text-ChalkBlack my-4 lg:pr-32">
//               Andy and Maria are old friends who live in different towns but try
//               and make an effort to see each other once a year. When they do,
//               they like to make an effort and usually book a trip overseas, as
//               they both love travelling and exploring new places.
//             </p>
//           </div>

//           <img data-aos="fade-left" class="md:w-1/2" src={EscapeImage} />
//         </div>
//         <div class="grid md:grid-cols-2 gap-14 md:gap-5 mt-20">
//           <div
//             data-aos="fade-up"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#5B72EE",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 33 46"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Planning together
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               Maria adds Andy to the project and together they use frond’s
//               Scrapbook feature to populate the project with things to do,
//               places to eat and recommendations from friends.
//             </p>
//           </div>
//           <div
//             data-aos="fade-up"
//             data-aos-delay="150"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#F48C06",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 48 48"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 23.9999H34.2857C33.3389 23.9999 32.5714 24.7674 32.5714 25.7142C32.5714 26.661 33.3389 27.4285 34.2857 27.4285H37.7143C38.6611 27.4285 39.4286 26.661 39.4286 25.7142C39.4286 24.7674 38.661 23.9999 37.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 30.8571H10.2857C9.33889 30.8571 8.57138 31.6246 8.57138 32.5714C8.57138 33.5182 9.33889 34.2857 10.2857 34.2857H13.7142C14.661 34.2857 15.4285 33.5182 15.4285 32.5714C15.4285 31.6246 14.661 30.8571 13.7142 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 30.8571H22.2857C21.3389 30.8571 20.5714 31.6246 20.5714 32.5714C20.5714 33.5182 21.3389 34.2857 22.2857 34.2857H25.7143C26.6611 34.2857 27.4286 33.5182 27.4286 32.5714C27.4286 31.6246 26.6611 30.8571 25.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 30.8571H34.2857C33.3389 30.8571 32.5714 31.6246 32.5714 32.5714C32.5714 33.5182 33.3389 34.2857 34.2857 34.2857H37.7143C38.6611 34.2857 39.4286 33.5182 39.4286 32.5714C39.4285 31.6246 38.661 30.8571 37.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 37.7142H10.2857C9.33889 37.7142 8.57138 38.4817 8.57138 39.4286C8.57138 40.3754 9.33889 41.1428 10.2857 41.1428H13.7142C14.661 41.1428 15.4285 40.3753 15.4285 39.4284C15.4285 38.4816 14.661 37.7142 13.7142 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 37.7142H22.2857C21.3389 37.7142 20.5714 38.4817 20.5714 39.4285C20.5714 40.3754 21.3389 41.1429 22.2857 41.1429H25.7143C26.6611 41.1429 27.4286 40.3754 27.4286 39.4285C27.4286 38.4817 26.6611 37.7142 25.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 37.7142H34.2857C33.3389 37.7142 32.5714 38.4817 32.5714 39.4285C32.5714 40.3754 33.3389 41.1429 34.2857 41.1429H37.7143C38.6611 41.1429 39.4286 40.3754 39.4286 39.4285C39.4286 38.4817 38.661 37.7142 37.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Storing everything in one place
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               On one of their earlier trips, Andy threw a spanner in the works
//               by losing his bag which had their train tickets and his passport
//               in it, so Maria asks him to upload a photo of passport and adds in
//               flight details and the contact numbers for their hire car company
//               to frond.
//             </p>
//           </div>
//         </div>

//         <div class="mt-20 flex flex-col-reverse md:flex-row items-center md:space-x-10">
//           <div data-aos="fade-right" class="md:w-6/12">
//             <img class="md:w-11/12" src={CelebrateImage} />
//           </div>
//           <div
//             data-aos="fade-left"
//             class="md:w-6/12 md:transform md:-translate-y-20"
//           >
//             <h1 class="font-semibold text-ChalkBlack text-3xl lg:pr-64">
//               Plan <span class="text-ChalkBlack">your event</span>
//             </h1>
//             <p class="text-ChalkBlack my-5 lg:pr-52">
//               Sandra is a busy professional, working full time and travelling a
//               lot for work. She enjoys going to bars and restaurants with her
//               boyfriend James in her free time and whenever they get the chance
//               they like to go travelling together.
//             </p>
//           </div>
//         </div>
//         <div class="grid md:grid-cols-2 gap-14 md:gap-5 mt-20">
//           <div
//             data-aos="fade-up"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#5B72EE",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 33 46"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M24.75 23H8.25V28.75H24.75V23ZM32.3984 9.43359L23.9852 0.628906C23.5984 0.224609 23.0742 0 22.5242 0H22V11.5H33V10.952C33 10.3859 32.7852 9.83789 32.3984 9.43359ZM19.25 12.2188V0H2.0625C0.919531 0 0 0.961328 0 2.15625V43.8438C0 45.0387 0.919531 46 2.0625 46H30.9375C32.0805 46 33 45.0387 33 43.8438V14.375H21.3125C20.1781 14.375 19.25 13.4047 19.25 12.2188ZM5.5 6.46875C5.5 6.07164 5.80766 5.75 6.1875 5.75H13.0625C13.4423 5.75 13.75 6.07164 13.75 6.46875V7.90625C13.75 8.30336 13.4423 8.625 13.0625 8.625H6.1875C5.80766 8.625 5.5 8.30336 5.5 7.90625V6.46875ZM5.5 12.2188C5.5 11.8216 5.80766 11.5 6.1875 11.5H13.0625C13.4423 11.5 13.75 11.8216 13.75 12.2188V13.6562C13.75 14.0534 13.4423 14.375 13.0625 14.375H6.1875C5.80766 14.375 5.5 14.0534 5.5 13.6562V12.2188ZM27.5 39.5312C27.5 39.9284 27.1923 40.25 26.8125 40.25H19.9375C19.5577 40.25 19.25 39.9284 19.25 39.5312V38.0938C19.25 37.6966 19.5577 37.375 19.9375 37.375H26.8125C27.1923 37.375 27.5 37.6966 27.5 38.0938V39.5312ZM27.5 21.5625V30.1875C27.5 30.9817 26.8847 31.625 26.125 31.625H6.875C6.11531 31.625 5.5 30.9817 5.5 30.1875V21.5625C5.5 20.7683 6.11531 20.125 6.875 20.125H26.125C26.8847 20.125 27.5 20.7683 27.5 21.5625Z"
//                   fill="white"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Sharing plans
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               By using frond, Sandra can keep track of all the important
//               planning information in one central place. She can easily share
//               her plans with James and her friends and family, making
//               information easier to access and provide them with updates in real
//               time, rather than them all emailing each other back and forth.
//             </p>
//           </div>
//           <div
//             data-aos="fade-up"
//             data-aos-delay="150"
//             class="bg-white shadow-xl p-6 text-center rounded-xl"
//           >
//             <div
//               style={{
//                 backgroundColor: "#F48C06",
//               }}
//               class="rounded-full w-16 h-16 flex items-center justify-center mx-auto shadow-lg transform -translate-y-12"
//             >
//               <svg
//                 class="w-6 h-6 text-white"
//                 viewBox="0 0 48 48"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M12 0C11.0532 0 10.2857 0.767511 10.2857 1.71432V5.14285H13.7142V1.71432C13.7142 0.767511 12.9467 0 12 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M36 0C35.0532 0 34.2856 0.767511 34.2856 1.71432V5.14285H37.7142V1.71432C37.7143 0.767511 36.9468 0 36 0Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M42.8571 5.14282H37.7143V12C37.7143 12.9468 36.9468 13.7143 36 13.7143C35.0532 13.7143 34.2857 12.9468 34.2857 12V5.14282H13.7142V12C13.7142 12.9468 12.9467 13.7143 11.9999 13.7143C11.0531 13.7143 10.2856 12.9468 10.2856 12V5.14282H5.14285C2.30253 5.14282 0 7.44535 0 10.2857V42.8571C0 45.6974 2.30253 48 5.14285 48H42.8571C45.6975 48 48 45.6974 48 42.8571V10.2857C48 7.44535 45.6975 5.14282 42.8571 5.14282ZM44.5714 42.8571C44.5714 43.8039 43.8039 44.5714 42.857 44.5714H5.14285C4.19605 44.5714 3.42854 43.8039 3.42854 42.8571V20.5714H44.5714V42.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 23.9999H10.2857C9.33889 23.9999 8.57138 24.7674 8.57138 25.7142C8.57138 26.661 9.33889 27.4285 10.2857 27.4285H13.7142C14.661 27.4285 15.4285 26.661 15.4285 25.7142C15.4285 24.7674 14.661 23.9999 13.7142 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 23.9999H22.2857C21.3389 23.9999 20.5714 24.7674 20.5714 25.7142C20.5714 26.661 21.3389 27.4285 22.2857 27.4285H25.7143C26.6611 27.4285 27.4286 26.661 27.4286 25.7142C27.4286 24.7674 26.6611 23.9999 25.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 23.9999H34.2857C33.3389 23.9999 32.5714 24.7674 32.5714 25.7142C32.5714 26.661 33.3389 27.4285 34.2857 27.4285H37.7143C38.6611 27.4285 39.4286 26.661 39.4286 25.7142C39.4286 24.7674 38.661 23.9999 37.7143 23.9999Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 30.8571H10.2857C9.33889 30.8571 8.57138 31.6246 8.57138 32.5714C8.57138 33.5182 9.33889 34.2857 10.2857 34.2857H13.7142C14.661 34.2857 15.4285 33.5182 15.4285 32.5714C15.4285 31.6246 14.661 30.8571 13.7142 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 30.8571H22.2857C21.3389 30.8571 20.5714 31.6246 20.5714 32.5714C20.5714 33.5182 21.3389 34.2857 22.2857 34.2857H25.7143C26.6611 34.2857 27.4286 33.5182 27.4286 32.5714C27.4286 31.6246 26.6611 30.8571 25.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 30.8571H34.2857C33.3389 30.8571 32.5714 31.6246 32.5714 32.5714C32.5714 33.5182 33.3389 34.2857 34.2857 34.2857H37.7143C38.6611 34.2857 39.4286 33.5182 39.4286 32.5714C39.4285 31.6246 38.661 30.8571 37.7143 30.8571Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M13.7142 37.7142H10.2857C9.33889 37.7142 8.57138 38.4817 8.57138 39.4286C8.57138 40.3754 9.33889 41.1428 10.2857 41.1428H13.7142C14.661 41.1428 15.4285 40.3753 15.4285 39.4284C15.4285 38.4816 14.661 37.7142 13.7142 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M25.7143 37.7142H22.2857C21.3389 37.7142 20.5714 38.4817 20.5714 39.4285C20.5714 40.3754 21.3389 41.1429 22.2857 41.1429H25.7143C26.6611 41.1429 27.4286 40.3754 27.4286 39.4285C27.4286 38.4817 26.6611 37.7142 25.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//                 <path
//                   d="M37.7143 37.7142H34.2857C33.3389 37.7142 32.5714 38.4817 32.5714 39.4285C32.5714 40.3754 33.3389 41.1429 34.2857 41.1429H37.7143C38.6611 41.1429 39.4286 40.3754 39.4286 39.4285C39.4286 38.4817 38.661 37.7142 37.7143 37.7142Z"
//                   fill="#F5F5FC"
//                 />
//               </svg>
//             </div>
//             <h1 class="font-medium text-xl mb-3 lg:px-14 text-ChalkBlack">
//               Track those tasks
//             </h1>
//             <p class="px-4 text-ChalkBlack">
//               frond gives Sandra the ability to set tasks and follow them up
//               them easily without leaving anything to chance, and everybody
//               knows who’s responsible for each part of the plan. frond provides
//               better control over the tasks that need to happen before her
//               birthday ahead of time and lets Sandra and her friends keep a
//               handle on when they need to be done.
//             </p>
//           </div>
//         </div>

//         <div class="mt-24 flex flex-col-reverse md:flex-row items-start md:space-x-10">
//           <div data-aos="zoom-in-right" class="md:w-5/12">
//             <h1 class="font-semibold text-ChalkBlack text-2xl lg:pr-40">
//               What They Say?
//             </h1>
//             <p class="text-ChalkBlack my-5 lg:pr-36">
//               “I never thought I would enjoy taking on new projects quite so
//               much! With frond I can't get enough! ” – Caroline Purton, Wedding
//               Planner
//             </p>

//             <button class="flex items-center space-x-3 pl-3 border-b border-l border-t border-ChalkBlack text-ChalkBlack font-medium my-4 focus:outline-none transform transition hover:scale-110 duration-300 ease-in-out rounded-full">
//               <span>Write your opinion</span>
//               <div class="border border-ChalkBlack h-14 w-14 rounded-full flex items-center justify-center">
//                 <svg
//                   class="w-5 h-5"
//                   viewBox="0 0 26 16"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     d="M25.7071 8.70711C26.0976 8.31658 26.0976 7.68342 25.7071 7.2929L19.3431 0.928934C18.9526 0.538409 18.3195 0.538409 17.9289 0.928934C17.5384 1.31946 17.5384 1.95262 17.9289 2.34315L23.5858 8L17.9289 13.6569C17.5384 14.0474 17.5384 14.6805 17.9289 15.0711C18.3195 15.4616 18.9526 15.4616 19.3431 15.0711L25.7071 8.70711ZM-8.74228e-08 9L25 9L25 7L8.74228e-08 7L-8.74228e-08 9Z"
//                     fill="#F48C06"
//                   />
//                 </svg>
//               </div>
//             </button>
//           </div>
//           <div data-aos="zoom-in-left" class="md:w-7/12">
//             <img class="md:w-10/12 mx-auto" src={WhattheysayImage} />
//           </div>
//         </div>
//         <p class="text-ChalkBlack my-5 lg:pr-36">
//           We always want to hear from our frondsters! Complaints, compliments,
//           ideas, experiences and any of your stories - let us know. We love to
//           hear how people are using frond and learning new ways that we can make
//           frond even better!! All of your messages come straight through to our
//           founders, Nev and Mark, and they always insist on responding
//           personally!!
//         </p>
//       </div>
//       <Footer />
//     </div>
//   );
// };

// const mapToStateProps = state => ({
//   isAuthenticated: state.auth.isAuthenticated,
// });
// export const Homepage = connect(mapToStateProps)(HomepageApp);

import React, { useContext, useEffect } from "react";
import Header from "./Header";
import Dreams from "./Dreams";
import Planing from "./Planing";
import Goals from "./Goals";
import Feedback from "./Feedback";
import JoinFrond from "./JoinFrond";

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Goals />
      <Dreams />
      <Planing />
      <Feedback />
      <JoinFrond />
    </>
  );
};

export default Homepage;
