import {
  Avatar,
  DatePicker,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "Context/AuthContext";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import calendar from "../../../Assets/create-frond/calendar.svg";
import { parseDate } from "@internationalized/date";
import up from "../../../Assets/create-frond/up.svg";

const TimelineAddEvent = ({ frondId, eventId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      date: null,
      title: "",
      desc: "",
      status: "",
    },
  });

  const [descLength, setDescLength] = useState(0);

  const getEventData = async () => {
    setIsDataLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/events/${eventId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.status === 200) {
        const dateArr = data?.response?.data?.event?.due_date.split("/");

        setValue("desc", data?.response?.data?.event?.description);
        setValue("title", data?.response?.data?.event?.title);

        if (data?.response?.data?.event?.status === "pending") {
          setValue("status", "Not Started");
        } else if (data?.response?.data?.event?.status === "in_progress") {
          setValue("status", "Not Started");
        } else if (data?.response?.data?.event?.status === "completed") {
          setValue("status", "Completed");
        }

        setValue(
          "date",
          parseDate(
            `${dateArr[2]}-${dateArr[1].padStart(2, "0")}-${dateArr[0].padStart(
              2,
              "0"
            )}`
          )
        );
        setDescLength(data?.response?.data?.task?.description.length || 0);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    if (eventId) {
      getEventData();
    }
  }, [eventId]);

  const { token } = useContext(AuthContext);
  const { setIsAddEvent, setIsTaskError, setCloseAddTaskModal } =
    useContext(PopUpContext);

  async function addEvent(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    let statusType = null;
    if (reqBody.status === "Not Started") {
      statusType = "pending";
    } else if (reqBody.status === "Completed") {
      statusType = "completed";
    }

    const payload = {
      title: reqBody.title,
      description: reqBody.desc,
      due_date: `${reqBody.date.day}/${reqBody.date.month}/${reqBody.date.year}`,
      type: "milestone",
    };

    if (statusType) {
      payload.status = statusType;
    }

    try {
      if (eventId) {
        const { data } = await axios.put(
          `https://frond-admin.code-minds.tech/api/events/${eventId}`,
          payload,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        toast("Updates saved", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      } else {
        const { data } = await axios.post(
          `https://frond-admin.code-minds.tech/api/projects/${frondId}/events`,
          payload,
          {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        toast("Event created successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      }

      setIsAddEvent(true);
      setCloseAddTaskModal(true);
      setTimeout(() => {
        setIsAddEvent(false);
        setCloseAddTaskModal(false);
      }, 1000);
      reset();
    } catch (error) {
      toast(error.response?.data?.message || "An error occurred", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }

    setIsDisabled(false);
    setIsLoading(false);
  }

  useEffect(() => {
    const hasErrors =
      errors.date?.message || errors.title?.message || errors.desc?.message;

    if (hasErrors) {
      setIsTaskError(true);
      setTimeout(() => {
        setIsTaskError(false);
      }, 1000);
    } else {
      setIsTaskError(true);
      setTimeout(() => {
        setIsTaskError(false);
      }, 1000);
    }
  }, [errors.date?.message, errors.title?.message, errors.desc?.message]);

  const dateValue = watch("date");
  const statusValue = watch("status");

  const statusList = ["Not Started", "Completed"];

  useEffect(() => {
    if (descLength > 0) {
      setIsTaskError(true);
    } else {
      setIsTaskError(true);
    }
  }, [descLength]);

  return (
    <>
      <form onSubmit={handleSubmit(addEvent)}>
        <div
          className={`relative about-edit-img sm:min-h-[295px] sm:max-h-[calc(100%+120px)] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%] mb-[50px]`}
        >
          <div className="content flex flex-col gap-5 items-center">
            <div className="date-wrapper relative w-full">
              <div
                className={`absolute left-[45px] ${
                  errors.date?.message
                    ? "top-0 translate-y-[33%]"
                    : "top-[50%] translate-y-[-50%]"
                } text-cardGrayColor phone:text-base text-[10px] font-normal ${
                  dateValue ? "hidden" : "block"
                }`}
              >
                Due Date
              </div>
              <Controller
                name="date"
                control={control}
                rules={{
                  required: "Date is required",
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    variant="bordered"
                    selectorIcon={
                      <Image src={calendar} className="w-[18px] h-[18px]" />
                    }
                    isInvalid={!!errors.date?.message}
                    errorMessage={errors.date?.message}
                    classNames={{
                      selectorButton:
                        "order-first min-w-[18px] max-h-[18px] h-[18px] me-0",
                    }}
                    dateInputClassNames={{
                      base: `mt-0 ${
                        errors.date?.message ? "h-[68px]" : "h-[39px]"
                      }`,
                      inputWrapper: `rounded-[8px] border-[0.86px] p-0 border-solid ${
                        errors.date?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      }`,
                      innerWrapper: "px-[13px] h-full gap-x-[2px] no-action",
                      segment: `date-picker-frond phone:text-base text-[10px] font-normal ${
                        dateValue ? "block" : "hidden"
                      }`,
                    }}
                  />
                )}
              />
            </div>

            <Controller
              name="title"
              control={control}
              rules={{
                required: "Title is required",
                minLength: {
                  value: 3,
                  message: "Minimum number of characters is 3",
                },
                maxLength: {
                  value: 100,
                  message: "Maximum number of characters is 100",
                },
                validate: {
                  noTrailingSpace: (value) =>
                    !/\s$/.test(value) || "Title shouldn't end with a space",
                },
                pattern: {
                  value:
                    // /^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+[\sa-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+$/gi,
                    /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(?!.*\s{2})[a-zA-Z0-9\-\/\\\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                  message:
                    "Title may contain characters, numbers and special characters",
                },
              }}
              render={({ field }) => (
                <Input
                  onKeyDown={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                  {...field}
                  type="text"
                  variant="bordered"
                  placeholder="*Title"
                  isInvalid={errors.title?.message ? true : false}
                  errorMessage={errors.title?.message}
                  classNames={{
                    base: `input-noMargin ${
                      errors.title?.message ? "h-[68px]" : "h-[39px]"
                    }`,
                    label:
                      "text-textColor font-normal phone:text-sm text-[10px]",
                    input: "border-none px-[18px] phone:text-base text-[10px]",
                    mainWrapper: `${
                      errors.title?.message ? "h-[68px]" : "h-[39px]"
                    }`,
                    innerWrapper: "h-[39px]",
                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                      errors.title?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] h-[39px]`,
                  }}
                />
              )}
            />

            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  className="w-[100%]"
                  {...field}
                  variant="bordered"
                  labelPlacement="outside"
                  selectedKeys={field.value ? [field.value] : []}
                  placeholder="Select Status"
                  classNames={{
                    base: `input-noMargin ${
                      errors.status?.message ? "h-[68px]" : "h-[39px]"
                    }`,
                    label: "text-textColor font-normal text-sm",
                    mainWrapper: "h-[42px]",
                    innerWrapper: "h-[42px] px-[18px]",
                    trigger: `p-0 h-[42px] border-[0.86px] border-solid ${
                      errors.status?.message
                        ? "invalid-text"
                        : "border-[#E5E4EB]"
                    } rounded-[8px] flex items-center justify-between`,
                    value: `${
                      statusValue ? "text-textColor" : "text-cardGrayColor"
                    } phone:text-base text-[10px] font-normal`,
                    selectorIcon: "w-[18px] h-[18px]",
                  }}
                  selectorIcon={<img src={up} />}
                  isInvalid={errors.status?.message ? true : false}
                  errorMessage={errors.status?.message}
                >
                  {statusList.map((item) => (
                    <SelectItem key={item}>{item}</SelectItem>
                  ))}
                </Select>
              )}
            />

            <div className="w-full mb-5">
              <Controller
                name="desc"
                control={control}
                rules={{
                  required: "Description content is required",
                  minLength: {
                    value: 2,
                    message: "Minimum number of characters is 2",
                  },
                  maxLength: {
                    value: 255,
                    message: "Maximum number of characters is 255",
                  },
                  pattern: {
                    value:
                      /^[a-zA-Z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\/\\]+(\s[A-Za-z0-9\-\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=\'\"\/\\]+)*$/gi,
                    message:
                      "Milestone description may contain characters, numbers or special characters and shouldn't end with space",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    onInput={(e) => setDescLength(e.target.value.length)}
                    maxLength={255}
                    type="text"
                    variant="bordered"
                    placeholder="*Add milestone description..."
                    classNames={{
                      base: "input-noMargin",
                      label:
                        "text-textColor font-normal phone:text-sm text-[10px]",
                      input:
                        "border-none px-[18px] min-h-[102px] phone:text-base text-[10px] overflow-y-auto",
                      mainWrapper: "h-[102px]",
                      innerWrapper: "h-[102px] overflow-y-auto",
                      inputWrapper: `border-[0.86px] p-0 border-solid ${
                        errors.desc?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      } rounded-[8px] h-[102px] overflow-y-auto`,
                    }}
                    isInvalid={errors.desc?.message ? true : false}
                    errorMessage={errors.desc?.message}
                  />
                )}
              />
              {!errors.desc?.message && 255 - descLength > 0 ? (
                <p className="text-[.75rem] text-mainBlue mt-1">
                  {255 - descLength} remaining characters
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <ModalFooter className="shadow-modalShadow">
          <button
            disabled={isDisabled}
            type="submit"
            className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
          >
            {isLoading ? (
              <span className="flex justify-center items-center text-white">
                <i className="fa-solid fa-spinner fa-spin"></i>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </ModalFooter>
      </form>
    </>
  );
};

export default TimelineAddEvent;
