import { Image, Input } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import magnifier from "../../Assets/filter/magnifier.svg";
import filterSearch from "../../Assets/filter/filter-search.svg";
import sort from "../../Assets/filter/sort.svg";
import upArrow from "../../Assets/filter/up.svg";
import deleteIcon from "../../Assets/filter/delete-folder.svg";
import archiveIcon from "../../Assets/filter/archive.svg";
import square from "../../Assets/filter/square.svg";
import row from "../../Assets/filter/row.svg";
import activeSquare from "../../Assets/filter/activeSquare.svg";
import activeRow from "../../Assets/filter/activeRow.svg";
import { SearchFrondContext } from "Context/SearchFrondContext";
import { ArchiveContext } from "Context/ArchiveContext";
import { DeleteProjectsContext } from "Context/DeleteProjectsContext";
import ArchiveModal from "components/MyFrondsFuctions/ArchiveModal";
import DeleteModal from "components/MyFrondsFuctions/DeleteModal";
import { ViewProjectsContext } from "Context/ViewProjectsContext";
import SortModal from "components/FrondCard/SortModal";
import FilterModal from "components/FrondCard/FilterModal";

const ProjectControl = ({ actions, page }) => {
  const {
    myFrondsView,
    setMyFrondsView,
    sharedFrondsView,
    setSharedFrondsView,
    archiveFrondsView,
    setArchiveFrondsView,
    pageType,
  } = useContext(ViewProjectsContext);

  const [layout, setLayout] = useState("");

  useEffect(() => {
    if (pageType === "my-fronds") {
      setLayout(myFrondsView);
    } else if (pageType === "shared-fronds") {
      setLayout(sharedFrondsView);
    } else if (pageType === "archive-fronds") {
      setLayout(archiveFrondsView);
    }
  }, [pageType, myFrondsView, sharedFrondsView]);

  const { setSearchValue } = useContext(SearchFrondContext);

  const handleGridClick = () => {
    const newLayout = "grid";
    setLayout(newLayout);

    if (pageType === "my-fronds") {
      setMyFrondsView(newLayout);
    } else if (pageType === "shared-fronds") {
      setSharedFrondsView(newLayout);
    } else if (pageType === "archive-fronds") {
      setArchiveFrondsView(newLayout);
    }
  };

  const handleListClick = () => {
    const newLayout = "list";
    setLayout(newLayout);

    if (pageType === "my-fronds") {
      setMyFrondsView(newLayout);
    } else if (pageType === "shared-fronds") {
      setSharedFrondsView(newLayout);
    } else if (pageType === "archive-fronds") {
      setArchiveFrondsView(newLayout);
    }
  };

  const {
    openArchiveCheckbox,
    setOpenArchiveCheckbox,
    archiveProjects,
    setArchiveProjects,
  } = useContext(ArchiveContext);
  const {
    openDeleteCheckbox,
    setOpenDeleteCheckbox,
    deleteProjects,
    setDeleteProjects,
  } = useContext(DeleteProjectsContext);

  useEffect(() => {}, [openArchiveCheckbox]);
  useEffect(() => {}, [openDeleteCheckbox]);

  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // Sort

  const [openSortModal, setOpenSortModal] = useState(false);

  // Filter

  const [openFilterModal, setOpenFilterModal] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-5 mb-5">
        <div className="w-full flex justify-between items-center 2xl:gap-10 gap-3">
          <div className="search flex items-center sm:justify-start justify-between sm:w-auto w-full xl:gap-5 gap-3">
            <Input
              onInput={(e) => {
                setSearchValue(e.target.value);
              }}
              type="text"
              placeholder="Search projects..."
              classNames={{
                base: "2xl:w-[284px] sm:w-[260px] phone:w-[151px] w-[93px]",
                input:
                  "border-none p-0 sm:text-base phone:text-[12px] text-[8px] sm:h-auto phone:h-[30px] h-[20px]",
                innerWrapper: "sm:h-auto h-fit",
                inputWrapper:
                  "phone:px-15px px-[6px] sm:py-2.5 py-0 bg-white rounded-[20px] in-wrap sm:h-auto h-fit min-h-fit",
                mainWrapper:
                  "bg-white rounded-[20px] border-2 border-solid border-grayBorder no-focus ",
              }}
              startContent={
                <Image
                  src={magnifier}
                  alt="magnifier"
                  className="sm:min-w-[18px] sm:min-h-[18px] sm:max-w-[18px] sm:max-h-[18px] phone:min-w-[14px] phone:min-h-[14px] phone:max-w-[14px] phone:max-h-[14px] min-w-[8px] min-h-[8px] max-w-[8px] max-h-[8px]"
                />
              }
              className="search-projects"
            />
            <div className="flex items-center gap-3">
              <button
                onClick={() => {
                  setOpenFilterModal(true);
                }}
                className="justify-between items-center sm:gap-10 phone:gap-2.5 gap-[5px] border-b-1 border-solid border-mainBlue no-focus no-visible lg:flex sm:hidden flex"
              >
                <div className="icon-title flex items-center gap-2 mb-0.5">
                  <Image
                    src={filterSearch}
                    alt="Filter"
                    className="sm:min-w-[18px] sm:min-h-[18px] phone:max-w-[14px] phone:max-h-[14px] max-w-[8px] max-h-[8px]"
                  />
                  <span className="text-mainBlue font-normal sm:text-base phone:text-[12px] text-[8px]">
                    Filter
                  </span>
                </div>
                <Image
                  className="mb-0.5 phone:min-w-[14px] phone:min-h-[14px] max-w-[8px] max-h-[8px]"
                  src={upArrow}
                  alt="^"
                />
              </button>
              <button
                onClick={() => {
                  setOpenSortModal(true);
                }}
                className="justify-between items-center sm:gap-10 phone:gap-2.5 gap-[5px] border-b-1 border-solid border-mainBlue no-focus no-visible lg:flex sm:hidden flex"
              >
                <div className="icon-title flex items-center gap-2 mb-0.5">
                  <Image
                    src={sort}
                    alt="Sort"
                    className="sm:min-w-[18px] sm:min-h-[18px] phone:max-w-[14px] phone:max-h-[14px] max-w-[8px] max-h-[8px]"
                  />
                  <span className="text-mainBlue font-normal sm:text-base phone:text-[12px] text-[8px]">
                    Sort
                  </span>
                </div>
                <Image
                  className="mb-0.5 phone:min-w-[14px] phone:min-h-[14px] max-w-[8px] max-h-[8px]"
                  src={upArrow}
                  alt="^"
                />
              </button>
            </div>
          </div>
          <div className="options flex items-center gap-3">
            <div className="show sm:flex hidden items-center gap-2">
              <button
                type="button"
                onClick={handleGridClick}
                className={`w-[40px] h-[40px] flex justify-center items-center rounded-lg transition-all duration-300 relative ${
                  layout === "grid" ? "bg-lightWhite " : "bg-white "
                }`}
              >
                <img
                  src={square}
                  alt="Square"
                  className={`min-w-[20px] min-h-[20px] absolute left-2.5 top-2.5 transition-all duration-300 ${
                    layout === "grid" ? "opacity-0" : "opacity-100"
                  }`}
                />
                <img
                  src={activeSquare}
                  alt="Square"
                  className={`min-w-[20px] min-h-[20px] absolute left-2.5 top-2.5 transition-all duration-300 ${
                    layout === "grid" ? "opacity-100" : "opacity-0"
                  }`}
                />
              </button>
              <button
                type="button"
                onClick={handleListClick}
                className={`w-[40px] h-[40px] flex justify-center items-center rounded-lg transition-all duration-300 relative ${
                  layout === "list" ? "bg-lightWhite" : "bg-white"
                }`}
              >
                <img
                  src={row}
                  alt="Row"
                  className={`min-w-[20px] min-h-[20px] absolute left-2.5 top-2.5 transition-all duration-300 ${
                    layout === "list" ? "opacity-0" : "opacity-100"
                  }`}
                />
                <img
                  src={activeRow}
                  alt="Row"
                  className={`min-w-[20px] min-h-[20px] absolute left-2.5 top-2.5 transition-all duration-300 ${
                    layout === "list" ? "opacity-100" : "opacity-0"
                  }`}
                />
              </button>
            </div>
            {actions && (
              <div className="actions gap-3 items-center xl:flex hidden">
                {openArchiveCheckbox ? (
                  <>
                    <button
                      onClick={() => {
                        if (archiveProjects.length > 0) {
                          setOpenArchiveModal(true);
                          setOpenArchiveCheckbox(false);
                        }
                      }}
                      type="button"
                      className="leading-[18.4px] text-white bg-darkBlue py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                    >
                      Archive
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setOpenArchiveCheckbox(false);
                        setArchiveProjects([]);
                      }}
                      className="gradiantBorder smallBorder no-focus py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                    >
                      <span className="leading-[18.4px] text-transparent bg-mainGradiant bg-clip-text">
                        Cancel
                      </span>
                    </button>
                  </>
                ) : (
                  !openDeleteCheckbox &&
                  actions.map((item, idx) => (
                    <button
                      key={idx}
                      onClick={() => {
                        if (item === "Archive") {
                          setOpenArchiveCheckbox(true);
                        } else if (item === "Delete") {
                          setOpenDeleteCheckbox(true);
                        }
                      }}
                      className={`relative flex justify-center items-center gap-3 rounded-4xl py-[11px] px-5 gradiantBorder smallBorder no-focus`}
                    >
                      <img
                        src={
                          item === "Archive"
                            ? archiveIcon
                            : item === "Delete"
                            ? deleteIcon
                            : null
                        }
                        alt={item}
                        className="w-[18px] h-[18px]"
                      />
                      <span className="bg-clip-text text-transparent bg-mainGradiant text-base font-normal">
                        {item}
                      </span>
                    </button>
                  ))
                )}

                {openDeleteCheckbox && (
                  <>
                    <button
                      onClick={() => {
                        if (deleteProjects.length > 0) {
                          setOpenDeleteModal(true);
                          setOpenDeleteCheckbox(false);
                        }
                      }}
                      type="button"
                      className="leading-[18.4px] text-white bg-redTimeline py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setOpenDeleteCheckbox(false);
                        setDeleteProjects([]);
                      }}
                      className="gradiantBorder smallBorder no-focus py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                    >
                      <span className="leading-[18.4px] text-transparent bg-mainGradiant bg-clip-text">
                        Cancel
                      </span>
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {actions && (
          <div className="actions gap-3 lg:self-center items-center sm:justify-between xl:hidden flex justify-center">
            <div className="items-center gap-3 lg:hidden sm:flex hidden">
              <button
                onClick={() => {
                  setOpenFilterModal(true);
                }}
                className="flex justify-between items-center lg:gap-10 gap-[5px] border-b-1 border-solid border-mainBlue no-focus no-visible"
              >
                <div className="icon-title flex items-center gap-2 mb-0.5">
                  <Image
                    src={filterSearch}
                    alt="Filter"
                    className="min-w-[18px] min-h-[18px]"
                  />
                  <span className="text-mainBlue font-normal text-base">
                    Filter
                  </span>
                </div>
                <Image
                  className="mb-0.5 min-w-[14px] min-h-[14px]"
                  src={upArrow}
                  alt="^"
                />
              </button>
              <button
                onClick={() => {
                  setOpenSortModal(true);
                }}
                className="flex justify-between items-center lg:gap-10 gap-[5px] border-b-1 border-solid border-mainBlue no-focus no-visible"
              >
                <div className="icon-title flex items-center gap-2 mb-0.5">
                  <Image
                    src={sort}
                    alt="Sort"
                    className="min-w-[18px] min-h-[18px]"
                  />
                  <span className="text-mainBlue font-normal text-base">
                    Sort
                  </span>
                </div>
                <Image
                  className="mb-0.5 min-w-[14px] min-h-[14px]"
                  src={upArrow}
                  alt="^"
                />
              </button>
            </div>
            <div className="flex items-center gap-2">
              {openArchiveCheckbox ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      if (archiveProjects.length > 0) {
                        setOpenArchiveModal(true);
                        setOpenArchiveCheckbox(false);
                      }
                    }}
                    className="leading-[18.4px] text-white bg-darkBlue py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                  >
                    Archive
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenArchiveCheckbox(false);
                      setArchiveProjects([]);
                    }}
                    className="gradiantBorder smallBorder no-focus py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                  >
                    <span className="leading-[18.4px] text-transparent bg-mainGradiant bg-clip-text">
                      Cancel
                    </span>
                  </button>
                </>
              ) : (
                !openDeleteCheckbox &&
                actions.map((item, idx) => (
                  <button
                    type="button"
                    onClick={() => {
                      if (item === "Archive") {
                        setOpenArchiveCheckbox(true);
                      } else if (item === "Delete") {
                        setOpenDeleteCheckbox(true);
                      }
                    }}
                    key={idx}
                    className={`relative transition-all duration-300 flex justify-center items-center lg:gap-3 gap-[5px] rounded-4xl lg:py-[11px] phone:py-2.5 py-[5px] lg:px-5 phone:px-[15px] px-2.5 gradiantBorder smallBorder no-focus`}
                  >
                    <img
                      src={
                        item === "Archive"
                          ? archiveIcon
                          : item === "Delete"
                          ? deleteIcon
                          : null
                      }
                      alt={item}
                      className="lg:w-[18px] lg:h-[18px] phone:w-[14px] phone:h-[14px] w-[10px] h-[10px]"
                    />
                    <span className="bg-clip-text text-transparent bg-mainGradiant lg:text-base phone:text-[14px] text-[10px] font-normal">
                      {item}
                    </span>
                  </button>
                ))
              )}

              {openDeleteCheckbox && (
                <>
                  <button
                    onClick={() => {
                      if (deleteProjects.length > 0) {
                        setOpenDeleteModal(true);
                        setOpenDeleteCheckbox(false);
                      }
                    }}
                    type="button"
                    className="leading-[18.4px] text-white bg-redTimeline py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenDeleteCheckbox(false);
                      setDeleteProjects([]);
                    }}
                    className="gradiantBorder smallBorder no-focus py-[11px] px-5 rounded-4xl w-[94px] h-[40px] flex justify-center items-center"
                  >
                    <span className="leading-[18.4px] text-transparent bg-mainGradiant bg-clip-text">
                      Cancel
                    </span>
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {openArchiveModal && (
        <ArchiveModal
          isOpen={openArchiveModal}
          onClose={() => {
            setOpenArchiveModal(false);
          }}
          projects={archiveProjects}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          isOpen={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          projects={deleteProjects}
        />
      )}
      {openSortModal && (
        <SortModal
          isOpen={openSortModal}
          onClose={() => {
            setOpenSortModal(false);
          }}
          page={page}
        />
      )}
      {openFilterModal && (
        <FilterModal
          isOpen={openFilterModal}
          onClose={() => {
            setOpenFilterModal(false);
          }}
          page={page}
        />
      )}
    </>
  );
};

export default ProjectControl;
