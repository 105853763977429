import React, { useState } from "react";
import photosIcon from "../../../Assets/frond-details/settings/picture.svg";
import notesIcon from "../../../Assets/frond-details/settings/files.svg";
import voiceIcon from "../../../Assets/frond-details/settings/graph.svg";
import linksIcon from "../../../Assets/frond-details/settings/fasten.svg";
import membersIcon from "../../../Assets/frond-details/settings/profile-2user.svg";
import timelineIcon from "../../../Assets/frond-details/settings/notepad-bookmark.svg";
import updatesIcon from "../../../Assets/frond-details/settings/notification-statu.svg";
import tasklistIcon from "../../../Assets/frond-details/settings/questionnaire-tablet.svg";
import { Image, Switch } from "@nextui-org/react";

export default function PrivacySettings() {
  const [photosSwitch, setPhotosSwitch] = useState(false);
  const [notesSwitch, setNotesSwitch] = useState(false);
  const [voiceSwitch, setVoiceSwitch] = useState(false);
  const [linksSwitch, setlinksSwitch] = useState(false);
  const [membersSwitch, setMembersSwitch] = useState(false);
  const [tasklistSwitch, setTasklistSwitch] = useState(false);
  const [timelineSwitch, setTimelineSwitch] = useState(false);
  const [updatesSwitch, setUpdatesSwitch] = useState(false);

  return (
    <section className="settings">
      <div className="title flex justify-between gap-2.5 items-center mb-[15px]">
        <h3 className="text-textColor text-lg font-bold leading-[20.7px]">
          Privacy Settings
        </h3>
        <h4 className="font-bold text-base text-cardGrayColor leading-[18.4px]">
          Visibility
        </h4>
      </div>

      <div className="content flex flex-col">
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image
              src={photosIcon}
              alt="Photos"
              className="w-[24px] h-[24px]"
            />
            <h6 className="text-textColor font-bold leading-[22px]">Photos</h6>
          </div>
          <div
            className={`${
              photosSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setPhotosSwitch(!photosSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                photosSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                photosSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {photosSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image src={notesIcon} alt="Notes" className="w-[24px] h-[24px]" />
            <h6 className="text-textColor font-bold leading-[22px]">Notes</h6>
          </div>
          <div
            className={`${
              notesSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setNotesSwitch(!notesSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                notesSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                notesSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {notesSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image
              src={voiceIcon}
              alt="Voice Memo"
              className="w-[24px] h-[24px]"
            />
            <h6 className="text-textColor font-bold leading-[22px]">
              Voice Memos
            </h6>
          </div>
          <div
            className={`${
              voiceSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setVoiceSwitch(!voiceSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                voiceSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                voiceSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {voiceSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image src={linksIcon} alt="Links" className="w-[24px] h-[24px]" />
            <h6 className="text-textColor font-bold leading-[22px]">Links</h6>
          </div>
          <div
            className={`${
              linksSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setlinksSwitch(!linksSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                linksSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                linksSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {linksSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image
              src={membersIcon}
              alt="Members"
              className="w-[24px] h-[24px]"
            />
            <h6 className="text-textColor font-bold leading-[22px]">Members</h6>
          </div>
          <div
            className={`${
              membersSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setMembersSwitch(!membersSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                membersSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                membersSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {membersSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image
              src={tasklistIcon}
              alt="Tasklist"
              className="w-[24px] h-[24px]"
            />
            <h6 className="text-textColor font-bold leading-[22px]">
              Tasklist
            </h6>
          </div>
          <div
            className={`${
              tasklistSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setTasklistSwitch(!tasklistSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                tasklistSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                tasklistSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {tasklistSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center pb-4 mb-4 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-2.5">
            <Image
              src={timelineIcon}
              alt="Timeline"
              className="w-[24px] h-[24px]"
            />
            <h6 className="text-textColor font-bold leading-[22px]">
              Timeline
            </h6>
          </div>
          <div
            className={`${
              timelineSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setTimelineSwitch(!timelineSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                timelineSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                timelineSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {timelineSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
        <div className="item flex justify-between gap-2.5 items-center">
          <div className="flex items-center gap-2.5">
            <Image
              src={updatesIcon}
              alt="Updates"
              className="w-[24px] h-[24px]"
            />
            <h6 className="text-textColor font-bold leading-[22px]">Updates</h6>
          </div>
          <div
            className={`${
              updatesSwitch
                ? "bg-mainGradiant justify-end"
                : "bg-darkBlue justify-start"
            } p-[5px] cursor-pointer relative transition-background duration-300 ease-linear w-[90px] h-[32px] rounded-full flex gap-2.5 items-center`}
            onClick={() => {
              setUpdatesSwitch(!updatesSwitch);
            }}
          >
            <div
              className={`absolute top-[50%] translate-y-[-50%] w-[22px] h-[22px] bg-white rounded-full transition-left duration-300 ease-linear ${
                updatesSwitch ? "left-[calc(100%-28px)]" : "left-[5px]"
              }`}
            />
            <span
              className={`absolute top-[50%] translate-y-[-50%] text-white font-bold text-sm transition-left transition-right duration-300 ease-linear ${
                updatesSwitch ? "left-2.5 right-auto" : "left-auto right-2.5"
              }`}
            >
              {updatesSwitch ? "Public" : "Private"}
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}
