import { createContext, useState } from "react";

export let ArchiveContext = createContext();

export default function ArchiveContextProvider({ children }) {
    const [openArchiveCheckbox, setOpenArchiveCheckbox] = useState(false);
    const [archiveProjects, setArchiveProjects] = useState([]);

    return (
        <ArchiveContext.Provider value={{ openArchiveCheckbox, setOpenArchiveCheckbox, archiveProjects, setArchiveProjects }}>
            {children}
        </ArchiveContext.Provider>
    );
}
