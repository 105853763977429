import { createContext, useState } from "react";

export let TasksBoardContext = createContext();

export default function TasksBoardContextProvider({ children }) {
    const [tasksPos, setTasksPos] = useState([]);

    return (
        <TasksBoardContext.Provider value={{ tasksPos, setTasksPos }}>
            {children}
        </TasksBoardContext.Provider>
    );
}