import React, { useEffect } from "react";
import headerImg from "../../Assets/about-frond/headerImg.svg";
import magicImg from "../../Assets/about-frond/magic.svg";
import visionImg from "../../Assets/about-frond/vision.svg";
import { Image } from "@nextui-org/react";

const AboutFrond = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="about-frond relative">
      <div className="intro bg-mainGradiant py-16">
        <div className="container xl:px-20 md:px-3 px-6 mx-auto">
          <div className="flex md:justify-between justify-center gap-x-7 gap-y-9 items-center md:flex-nowrap flex-wrap">
            <div className="text-white font-bold">
              <h2 className="text-[58px] font-bold leading-[66.69px] mb-8">
                About frond
              </h2>
              <p className="text-2xl font-bold leading-[29px] md:w-[70%]">
                Hello and welcome to frond! We’re really happy that you’ve heard
                about frond, and that you will find joy in using it, as much as
                we’ve enjoyed building it over the years.
              </p>
            </div>
            <Image
              src={headerImg}
              alt="About Frond"
              className="md:min-w-[321px] sm:min-w-[100%] min-h-[265px]"
            />
          </div>
        </div>
      </div>
      <div className="about-content py-14 relative">
        <div className="absolute left-0 top-0 sm:w-[367px] w-[80%] h-[362px] z-10 bg-aboutBuble"></div>
        <div className="absolute left-0 bottom-[0] sm:w-[344px] w-[80%] h-[384.17px] z-10 bg-aboutOrangeBuble"></div>
        <div className="absolute right-0 bottom-[15%] sm:w-[260px] w-[50%] h-[348px] z-10 bg-aboutPrint"></div>
        <div className="container xl:px-20 px-6 mx-auto">
          <div className="who-we-are text-center mb-10 relative z-20">
            <h3 className="text-38px font-bold leading-[43.7px] bg-clip-text text-transparent bg-mainGradiant mb-6">
              Who are we?
            </h3>
            <p className="text-lg text-black opacity-75 leading-[25.2px] font-normal mx-auto lg:w-[70%]">
              Nev and Mark have a background in technology, and project
              management, therefore it made sense to work on a personal planning
              app that combined both these things together.
            </p>
            <p className="text-lg text-black opacity-75 leading-[25.2px] font-normal mx-auto lg:w-[70%]">
              In their spare time, Nev enjoys travelling, painting and
              volunteering for charity work. Whereas Mark, enjoys playing a game
              of 5 aside, and drumming, and is at the recording studio in London
              every week. They both love dogs and share two, Erda and Mia, who
              have been key founding members of the growing frond team.
            </p>
          </div>
          <div className="magic flex items-center justify-between md:flex-nowrap flex-wrap gap-2.5 mb-10 lg:px-[30px] relative z-20">
            <div className="info">
              <h3 className="text-38px font-bold leading-[43.7px] bg-clip-text text-transparent bg-mainGradiant mb-6">
                When the magic began
              </h3>
              <p className="text-lg text-black opacity-75 leading-[25.2px] font-normal xl:w-[600px]">
                Frond started off as a late-night exchange of i-messages, way
                before WhatsApp was even invented, from an idea Nev had about
                renovating her flat in London. After sharing countless images
                and ideas, she called Mark and said, surely there must be a
                better way to do this? And that was the start of frond!
              </p>
            </div>
            <Image
              src={magicImg}
              alt="Magic"
              className="lg:min-w-[421px] md:min-w-[300px] sm:min-w-[421px] min-w-[100%] h-[262px]"
            />
          </div>
          <div className="vision bg-white rounded-4xl py-[30px] sm:px-[60px] px-[30px] border-2 border-solid border-cardColor flex items-center lg:justify-between justify-center lg:flex-nowrap flex-wrap xl:gap-[90px] gap-10 mb-10 relative z-20">
            <Image
              src={visionImg}
              alt="Vision"
              className="sm:min-w-[383px] min-w-[100%] sm:h-[392px]"
            />
            <div className="info">
              <h3 className="text-[28px] font-bold leading-[39.2px] w-fit bg-clip-text text-transparent bg-mainGradiant mb-6 relative after:absolute after:left-0 after:right-0 after:bottom-[-7px] after:h-[2px] after:bg-mainGradiant">
                Our Vision
              </h3>
              <p className="text-lg text-black opacity-80 leading-[23.4px] font-normal">
                Technology has changed our lives completely, most of us now
                depend on it, to make the decisions that shape our lives, from
                booking flights and hotels, to responding to work emails, or
                consuming endless content on social media to make important
                decisions.
              </p>
              <p className="text-lg text-black opacity-80 leading-[23.4px] font-normal">
                We believe that frond, will make our lives that extra bit more
                simple, without the hassle of having to look through old
                messages, post it notes or having to remember things in our
                headwhen you need to plan the important events in our lives –
                from holidays, weddings, honeymoons to house renovations, the
                list is endless. There is so much that can be planned using
                frond. Think of it as your personal planning assistant, without
                having to hire anyone.
              </p>
              <p className="text-lg text-black opacity-80 leading-[23.4px] font-normal">
                It’s all about planning, sharing and living the experience,
                which we are confident frond will help you achieve.
              </p>
            </div>
          </div>
          <div className="team relative z-20">
            <h3 className="font-bold text-38px leading-[43.7px] bg-clip-text text-transparent bg-mainGradiant text-center mb-10">
              Meet the team
            </h3>
            <div className="team-box flex flex-col gap-10 items-center">
              <div className="items flex justify-center items-center lg:gap-x-10 gap-x-5 gap-y-10 sm:flex-nowrap flex-wrap">
                <div className="item flex flex-col justify-center items-center text-center">
                  <div className="bg-[#D9D9D9] w-[90px] h-[90px] rounded-full mb-[15px]"></div>
                  <h5 className="text-2xl font-bold leading-[27.6px] mb-[10px]">
                    Nev Shawer
                  </h5>
                  <p className="text-lg opacity-75 leading-[20.7px]">
                    Co-founder the frond app
                  </p>
                </div>
                <div className="item flex flex-col justify-center items-center text-center">
                  <div className="bg-[#D9D9D9] w-[90px] h-[90px] rounded-full mb-[15px]"></div>
                  <h5 className="text-2xl font-bold leading-[27.6px] mb-[10px]">
                    Mark Ferrari
                  </h5>
                  <p className="text-lg opacity-75 leading-[20.7px]">
                    Co-founder the frond app
                  </p>
                </div>
              </div>
              <div className="items flex justify-center items-center lg:gap-x-10 gap-x-5 gap-y-10 md:flex-nowrap flex-wrap">
                <div className="item flex flex-col justify-center items-center text-center">
                  <div className="bg-[#D9D9D9] w-[90px] h-[90px] rounded-full mb-[15px]"></div>
                  <h5 className="text-2xl font-bold leading-[27.6px] mb-[10px]">
                    Mohamed El Shamy
                  </h5>
                  <p className="text-lg opacity-75 leading-[20.7px]">
                    Senior Developer (Full Stack)
                  </p>
                </div>
                <div className="item flex flex-col justify-center items-center text-center">
                  <div className="bg-[#D9D9D9] w-[90px] h-[90px] rounded-full mb-[15px]"></div>
                  <h5 className="text-2xl font-bold leading-[27.6px] mb-[10px]">
                    Mazen Ahmed
                  </h5>
                  <p className="text-lg opacity-75 leading-[20.7px]">
                    Lead Front End Developer
                  </p>
                </div>
                <div className="item flex flex-col justify-center items-center text-center">
                  <div className="bg-[#D9D9D9] w-[90px] h-[90px] rounded-full mb-[15px]"></div>
                  <h5 className="text-2xl font-bold leading-[27.6px] mb-[10px]">
                    Ahmed El Adawy
                  </h5>
                  <p className="text-lg opacity-75 leading-[20.7px]">
                    Head of Design (UI and UX)
                  </p>
                </div>
              </div>
              <div className="items flex justify-center items-center">
                <div className="item flex flex-col justify-center items-center text-center">
                  <div className="bg-[#D9D9D9] w-[90px] h-[90px] rounded-full mb-[15px]"></div>
                  <h5 className="text-2xl font-bold leading-[27.6px] mb-[10px]">
                    Erda & Mia
                  </h5>
                  <p className="text-lg opacity-75 leading-[20.7px]">
                    Resident PAs (who provide valuable insights when given
                    treats)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutFrond;
