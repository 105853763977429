import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import { SortContext } from "Context/SortContext";
import React, { useContext, useEffect, useState } from "react";
import "react-range-slider-input/dist/style.css";

const SortModal = ({ isOpen, onClose, page }) => {
  const {
    frondSortTypes,
    setFrondSortTypes,
    sharedSortTypes,
    setSharedSortTypes,
    archiveSortTypes,
    setArchiveSortTypes,
  } = useContext(SortContext);
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] sort-modal",
        footer: "justify-center items-center pb-2",
        body: "border-b-1 border-solid border-grayBorder p-0",
        closeButton: "hidden",
        base: "rounded-[10px]",
      }}
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <ModalBody className="overflow-auto">
              <div className="sort sm:w-auto w-full">
                <RadioGroup
                  onValueChange={(e) => {
                    if (page === "my-fronds") {
                      setFrondSortTypes(e);
                    } else if (page === "archive-fronds") {
                      setArchiveSortTypes(e);
                    } else if (page === "shared-fronds") {
                      setSharedSortTypes(e);
                    }
                  }}
                  classNames={{
                    label:
                      "text-blueTimeline text-center border-b-1 border-solid border-grayBorder font-bold leading-[30px] text-base pb-2.5",
                    base: "gap-0 py-[15px]",
                    wrapper: "gap-0 px-5",
                  }}
                  label="Sort by"
                  value={
                    page === "my-fronds"
                      ? frondSortTypes
                      : page === "archive-fronds"
                      ? archiveSortTypes
                      : page === "shared-fronds"
                      ? sharedSortTypes
                      : ""
                  }
                >
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="Name (A-Z)"
                  >
                    Name (A-Z)
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="Name (Z-A)"
                  >
                    Name (Z-A)
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="Start Date"
                  >
                    Start Date
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="End Date"
                  >
                    End Date
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="Importance (High - Low)"
                  >
                    Importance (High - Low)
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter sort-fronds",
                    }}
                    value="Importance (Low - High)"
                  >
                    Importance (Low - High)
                  </Radio>
                  <Radio
                    classNames={{
                      control: "bg-mainGradiant w-3 h-3",
                      label: "text-cardGrayColor leading-[16.1px] text-sm",
                      base: "px-0 min-w-full py-2.5 m-0 radio-filter sort-fronds",
                    }}
                    value="Last Updated"
                  >
                    Last Updated
                  </Radio>
                </RadioGroup>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default SortModal;
