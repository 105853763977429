import React, { useContext, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";

import closeIcon from "../../Assets/popup/cross-square.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";
import { useNavigate } from "react-router-dom";

export default function LeaveProjectModal({
  isOpen,
  onClose,
  stopClosePropagation,
  frondId,
  flag,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleClose = (e) => {
    onClose();
  };

  const authToken = localStorage.getItem("token");

  const { setIsShareProject, setIsEditTitle } = useContext(PopUpContext);

  const navigate = useNavigate();

  const leaveProject = async () => {
    setIsLoading(true);
    setIsDisabled(true);
    if (!frondId) return;
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/leave`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      onClose();

      setIsShareProject(true);
      setTimeout(() => {
        setIsShareProject(false);
      }, 1000);

      toast("Leave project done", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
      if (flag) {
        navigate("/shared-fronds");
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
    setIsDisabled(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        footer: "justify-center items-center py-5",
        body: "border-b-1 border-solid border-grayBorder pt-2 pb-4",
        closeButton: "hidden",
        base: "p-5 confirm-modal",
      }}
      hideCloseButton={true}
      onClick={(e) => e.stopPropagation()}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex items-center justify-end">
              <button onClick={handleClose} className="" aria-label="Close">
                <img src={closeIcon} alt="Close" className="w-6 h-6" />
              </button>
            </ModalHeader>
            <ModalBody>
              <p className="text-cardGrayColor font-bold text-center leading-[18.4px] sm:w-[256px] mx-auto">
                Are you sure you want to leave this project?
              </p>
            </ModalBody>
            <ModalFooter className="flex items-center justify-center gap-2.5">
              <button
                onClick={() => {
                  handleClose();
                }}
                className="text-base font-bold leading-[18.4px] flex justify-center items-center rounded-[27px] py-[11px] px-[25px] w-[100px] h-[39px] gradiantBorder no-focus smallBorder"
              >
                <span className="text-transparent bg-mainGradiant bg-clip-text font-normal text-base">
                  Cancel
                </span>
              </button>
              <button
                disabled={isDisabled}
                onClick={() => {
                  leaveProject();
                }}
                className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[100px] h-[39px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center text-white">
                    <i className="fa-solid fa-spinner fa-spin"></i>
                  </span>
                ) : (
                  "Leave"
                )}
              </button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
