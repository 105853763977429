import React, { useRef, useState } from "react";
import dealImg from "../../Assets/discover/sliders/deal-img.jpg";
import starIcon from "../../Assets/discover/sliders/star.svg";
import { Image } from "@nextui-org/react";

export default function Hotels() {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const preventDrag = (e) => {
    e.preventDefault();
  };

  const handleMouseDown = (e) => {
    const slider = sliderRef.current;
    setIsDragging(true);
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const slider = sliderRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  return (
    <section className="best-deals ms-5 mt-5">
      <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2.5">
        Hotels
      </h3>
      <div className="overflow-hidden h-[227px]">
        <div
          ref={sliderRef}
          className="flex gap-5 items-center overflow-x-auto pb-5 cursor-grab active:cursor-grabbing"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
        >
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[257px] max-w-[257px] h-[227px] bg-white p-2.5 rounded-[14px] border-2 border-solid border-grayBorder"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2" }}
              className="w-full h-[124px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="rating flex items-center gap-[5px]">
                <div className="stars flex items-center gap-0.5">
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                </div>
                <p className="text-cardGrayColor leading-[16.1px] text-xs ">
                  (212 Reviews)
                </p>
              </div>
              <p className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                99 - 120€
              </p>
            </div>
          </div>
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[257px] max-w-[257px] h-[227px] bg-white p-2.5 rounded-[14px] border-2 border-solid border-grayBorder"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2" }}
              className="w-full h-[124px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="rating flex items-center gap-[5px]">
                <div className="stars flex items-center gap-0.5">
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                </div>
                <p className="text-cardGrayColor leading-[16.1px] text-xs ">
                  (212 Reviews)
                </p>
              </div>
              <p className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                99 - 120€
              </p>
            </div>
          </div>
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[257px] max-w-[257px] h-[227px] bg-white p-2.5 rounded-[14px] border-2 border-solid border-grayBorder"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2" }}
              className="w-full h-[124px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="rating flex items-center gap-[5px]">
                <div className="stars flex items-center gap-0.5">
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                </div>
                <p className="text-cardGrayColor leading-[16.1px] text-xs ">
                  (212 Reviews)
                </p>
              </div>
              <p className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                99 - 120€
              </p>
            </div>
          </div>
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[257px] max-w-[257px] h-[227px] bg-white p-2.5 rounded-[14px] border-2 border-solid border-grayBorder me-5"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2" }}
              className="w-full h-[124px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="rating flex items-center gap-[5px]">
                <div className="stars flex items-center gap-0.5">
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                </div>
                <p className="text-cardGrayColor leading-[16.1px] text-xs ">
                  (212 Reviews)
                </p>
              </div>
              <p className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                99 - 120€
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
