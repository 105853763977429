import React, { useEffect } from "react";
import CardsTemplate from "./CardsTemplate";

const Ventures = () => {
  const cardsImgs = require.context("../../Assets/template/ventures", true);
  const cardsImgsList = cardsImgs.keys().map((image) => cardsImgs(image));
  const cardOne = {
    title: "Turn your business ideas into reality with frond",
    content: [
      {
        space: true,
        info: "Plan like a pro",
        desc: "Organize your business ideas, track progress, and never miss a detail",
      },
      {
        space: true,
        info: "Collaborate with ease",
        desc: "Share ideas, assign tasks, plan your marketing strategy and keep everyone on the same page",
      },
      {
        info: "Mobile mastermind",
        desc: "Access your plans and manage your ventures from anywhere, anytime",
      },
    ],
    img: cardsImgsList[0],
  };
  const cardTwo = {
    title: "Beyond the dream vacation: How frond empowers businesses to thrive",
    content: [
      {
        space: true,
        info: "Streamline project management",
        desc: "Keep teams, clients, and contractors in sync with one central platform",
      },
      {
        space: true,
        info: "Boost efficiency",
        desc: "Manage tasks, deadlines, and updates for all project sizes, from design sprints to multi-million dollar initiatives",
      },
      {
        info: "Stay organized, no matter the scale",
        desc: "From small start-ups to global teams, frond keeps everyone on the same page",
      },
    ],
    img: cardsImgsList[1],
  };
  const cardThree = {
    title: "frond keeps everything organized in one place",
    content: [
      {
        space: true,
        info: "Ditch the digital clutter",
        desc: "No more digging through emails, texts, and notes. frond consolidates everything you need in one user-friendly app.",
      },
      {
        space: true,
        info: "Find anything fast",
        desc: "From initial meetings to key milestones, access all your project details instantly",
      },
      {
        info: "Never miss a beat",
        desc: "Keep track of tasks, contacts, and photos – all seamlessly organized for effortless project management",
      },
    ],
    img: cardsImgsList[2],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <CardsTemplate
      secName="ventures"
      mainTitle={"From Idea to Reality Plan Your Venture with frond"}
      cardOne={cardOne}
      cardTwo={cardTwo}
      cardThree={cardThree}
      subTitle={"Say goodbye to information overload!"}
    />
  );
};

export default Ventures;
