import React, { useEffect, useState } from "react";
import { Tabs, Tab, Image } from "@nextui-org/react";
import { Link, useLocation } from "react-router-dom";

// Icons for active and non-active states
const activeIcons = require.context("../../Assets/tabs/active", true);
const activeIconsList = activeIcons.keys().map((image) => activeIcons(image));
const nonActiveIcons = require.context("../../Assets/tabs/non-active", true);
const nonActiveIconsList = nonActiveIcons
  .keys()
  .map((image) => nonActiveIcons(image));

const ControlledTabs = () => {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);

  // Format pathname to handle trailing slashes and default paths
  const formatPathname = (path) =>
    path === "/" ? path : path.replace(/\/+$/, "");
  const formattedPathname = formatPathname(pathname);

  // Tabs data for each tab's title and link
  const tabsData = [
    {
      title: "My fronds",
      link: "/",
    },
    {
      title: "Create a frond",
      link: "/create-frond",
    },
    {
      title: "Shared fronds",
      link: "/shared-fronds",
    },
    {
      title: "Invitations",
      link: "/invitations",
    },
    {
      title: "Archive",
      link: "/archived-fronds",
    },
  ];

  useEffect(() => {
    setActiveLink(formatPathname(pathname));
  }, [pathname]);

  return (
    <div className="tabs bg-white w-full border-2 border-solid border-grayBorder no-focus rounded-4xl mb-2.5 relative">
      <Tabs
        selectedKey={activeLink}
        aria-label="Fronds Tabs"
        radius="full"
        classNames={{
          base: "w-full flex",
          tabList: "phone:p-2.5 p-1.5 w-full flex justify-between bg-white",
          tab: "flex w-fit items-center justify-center gap-2.5 phone:py-[24px] py-2.5 phone:px-5 px-2.5 rounded-[40px] transition-all main-tabs",
          tabContent: "phone:text-lg text-[12px] font-normal",
        }}
      >
        {tabsData.map((item, idx) => (
          <Tab
            key={item.link}
            title={
              <div className="flex items-center gap-2.5">
                <Image
                  src={
                    formattedPathname === item.link
                      ? activeIconsList[idx]
                      : nonActiveIconsList[idx]
                  }
                  className="phone:min-w-[24px] phone:min-h-[24px] phone:max-w-[24px] phone:max-h-[24px] min-w-[14px] min-h-[14px] max-w-[14px] max-h-[14px]"
                />
                <h5
                  className={`${
                    formattedPathname === item.link
                      ? "text-white font-bold"
                      : "text-cardGrayColor font-normal"
                  }`}
                >
                  {item.title}
                </h5>
              </div>
            }
            as={Link}
            to={item.link}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default ControlledTabs;
