import React, { useContext, useEffect, useState } from "react";
import linkImg from "../../../Assets/landing/feedbackCardImg.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import dots from "../../../Assets/scrapbook/dots-vertical.svg";
import { PopUpContext } from "Context/PopUpContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddNote from "./PopUp/AddNote";

const ScrapbookNotes = () => {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [editNoteModal, setEditNoteModal] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { frondId } = useParams();
  const authToken = localStorage.getItem("token");

  // Function to calculate time ago
  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  // Fetch notes data
  const getScrapbookNotes = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-notes/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getScrapbookNotes();
  }, [frondId]);

  const { isAddNote } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddNote) {
      getScrapbookNotes();
    }
  }, [isAddNote]);

  // Delete note
  const deleteRecord = async () => {
    if (!targetId) return;
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/scrapbook-notes/${frondId}/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      getScrapbookNotes();
      toast("Note deleted successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (itemKey === "delete") {
      deleteRecord();
      setItemKey(null);
    } else if (itemKey === "edit") {
      setEditNoteModal(true);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <>
      <section className="scrapbook-voice-notes">
        {frondData.length > 0 && (
          <div className="flex flex-col gap-[15px]">
            {frondData.map((item, idx) => (
              <div
                key={item.id}
                className={`flex justify-between w-full gap-2.5 ${
                  idx !== frondData.length - 1 &&
                  "pb-[15px] border-b-1 border-solid border-b-grayBorder"
                }`}
              >
                <div className="flex items-start gap-2.5">
                  <Image
                    src={item?.user?.img_url}
                    className="phone:min-w-[45px] phone:min-h-[45px] max-w-[30px] max-h-[30px] rounded-full"
                    alt="Link Image"
                  />
                  <div>
                    <h4 className="text-base text-textColor font-bold leading-[22px] mb-[3px]">
                      {item.user?.name || "-"}
                    </h4>
                    <p className="text-sm text-cardGrayColor mb-2.5">
                      {timeAgo(item.created_at) || "-"}
                    </p>
                    <p className="text-mainBlue leading-[22px] font-bold mb-[8px]">
                      {item.title || "-"}
                    </p>
                    <p className="text-textColor leading-[22px] break-all">
                      {item.note || "-"}
                    </p>
                  </div>
                </div>
                <Dropdown
                  classNames={{
                    content:
                      "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                  }}
                  placement="left-start"
                >
                  <DropdownTrigger>
                    <button
                      className="w-[24px] h-[24px]"
                      onClick={handleDotsClick}
                    >
                      <img
                        src={dots}
                        alt="Dots"
                        className="min-w-[24px] min-h-[24px]"
                      />
                    </button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Frond Actions"
                    onAction={(key) => openModal(key, item.id)}
                  >
                    <DropdownItem
                      textValue="edit note"
                      key="edit"
                      className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      key="delete"
                      className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                      color="danger"
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            ))}
          </div>
        )}
      </section>
      {editNoteModal && targetId && (
        <AddNote
          isOpen={editNoteModal}
          onClose={() => setEditNoteModal(false)}
          frondId={frondId}
          isModalOpen={true}
          noteId={targetId}
        />
      )}
    </>
  );
};

export default ScrapbookNotes;
