import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedLogin = (props) => {
  if (localStorage.getItem("token") !== null) {
    return <Navigate to={"/"}></Navigate>;
  } else {
    return props.children;
  }
};

export default ProtectedLogin;
