import { createContext, useState } from "react";

export let Error500Context = createContext();

export default function Error500ContextProvider({ children }) {
  const [isServerError, setIsServerError] = useState(false);

  return (
    <Error500Context.Provider value={{ isServerError, setIsServerError }}>
      {children}
    </Error500Context.Provider>
  );
}
