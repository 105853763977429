import React from "react";
import cakeImg from "../../Assets/landing/cake.jpg";

const Dreams = () => {
  return (
    <section className="dreams py-12">
      <div className="container xl:px-20 px-6 mx-auto">
        <div className="grid lg:grid-cols-2 rounded-4xl border-2 border-solid border-cardColor items-center justify-items-end overflow-hidden shadow-mainShadow">
          <div className="content ps-10 py-10 xl:py-0 lg:pe-0 pe-10">
            <h2 className="bg-clip-text text-transparent bg-mainGradiant font-bold sm:text-38px text-3xl mb-8 leading-HeadingHeight">
              Turn your dreams into reality with your all-in-one personal
              project management app
            </h2>
            <p className="font-normal text-lg leading-textHeight mb-4 text-textColor">
              <strong className="font-bold me-1">Plan anything:</strong>From
              epic vacations to home renovations or dream weddings and
              honeymoons, frond keeps your ideas organized and in the same
              place.
            </p>
            <p className="font-normal text-lg leading-textHeight mb-4 text-textColor">
              <strong className="font-bold me-1">Stay on track:</strong>Manage
              timelines, budgets, and collaborate seamlessly with others. Share
              your project with friends and family to keep everyone in the loop.
            </p>
            <p className="font-normal text-lg leading-textHeight text-textColor">
              <strong className="font-bold me-1">Make it happen:</strong>Frond
              empowers you to transform your plans from ideas into reality.
            </p>
          </div>
          <div className="dream-img lg:w-[397px] w-full xl:h-[492px] lg:h-[574px] h-[492px]">
            <img
              src={cakeImg}
              className="size-full lg:rounded-e-4xl lg:rounded-bl-none rounded-b-4xl"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dreams;
