import React, { useState } from "react";
import lightLogo from "../../Assets/landing/lightLogo.svg";
import { Image } from "@nextui-org/react";
import { Link } from "react-router-dom";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "components/TermsOfService/TermsOfService";

const MainFooter = () => {
  const socialIcons = require.context("../../Assets/landing/social", true);
  const socialIconsList = socialIcons.keys().map((image) => socialIcons(image));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const showPolicy = () => {
    setIsModalOpen(true);
  };

  const showTerms = () => {
    setIsTermsModalOpen(true);
  };

  const socialLinks = [
    "https://www.facebook.com/thefrondapp",
    "https://x.com/TheFrondApp",
    "https://www.instagram.com/thefrondapp",
    "https://www.linkedin.com/company/thefrondapp/?viewAsMember=true",
  ];
  return (
    <section className="footer pt-12 pb-6 bg-textColor">
      <div className="container xl:px-20 px-6 mx-auto">
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5">
          <div className="col flex flex-col gap-4 grow-1 sm:content-start sm:items-start items-center">
            <Link to={"/"}>
              <Image src={lightLogo} alt="Logo" />
            </Link>
            <h4 className="text-2xl font-bold bg-clip-text text-transparent bg-mainGradiant">
              Keep In Touch!
            </h4>
            <div className="social flex gap-2">
              {socialIconsList.map((icon, idx) => (
                <Link target="_blank" to={socialLinks[idx]} key={idx}>
                  <Image src={icon} alt="Social" />
                </Link>
              ))}
            </div>
            <Link
              to={"mailto:info@thefrondapp.com"}
              className="text-white w-fit relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-white"
            >
              info@thefrondapp.com
            </Link>
          </div>
          <div className="col flex flex-col gap-2 text-white text-lg sm:content-center sm:items-start items-center flex-wrap">
            <h4 className="bg-clip-text text-transparent bg-mainGradiant font-bold">
              Frond
            </h4>
            <Link to={"/"} className="opacity-80">
              Home
            </Link>
            <Link to={"/about-frond"} className="opacity-80">
              About frond
            </Link>
            <Link to={"/contact-us"} className="opacity-80">
              Contact Us
            </Link>
            <div>
              <button onClick={showTerms} className="opacity-80">
                Terms Of Service
              </button>
              <TermsOfService
                isOpen={isTermsModalOpen}
                onClose={() => setIsTermsModalOpen(false)}
              />
            </div>
            <div>
              <button onClick={showPolicy} className="opacity-80">
                Privacy Policy
              </button>
              <PrivacyPolicy
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
              />
            </div>
          </div>
          <div className="col flex flex-col gap-2 text-white text-lg lg:content-center sm:items-start items-center flex-wrap">
            <h4 className="bg-clip-text text-transparent bg-mainGradiant font-bold">
              Tour
            </h4>
            <Link to={"/home"} className="opacity-80">
              Home
            </Link>
            <Link to={"/ventures"} className="opacity-80">
              Ventures
            </Link>
            <Link to={"/events"} className="opacity-80">
              Events
            </Link>
            <Link to={"/escape"} className="opacity-80">
              Escapes
            </Link>
          </div>
          <div className="col flex flex-col gap-2 text-white text-lg sm:content-center sm:items-start items-center flex-wrap">
            <h4 className="bg-clip-text text-transparent bg-mainGradiant font-bold">
              Community
            </h4>
            <Link to={"/signup"} className="opacity-80">
              Register
            </Link>
            <Link to={"/login"} className="opacity-80">
              Log In
            </Link>
            <Link to={"/faq"} className="opacity-80">
              FAQs
            </Link>
            <Link to={"/how-frond-works"} className="opacity-80">
              How frond Works
            </Link>
          </div>
        </div>
        <div
          className="divider h-px mt-8 mb-6"
          style={{
            background:
              "linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)",
          }}
        ></div>
        <div className="copyright text-center">
          <p className="text-white opacity-80 text-lg">
            Copyrights @ 2024 — All rights reserved.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MainFooter;
