import { Image, Input, Tab, Tabs } from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import magnifierIcon from "../../Assets/discover/magnifier.svg";
import filterIcon from "../../Assets/discover/filter-search.svg";
import { Link, useLocation } from "react-router-dom";
import BestDeals from "./BestDeals";
import Hotels from "./Hotels";
import Flight from "./Flights";
import DiscoverFilter from "./DiscoverFilter";

export default function Suggestions() {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);

  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setActiveLink(pathname);
  }, [pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const tabsIconsActive = require.context(
    "../../Assets/discover/tabs/active",
    true
  );
  const tabsIconsNonActive = require.context(
    "../../Assets/discover/tabs/non-active",
    true
  );
  const tabsIconsActiveList = tabsIconsActive
    .keys()
    .map((image) => tabsIconsActive(image));
  const tabsIconsNonActiveList = tabsIconsNonActive
    .keys()
    .map((image) => tabsIconsNonActive(image));

  const tabsNames = [
    {
      title: "Discover",
      link: "/discover/suggestions",
    },
    {
      title: "Hotels",
      link: "/discover/suggestions/hotels",
    },
    {
      title: "Flights",
      link: "/discover/suggestions/flights",
    },
    {
      title: "Airports",
      link: "/discover/suggestions/airports",
    },
    {
      title: "Museums",
      link: "/discover/suggestions/museums",
    },
    {
      title: "Restaurants",
      link: "/discover/suggestions/restaurants",
    },
  ];

  return (
    <>
      <section className="suggestions bg-white rounded-4xl border-2 border-solid border-grayBorder py-5 w-full no-focus">
        <div className="flex justify-between items-center gap-2.5 mb-5 mx-5">
          <Input
            type="text"
            placeholder="Search and discover..."
            classNames={{
              base: "input-noMargin h-[39px] discover-search-input bg-white w-[399px]",
              label:
                "text-textColor font-normal smallPhone:text-sm text-[12px]",
              input:
                "border-none px-[18px] ms-1.5 smallPhone:text-sm text-[10px]",
              mainWrapper: "h-[39px] bg-white",
              innerWrapper: "h-[39px]",
              inputWrapper: `border-2 py-0 px-[15px] border-solid border-grayBorder no-focus rounded-[20px] h-[39px] bg-white`,
            }}
            startContent={
              <img
                src={magnifierIcon}
                alt="Search"
                className="w-[18px] h-[18px]"
              />
            }
          />
          <button
            onClick={() => {
              setOpenFilter(true);
            }}
            className="flex items-center gap-2"
          >
            <Image
              src={filterIcon}
              alt="Filter"
              className="w-[24px] h-[24px]"
            />
            <span className="text-mainBlue font-bold">Filter</span>
          </button>
        </div>
        <Tabs
          selectedKey={activeLink}
          aria-label="Discover Tabs"
          radius="full"
          classNames={{
            tabList: "bg-white p-0 w-full h-[36px] gap-2.5",
            tab: "py-[6px] px-5 discover-tab h-[36px]",
            tabContent:
              "phone:text-base text-[12px] font-normal leading-[18.4px] text-cardGrayColor",
            base: "w-full mb-[30px] px-5",
          }}
        >
          {tabsNames.map((item, idx) => (
            <Tab
              key={item.link}
              title={
                <div className="flex items-center justify-center gap-2.5">
                  <div className="relative w-[24px] h-[24px]">
                    <div className="absolute left-0 top-[50%] translate-y-[-50%]">
                      <Image
                        src={tabsIconsActiveList[idx]}
                        className={`min-w-[24px] min-h-[24px] transition-all duration-300 ${
                          activeLink === item.link
                            ? "opacity-100 block"
                            : "opacity-0 hidden"
                        }`}
                        alt={item.title}
                      />
                    </div>
                    <div className="absolute left-0 top-[50%] translate-y-[-50%]">
                      <Image
                        src={tabsIconsNonActiveList[idx]}
                        className={`min-w-[24px] min-h-[24px] transition-all duration-300 ${
                          activeLink !== item.link
                            ? "opacity-100 block"
                            : "opacity-0 hidden"
                        }`}
                        alt={item.title}
                      />
                    </div>
                  </div>
                  <span>{item.title}</span>
                </div>
              }
              as={Link}
              to={item.link}
            />
          ))}
        </Tabs>
        <BestDeals />
        <Hotels />
        <Flight />
      </section>
      <DiscoverFilter
        isOpen={openFilter}
        onClose={() => {
          setOpenFilter(false);
        }}
      />
    </>
  );
}
