import React, { useEffect } from "react";
import CreateFrondForm from "./CreateFrondForm";

export default function CreateAFrond() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="bg-white border-2 border-solid border-grayBorder p-7 rounded-4xl no-focus">
      <CreateFrondForm />
    </section>
  );
}
