import { AuthContext } from "Context/AuthContext";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedReset = (props) => {
  const { isVerified, forgetOtp, forgetEmail } = useContext(AuthContext);
  if (isVerified === false && forgetOtp && forgetEmail) {
    return props.children;
  } else {
    if (localStorage.getItem("token") !== null) {
      return <Navigate to={"/"}></Navigate>;
    } else {
      return <Navigate to={"/"}></Navigate>;
    }
  }
};

export default ProtectedReset;
