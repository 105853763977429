import react from "react"

const DataLoader = () => {
    return (
        <div
            className={`loading bg-white flex justify-center items-center transition-all duration-500`}
        >
            <div className="load w-[75px] h-[75px] flex justify-center items-center relative">
                <div className="spinner"></div>
                <div className="child-load"></div>
            </div>
        </div>
    )
}

export default DataLoader