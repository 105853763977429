import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import dots from "../../Assets/community/dots-vertical.svg";
import grayLikesIcon from "../../Assets/community/gray-likes.svg";
import grayCommentsIcon from "../../Assets/community/gray-comments.svg";
import likesIcon from "../../Assets/community/likes.svg";
import commentsIcon from "../../Assets/community/comments.svg";

export default function PostLayout({
  userImg,
  userName,
  time,
  content,
  likesCount,
  commentsCount,
  postImg,
}) {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);

  useEffect(() => {
    if (itemKey === "delete") {
      setItemKey(null);
    } else if (itemKey === "edit") {
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  return (
    <div className="post bg-white rounded-[20px] border-1 border-solid border-grayBorder p-5 w-full no-focus">
      <div className="flex items-start justify-between gap-2.5 mb-5">
        <div className="flex items-center gap-2.5">
          <Image
            src={userImg}
            alt={userName}
            className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full"
          />
          <div>
            <h5 className="mb-[3px] text-textColor font-bold text-base leading-[22px]">
              {userName}
            </h5>
            <p className="text-cardGrayColor text-sm leading-[16.1px]">
              {time}
            </p>
          </div>
        </div>
        <Dropdown
          classNames={{
            content: "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
          }}
          placement="left-start"
        >
          <DropdownTrigger>
            <button className="w-[24px] h-[24px]" onClick={handleDotsClick}>
              <img
                src={dots}
                alt="Dots"
                className="min-w-[24px] min-h-[24px]"
              />
            </button>
          </DropdownTrigger>
          <DropdownMenu
            aria-label="Post Actions"
            onAction={(key) => openModal(key, 1)}
          >
            <DropdownItem
              textValue="edit note"
              key="edit"
              className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
            >
              Edit
            </DropdownItem>
            <DropdownItem
              key="delete"
              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
              color="danger"
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
      <p className="mb-5 text-textColor leading-[22px]">{content}</p>
      {postImg && (
        <div className="w-5/6 mx-auto max-h-[335px] mb-2.5">
          <Image
            src={postImg}
            alt={content}
            classNames={{ wrapper: "min-w-full max-h-[335px]" }}
            className="w-full rounded-[10px] max-h-[335px] object-cover"
          />
        </div>
      )}
      <div className="flex justify-between mx-auto items-center w-5/6 pb-2.5 mb-2.5 border-b-1 border-solid border-grayBorder">
        <div className="flex items-center gap-1.5">
          <Image
            src={grayLikesIcon}
            alt="Likes"
            className="w-[18px] h-[18px]"
          />
          <p className="text-sm text-cardGrayColor leading-[16.1px]">
            {likesCount} Likes
          </p>
        </div>
        <div className="flex items-center gap-1.5">
          <Image
            src={grayCommentsIcon}
            alt="Comments"
            className="w-[18px] h-[18px]"
          />
          <p className="text-sm text-cardGrayColor leading-[16.1px]">
            {commentsCount} Comments
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between w-9/12 mx-auto">
        <button className="flex items-center justify-start gap-[5px] w-1/2 border-r-2 border-solid border-grayBorder">
          <Image src={likesIcon} alt="Like" className="w-[24px] h-[24px]" />
          <h4 className="text-base font-bold text-cardGrayColor leading-[18.4px]">
            Like
          </h4>
        </button>
        <button className="flex items-center justify-end gap-[5px] w-1/2">
          <Image
            src={commentsIcon}
            alt="Comment"
            className="w-[24px] h-[24px]"
          />
          <h4 className="text-base font-bold text-cardGrayColor leading-[18.4px]">
            Comment
          </h4>
        </button>
      </div>
    </div>
  );
}
