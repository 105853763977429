import { createContext, useState } from "react";

export let DeleteScrapbookImagesContext = createContext();

export default function DeleteScrapbookImagesContextProvider({ children }) {
    const [openDeleteCheckbox, setOpenDeleteCheckbox] = useState(false);
    const [deleteProjects, setDeleteProjects] = useState([]);

    return (
        <DeleteScrapbookImagesContext.Provider value={{ openDeleteCheckbox, setOpenDeleteCheckbox, deleteProjects, setDeleteProjects }}>
            {children}
        </DeleteScrapbookImagesContext.Provider>
    );
}
