import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "Context/AuthContext";
import OTPInput from "react-otp-input";
import { useClerk, useSignIn, useSignUp, useUser } from "@clerk/clerk-react";
import axios from "axios";
import { Error500Context } from "Context/Error500Context";

const VerifyPassword = () => {
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [timer, setTimer] = useState(0);
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  const {
    setIsVerified,
    isForgetPassword,
    setForgetOtp,
    forgetEmail,
    registerFname,
    registerLname,
    registerEmail,
    registerPass,
  } = useContext(AuthContext);

  useEffect(() => {
    otp.length === 6 ? setIsDisabled(false) : setIsDisabled(true);
  }, [otp]);

  const { signUp, setActive } = useSignUp();
  const { setIsServerError } = useContext(Error500Context);
  const [isResentOtp, setIsResentOtp] = useState(false);
  const { user } = useUser();

  async function callVerify() {
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);

    if (otp.match(/^[0-9]{6}$/)) {
      try {
        const completeSignUp = await signUp.attemptEmailAddressVerification({
          code: otp,
        });

        if (completeSignUp.status === "complete") {
          navigate("/loading");
          await setActive({ session: completeSignUp.createdSessionId });
          setIsVerified(true);
          await axios
            .post("/user", {
              name: completeSignUp.firstName + " " + completeSignUp.lastName,
              email: completeSignUp.emailAddress,
              password: completeSignUp.emailAddress,
              img_url: user.imageUrl,
            })
            .then((response) => {
              let authToken = localStorage.setItem(
                "token",
                response.data.response.data.token
              );
              setToken(response.data.response.data.token);

              axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
              setIsLoading(false);
            })
            .catch((err) => {
              if (err?.response?.status === 500) {
                setIsServerError(true);
                navigate("/server-error");
              } else {
                setErrorMessage(
                  err.errors ? err.errors[0].message : err.message
                );
              }
            });
        } else {
          setIsLoading(false);
          setErrorMessage("An error occurred");
        }
      } catch (error) {
        setErrorMessage(error.errors ? error.errors[0].message : error.message);
        setIsLoading(false);
      }
    } else {
      setErrorMessage("OTP must be 6 digits");
      setIsLoading(false);
    }
    setIsDisabled(false);
  }

  function callForgetVerify() {
    if (otp.match(/^[0-9]{6}$/)) {
      setForgetOtp(otp);
      navigate("/reset-password");
    }
  }

  const { signIn } = useSignIn();

  async function resendRegisterOtp() {
    try {
      await signUp.create({
        first_name: registerFname,
        last_name: registerLname,
        emailAddress: registerEmail,
        password: registerPass,
      });

      // Send the user an email with the verification code
      await signUp.prepareEmailAddressVerification({
        strategy: "email_code",
      });
      setIsResentOtp(true);
      startTimer();
    } catch (error) {
      setErrorMessage(error.errors[0].longMessage || "An error occurred");
    }
  }

  async function resendForgetOtp() {
    setErrorMessage("");
    try {
      await signIn?.create({
        strategy: "reset_password_email_code",
        identifier: forgetEmail,
      });
      setIsResentOtp(true);
      startTimer();
    } catch (error) {
      setErrorMessage(error.errors[0].longMessage || "An error occurred");
    }
  }

  function startTimer() {
    setTimer(59);
  }

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResentOtp(false);
    }
  }, [timer]);

  function getOtp(t) {
    setOtp(t);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="verify-password h-[calc(100vh-85px)] flex justify-center items-center relative overflow-x-hidden">
      <div className="absolute bottom-0 left-0 w-[450px] h-[412px] bg-authImg1 bg-cover bg-no-repeat z-0"></div>
      <div className="absolute top-[30px] right-0 w-[214px] h-[287px] bg-authImg2 bg-cover bg-no-repeat z-0"></div>
      <div className="container xl:px-44 sm:px-12 px-4 mx-auto relative z-10">
        <div className="box sm:max-w-[435px] max-w-[310px] mx-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white">
          <h2 className="font-bold text-[20.72px] mb-1 text-center">
            Confirm OTP
          </h2>
          <p className="text-cardGrayColor text-base text-center mb-6">
            Check your e-mail {isForgetPassword ? forgetEmail : registerEmail}
          </p>
          <div className="verify-password-form mb-8">
            {errorMessage && (
              <div className="text-white bg-cardColor py-3 mb-10 text-center rounded-15px text-sm">
                <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                  {errorMessage}
                </span>
              </div>
            )}
            <div className="mb-8">
              <OTPInput
                value={otp}
                onChange={getOtp}
                numInputs={6}
                containerStyle={"justify-between"}
                renderInput={(props, idx) => {
                  return (
                    <input
                      data-id={idx}
                      value={otp}
                      {...props}
                      className={`otp-input border-[0.86px] border-solid ${
                        props.value.length > 0
                          ? "border-[#5CCDB0] focusBorder"
                          : "border-cardColor normalBorder"
                      } rounded-[8.64px] sm:min-w-[46px] sm:h-[40px] min-w-[36px] h-[36px] bg-white text-[15.55px] font-normal text-[#2B2B2B]`}
                    />
                  );
                }}
              />
            </div>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                isForgetPassword ? callForgetVerify() : callVerify();
              }}
              disabled={isDisabled}
              className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
            >
              {isLoading ? (
                <span className="flex gap-2 justify-center items-center">
                  <i className="fa-solid fa-spinner fa-spin text-white"></i>
                </span>
              ) : (
                "Confirm OTP"
              )}
            </button>
          </div>
          <div className="sign-up text-center text-[13.81px]">
            {isResentOtp ? (
              <>
                <span>
                  Resend OTP in{" "}
                  <span className="text-transparent bg-clip-text bg-mainGradiant">
                    {timer}
                  </span>{" "}
                  seconds
                </span>
              </>
            ) : (
              <>
                Didn’t receive code?
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    isForgetPassword ? resendForgetOtp() : resendRegisterOtp();
                  }}
                  className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
                >
                  Resend OTP
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyPassword;
