import { createContext, useState } from "react";

export let ViewProjectsContext = createContext();

export default function ViewProjectsContextProvider({ children }) {
    const [myFrondsView, setMyFrondsView] = useState("grid");
    const [archiveFrondsView, setArchiveFrondsView] = useState("grid");
    const [sharedFrondsView, setSharedFrondsView] = useState("grid");
    const [pageType, setPageType] = useState("");

    return (
        <ViewProjectsContext.Provider value={{pageType, setPageType, myFrondsView, setMyFrondsView, archiveFrondsView, setArchiveFrondsView, sharedFrondsView, setSharedFrondsView }}>
            {children}
        </ViewProjectsContext.Provider>
    );
}