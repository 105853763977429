import { useState, useEffect } from "react";

// Custom hook for getting window dimensions
const useSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: typeof window !== 'undefined' ? window.innerWidth : 0,
        height: typeof window !== 'undefined' ? window.innerHeight : 0
    });

    useEffect(() => {
        // Function to handle window resize
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        };

        // Attach the event listener to window resize
        window.addEventListener('resize', handleResize);

        // Initial call to set size on mount
        handleResize();

        // Cleanup event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty dependency array ensures this runs only on mount/unmount

    return windowSize;
};

export default useSize;
