import React, { useEffect, useState } from "react";
import people from "../../Assets/contact-us/people.svg";
import card from "../../Assets/contact-us/card.svg";
import { Image, Input, Textarea, button } from "@nextui-org/react";
import arrow from "../../Assets/contact-us/arrow.svg";
import egFlag from "../../Assets/contact-us/egypt-flag.svg";
import whatsappIcon from "../../Assets/contact-us/whatsapp.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContactUsModal from "components/SuccessModals/ContactUsModal";

export default function AuthContactUs() {
  const [isCountryError, setIsCountryError] = useState("");
  const [countries, setCountries] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [contactUsModal, setContactUsModal] = useState(false);

  async function getCountries() {
    try {
      const { data } = await axios.get("https://restcountries.com/v3.1/all");

      setCountries(data);
    } catch (error) {
      setIsCountryError("Error");
    }
  }

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const [countryCode, setCountryCode] = useState("+20");
  const [countryFlag, setCountryFlag] = useState(egFlag);
  const [countryFilter, setCountryFilter] = useState("");

  async function callContact(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    setErrorMessage("");
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/contact-us`,
        {
          first_name: reqBody.first_name,
          last_name: reqBody.last_name,
          phone_number: `${countryCode}${reqBody.phone_number}`,
          email: reqBody.email,
          message: reqBody.message,
        }
      );

      if (data?.status === 201) {
        setContactUsModal(true);
        contactUsForm.handleReset();
        setCountryCode("+20");
        setCountryFlag(egFlag);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "An error occurred");
    }
    setIsLoading(false);
    setIsDisabled(false);
  }

  const validationSchema = Yup.object({
    first_name: Yup.string()
      .matches(/^[a-zA-Z]+(\s[A-Za-z]+)*$/gi, "Invalid first name")
      .min(2, "Short first name")
      .max(20, "Long first name")
      .required("First name required"),
    last_name: Yup.string()
      .matches(/^[a-zA-Z]+(\s[A-Za-z]+)*$/gi, "Invalid last name")
      .min(2, "Short last name")
      .max(20, "Long last name")
      .required("Last name required"),
    phone_number: Yup.string().matches(
      /^(?!([0-9])\1{6,14}$)[0-9]{7,15}$/,
      "Invalid phone number"
    ),
    email: Yup.string().email("Invalid email").required("Email required"),
    message: Yup.string()
      .min(2, "Short message")
      .max(150, "Long message")
      .matches(
        /^[a-zA-Z]+(\s[A-Za-z]+)*$/gi,
        "Message should contain characters and spaces only, should not end with space"
      )
      .required("Message required"),
  });

  const contactUsForm = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: callContact,
  });

  useEffect(() => {
    if (!menuOpen) {
      setCountryFilter("");
    }
  }, [menuOpen]);
  return (
    <>
      <div className="contact-box relative p-5 bg-white border-1 border-solid border-grayBorder rounded-4xl no-focus">
        <div className="info text-center mb-14">
          <p className="text-lg text-textColor mb-0.5 font-normal opacity-80">
            <strong className="font-bold me-1">Share your experience:</strong>
            We love hearing how you use frond and your ideas for improvement.
          </p>
          <p className="text-lg text-textColor mb-0.5 font-normal opacity-80">
            <strong className="font-bold me-1">Direct line to the top:</strong>
            All feedback goes straight to our founders, Nev and Mark, who are
            passionate about making frond even better.
          </p>
          <p className="text-lg text-textColor mb-0.5 font-normal opacity-80">
            <strong className="font-bold me-1">Personalised response:</strong>
            Expect a personal reply from Nev or Mark, showing we value your
            input.
          </p>
        </div>
        <div className="contact-form grid xl:grid-cols-2 gap-y-8 items-center">
          <div className="xl:mx-0 mx-auto">
            <Image src={card} alt="Card" />
          </div>
          <div className="form">
            <form onSubmit={contactUsForm.handleSubmit}>
              <div className="flex items-start gap-10 mb-6 auth-contact-form-input-container">
                <label
                  htmlFor="fName"
                  className="text-lg text-black opacity-75 min-w-[74px] mt-[7px]"
                >
                  Name
                </label>
                <div className="flex gap-5 items-start sm:flex-nowrap flex-wrap w-full">
                  <Input
                    id="fName"
                    name="first_name"
                    type="text"
                    variant="bordered"
                    placeholder="First Name"
                    className="sm:w-1/2"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                    onChange={contactUsForm.handleChange}
                    onBlur={contactUsForm.handleBlur}
                    value={contactUsForm.values.first_name}
                    isInvalid={contactUsForm.errors.first_name}
                    errorMessage={
                      contactUsForm.errors.first_name &&
                      contactUsForm.touched.first_name
                        ? contactUsForm.errors.first_name
                        : null
                    }
                  />
                  <Input
                    name="last_name"
                    type="text"
                    variant="bordered"
                    placeholder="Last Name"
                    className="sm:w-1/2"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                    onChange={contactUsForm.handleChange}
                    onBlur={contactUsForm.handleBlur}
                    value={contactUsForm.values.last_name}
                    isInvalid={contactUsForm.errors.last_name}
                    errorMessage={
                      contactUsForm.errors.last_name &&
                      contactUsForm.touched.last_name
                        ? contactUsForm.errors.last_name
                        : null
                    }
                  />
                </div>
              </div>
              <div className="flex items-start gap-10 mb-6 auth-contact-form-input-container">
                <label
                  htmlFor="email"
                  className="text-lg text-black opacity-75 min-w-[74px] mt-[7px]"
                >
                  Email
                </label>
                <div className="w-full">
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    variant="bordered"
                    placeholder="example@mail.com"
                    classNames={{
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                    onChange={contactUsForm.handleChange}
                    onBlur={contactUsForm.handleBlur}
                    value={contactUsForm.values.email}
                    isInvalid={contactUsForm.errors.email}
                    errorMessage={
                      contactUsForm.errors.email && contactUsForm.touched.email
                        ? contactUsForm.errors.email
                        : null
                    }
                  />
                </div>
              </div>
              <div className="flex items-start gap-10 mb-6 auth-contact-form-input-container relative">
                <label
                  htmlFor="phone"
                  className="text-lg text-black opacity-75 min-w-[74px] mt-[7px]"
                >
                  Phone
                </label>
                <Input
                  id="phone"
                  name="phone_number"
                  type="text"
                  variant="bordered"
                  placeholder="000 - 0000 - 000"
                  classNames={{
                    inputWrapper: "p-0 rounded-[10px]",
                    input: "py-3 px-5 border-none",
                  }}
                  startContent={
                    <button
                      className="h-full"
                      onClick={() => setMenuOpen(!menuOpen)}
                      type="button"
                    >
                      <div className="flex items-center gap-2 ps-5 pe-2 border-r-1 border-solid border-cardColor">
                        <Image
                          src={arrow}
                          alt="arrow"
                          className="min-w-[12px] h-[12px]"
                        />
                        <Image
                          src={countryFlag}
                          alt="Country Flag"
                          className="min-w-[21px] h-[21px]"
                        />
                        <span className="text-cardGrayColor text-sm">
                          {countryCode}
                        </span>
                      </div>
                    </button>
                  }
                  onChange={contactUsForm.handleChange}
                  onBlur={contactUsForm.handleBlur}
                  value={contactUsForm.values.phone_number}
                  isInvalid={contactUsForm.errors.phone_number}
                  errorMessage={
                    contactUsForm.errors.phone_number &&
                    contactUsForm.touched.phone_number
                      ? contactUsForm.errors.phone_number
                      : null
                  }
                />
                {countries.length > 0 && !isCountryError ? (
                  <ul
                    className={`list-none rounded-[10px] code absolute left-[114px] top-[40px] bg-cardColor transition-all duration-300 z-30 ${
                      menuOpen ? "h-[216px] py-2" : "h-0 py-0"
                    } overflow-y-auto flex flex-col gap-2`}
                  >
                    <input
                      type="text"
                      placeholder="Search country"
                      value={countryFilter}
                      onChange={(e) => setCountryFilter(e.target.value)}
                      className="px-3 py-2 border border-gray-300 w-[200px] mx-[17px] rounded-xl"
                    />
                    {countries
                      .filter((country) => {
                        const countryCode =
                          country.idd && country.idd.root
                            ? country.idd.root +
                              (country.idd.suffixes && country.idd.suffixes[0]
                                ? country.idd.suffixes[0]
                                : "")
                            : null;
                        return (
                          country.name &&
                          country.name.common &&
                          countryCode &&
                          country.name.common
                            .toLowerCase()
                            .includes(countryFilter.toLowerCase())
                        );
                      })
                      .map((country, idx) => (
                        <button
                          className="px-[17px]"
                          key={idx}
                          onClick={() => {
                            setCountryCode(
                              country.idd.root +
                                (country.idd.suffixes
                                  ? country.idd.suffixes[0]
                                  : "")
                            );
                            setCountryFlag(country.flags.svg);
                            setMenuOpen(false);
                          }}
                        >
                          <li className="flex items-center gap-2 px-[17px] py-3 bg-secondaryGray mx-auto rounded-xl">
                            {country.flags && country.flags.svg && (
                              <Image
                                src={country.flags.svg}
                                alt={`${country.name.common} Flag`}
                                className="min-w-[21px] max-w-[21px] h-[21px]"
                              />
                            )}
                            <span className="text-black opacity-75 font-bold text-sm">
                              {country.idd.root +
                                (country.idd.suffixes
                                  ? country.idd.suffixes[0]
                                  : "")}
                            </span>
                          </li>
                        </button>
                      ))}
                  </ul>
                ) : null}
              </div>

              <div className="flex items-start gap-10 mb-6 auth-contact-form-input-container relative z-20">
                <label
                  htmlFor="message"
                  className="text-lg text-black opacity-75 min-w-[74px]"
                >
                  Message
                </label>
                <div className="w-full">
                  <Textarea
                    id="message"
                    name="message"
                    variant="bordered"
                    placeholder="Enter your message here..."
                    classNames={{
                      innerWrapper: "h-[168px]",
                      inputWrapper: "p-0 rounded-[10px]",
                      input: "py-3 px-5 border-none",
                    }}
                    onChange={contactUsForm.handleChange}
                    onBlur={contactUsForm.handleBlur}
                    value={contactUsForm.values.message}
                    isInvalid={contactUsForm.errors.message}
                    errorMessage={
                      contactUsForm.errors.message &&
                      contactUsForm.touched.message
                        ? contactUsForm.errors.message
                        : null
                    }
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <button
                  disabled={
                    !(contactUsForm.isValid && contactUsForm.dirty) ||
                    isDisabled
                  }
                  type="submit"
                  className="text-lg text-white font-bold py-3 px-5 rounded-4xl bg-mainGradiant"
                >
                  {isLoading ? (
                    <span className="flex gap-2 justify-center items-center text-white">
                      <i className="fa-solid fa-spinner fa-spin"></i>
                    </span>
                  ) : (
                    "Send Message"
                  )}
                </button>
              </div>
              {errorMessage && (
                <div className="text-white bg-offWhite py-3 mt-5 max-w-[300px] ms-auto text-center rounded-15px text-sm">
                  <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                    {errorMessage}
                  </span>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <ContactUsModal
        isOpen={contactUsModal}
        onClose={() => {
          setContactUsModal(false);
        }}
      />
    </>
  );
}
