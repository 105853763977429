import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CardHeader from "./CardHeader";
import { PopUpContext } from "Context/PopUpContext";

const DetailsTemplate = () => {
  const { frondId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");
  const [lastImg, setLastImg] = useState("");
  let authToken = localStorage.getItem("token");

  const getFrondData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
      console.log(data);
      let lastImageObject = null;

      for (const imgKey in data?.response?.data.images) {
        if (data?.response?.data.images.hasOwnProperty(imgKey)) {
          lastImageObject = data.response.data.images[imgKey];
        }
      }

      if (lastImageObject) {
        setLastImg(lastImageObject?.original_url);
      }
      if (data?.response?.data?.frond?.start_date) {
        setStartDateArr(data?.response?.data?.frond?.start_date.split("/"));
      }
      if (data?.response?.data?.frond?.end_date) {
        setEndDateArr(data?.response?.data?.frond?.end_date.split("/"));
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondData();
  }, []);

  const { isEditTitle } = useContext(PopUpContext);

  useEffect(() => {
    if (isEditTitle) {
      getFrondData();
    }
  }, [isEditTitle]);
  return (
    <section className="frond-details rounded-4xl border-2 border-solid border-grayBorder p-5 bg-white no-focus">
      <CardHeader
        cardImg={frondData?.image}
        editId={frondData?.frond?.id}
        cardTitle={frondData?.frond?.title}
        editCategory={frondData?.frond?.category}
        editDesc={frondData?.frond?.desc}
        editStartDate={startDateArr}
        editEndDate={endDateArr}
        editBudget={frondData?.frond?.budget}
        editCurrecy={frondData?.frond?.currency}
        editTags={frondData?.tags}
        editImportance={frondData?.frond?.priority}
        editLatitude={frondData?.latitude}
        editLongitude={frondData?.longitude}
        editCountry={frondData?.frond?.country}
        editCity={frondData?.frond?.city}
        editLocation={frondData?.frond?.location}
        getFrondData={getFrondData}
        isArchived={frondData?.is_archived}
        isShared={frondData?.is_shared}
      />
      <Outlet />
    </section>
  );
};

export default DetailsTemplate;
