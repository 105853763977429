import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import nameIcon from "../../Assets/profile/name.svg";
import EmailIcon from "../../Assets/profile/email.svg";
import PhoneIcon from "../../Assets/profile/phone.svg";
import { Image, Input } from "@nextui-org/react";
import axios from "axios";
import egFlag from "../../Assets/contact-us/egypt-flag.svg";
import arrow from "../../Assets/profile/arrow.svg";

export default function EditProfileData({
  userName,
  userEmail,
  userPhone,
  userCountryCode,
  triggerForm,
}) {
  //Get Codes

  const [isCountryError, setIsCountryError] = useState("");
  const [countries, setCountries] = useState([]);

  async function getCountries() {
    try {
      const { data } = await axios.get("https://restcountries.com/v3.1/all");

      setCountries(data);
    } catch (error) {
      setIsCountryError("Error");
    }
  }

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const [countryCode, setCountryCode] = useState("+20");
  const [countryFlag, setCountryFlag] = useState(egFlag);
  const [countryFilter, setCountryFilter] = useState("");

  useEffect(() => {
    if (!menuOpen) {
      setCountryFilter("");
    }
  }, [menuOpen]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      phone: "",
    },
  });
  function editProfile(data) {
    console.log(data);
  }

  useEffect(() => {
    if (triggerForm) {
      handleSubmit(editProfile)();
    } else {
      setCountryCode("+20");
      setCountryFlag(egFlag);
      reset();
    }
  }, [triggerForm, handleSubmit]);

  useEffect(() => {
    if (userName) {
      setValue("fullName", userName || "");
    }
    if (userEmail) {
      setValue("email", userEmail || "");
    }
    if (userPhone) {
      setValue("phone", userPhone || "");
    }
    if (userCountryCode) {
      setCountryCode(userCountryCode);
    }
  }, [userName, userEmail, userPhone, userCountryCode]);

  useEffect(() => {
    if (countries.length > 0 && countryCode) {
      const foundCountry = countries.find((country) => {
        const countryCodeString =
          country.idd && country.idd.root
            ? country.idd.root +
              (country.idd.suffixes && country.idd.suffixes[0]
                ? country.idd.suffixes[0]
                : "")
            : null;
        return countryCodeString === countryCode;
      });

      if (foundCountry) {
        setCountryFlag(foundCountry.flags.svg);
      } else {
        setCountryFlag(null);
      }
    }
  }, [countryCode, countries]);

  useEffect(() => {
    if (triggerForm === "reset") {
      reset();
      setCountryCode("+20");
      setCountryFlag(egFlag);
    }
  }, [triggerForm]);

  return (
    <form onSubmit={handleSubmit(editProfile)}>
      <div
        className={`flex flex-col gap-2 w-full ${
          errors.fullName?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="fullNameFiled" className="flex items-center gap-[5px]">
          <Image src={nameIcon} className="w-[18px] h-[18px]" alt="Eidt Name" />
          <span className="text-cardGrayColor text-sm">Full Name</span>
        </label>
        <Controller
          name="fullName"
          control={control}
          rules={{
            required: "Name is required",
            minLength: {
              value: 3,
              message: "Minimum number of characters is 3",
            },
            maxLength: {
              value: 60,
              message: "Maximum number of characters is 60",
            },

            pattern: {
              value: /^[a-zA-Z()]+((\s|-|_)[A-Za-z\(\)]+)*$/gi,
              message: "Invalid name",
            },
          }}
          render={({ field }) => (
            <Input
              id="fullNameFiled"
              {...field}
              type="text"
              variant="bordered"
              placeholder="Enter your name"
              classNames={{
                base: `input-noMargin ${
                  errors.fullName?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.fullName?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.fullName?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.fullName?.message ? true : false}
              errorMessage={errors.fullName?.message}
            />
          )}
        />
      </div>
      <div
        className={`flex flex-col gap-2 w-full ${
          errors.email?.message ? "mb-[30px]" : "mb-5"
        }`}
      >
        <label htmlFor="emailFiled" className="flex items-center gap-[5px]">
          <Image
            src={EmailIcon}
            className="w-[18px] h-[18px]"
            alt="Eidt Email"
          />
          <span className="text-cardGrayColor text-sm">Email</span>
        </label>
        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email is required",
            validate: (value) => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              return emailRegex.test(value) || "Invalid email address";
            },
          }}
          render={({ field }) => (
            <Input
              id="emailFiled"
              {...field}
              type="text"
              variant="bordered"
              placeholder="Enter your email"
              classNames={{
                base: `input-noMargin ${
                  errors.email?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.email?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.email?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.email?.message ? true : false}
              errorMessage={errors.email?.message}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-2 w-full relative">
        <label htmlFor="PhoneFiled" className="flex items-center gap-[5px]">
          <Image
            src={PhoneIcon}
            className="w-[18px] h-[18px]"
            alt="Eidt Phone"
          />
          <span className="text-cardGrayColor text-sm">Phone (Optional)</span>
        </label>
        <Controller
          name="phone"
          control={control}
          rules={{
            pattern: {
              value: /^(?!([0-9])\1{6,14}$)[0-9]{7,15}$/,
              message: "Invalid phone number",
            },
          }}
          render={({ field }) => (
            <Input
              id="PhoneFiled"
              {...field}
              type="text"
              variant="bordered"
              placeholder="Enter your phone number"
              classNames={{
                base: `input-noMargin ${
                  errors.phone?.message ? "h-[39px]" : "h-[39px]"
                }`,
                label: "text-textColor font-normal phone:text-sm text-[10px]",
                input: "border-none px-[18px] phone:text-base text-[10px]",
                mainWrapper: `${
                  errors.phone?.message ? "h-[39px]" : "h-[39px]"
                }`,
                innerWrapper: "h-[39px]",
                inputWrapper: `border-[0.86px] p-0 border-solid ${
                  errors.phone?.message ? "invalid-text" : "border-[#E5E4EB]"
                } rounded-[8px] h-[39px]`,
              }}
              isInvalid={errors.phone?.message ? true : false}
              errorMessage={errors.phone?.message}
              startContent={
                <button
                  className="h-full"
                  onClick={() => setMenuOpen(!menuOpen)}
                  type="button"
                >
                  <div className="flex items-center gap-2 ps-5 pe-2 border-r-1 border-solid border-cardColor">
                    <Image
                      src={arrow}
                      alt="arrow"
                      className="min-w-[12px] h-[12px]"
                    />
                    {countryFlag !== null && (
                      <Image
                        src={countryFlag}
                        alt="Country Flag"
                        className="min-w-[21px] h-[21px]"
                      />
                    )}
                    <span className="text-cardGrayColor text-sm">
                      {countryCode}
                    </span>
                  </div>
                </button>
              }
            />
          )}
        />
        {countries.length > 0 && !isCountryError ? (
          <ul
            className={`list-none code absolute left-0 bottom-[38px] bg-cardColor transition-all duration-300 z-30 ${
              menuOpen ? "h-[216px] py-2" : "h-0 py-0"
            } overflow-y-auto flex flex-col gap-2`}
          >
            <input
              type="text"
              placeholder="Search country"
              value={countryFilter}
              onChange={(e) => setCountryFilter(e.target.value)}
              className="px-3 py-2 border border-gray-300 w-[200px] mx-[17px] rounded-xl"
            />
            {countries
              .filter((country) => {
                const countryCode =
                  country.idd && country.idd.root
                    ? country.idd.root +
                      (country.idd.suffixes && country.idd.suffixes[0]
                        ? country.idd.suffixes[0]
                        : "")
                    : null;
                return (
                  country.name &&
                  country.name.common &&
                  countryCode &&
                  country.name.common
                    .toLowerCase()
                    .includes(countryFilter.toLowerCase())
                );
              })
              .map((country, idx) => (
                <button
                  className="px-[17px]"
                  key={idx}
                  type="button"
                  onClick={() => {
                    setCountryCode(
                      country.idd.root +
                        (country.idd.suffixes ? country.idd.suffixes[0] : "")
                    );
                    setCountryFlag(country.flags.svg);
                    setMenuOpen(false);
                  }}
                >
                  <li className="flex items-center gap-2 px-[17px] py-3 bg-secondaryGray mx-auto rounded-xl">
                    {country.flags && country.flags.svg && (
                      <Image
                        src={country.flags.svg}
                        alt={`${country.name.common} Flag`}
                        className="min-w-[21px] max-w-[21px] h-[21px]"
                      />
                    )}
                    <span className="text-black opacity-75 font-bold text-sm">
                      {country.idd.root +
                        (country.idd.suffixes ? country.idd.suffixes[0] : "")}
                    </span>
                  </li>
                </button>
              ))}
          </ul>
        ) : null}
      </div>
    </form>
  );
}
