import React, { useContext, useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
  Image,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownTrigger,
  Accordion,
  AccordionItem,
} from "@nextui-org/react";
import Logo from "../../Assets/Images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useClerk } from "@clerk/clerk-react";
import { AuthContext } from "Context/AuthContext";
import upArrow from "../../Assets/landing/arrows/up.svg";
import downArrow from "../../Assets/landing/arrows/down.svg";

const NavbarComponent = () => {
  const tabsIconsNonActive = require.context(
    "../../Assets/landing/icons/nonActive",
    true
  );
  const tabsIconsNonActiveList = tabsIconsNonActive
    .keys()
    .map((image) => tabsIconsNonActive(image));

  const renderIndicator = ({ isOpen }) => {
    return <Image src={isOpen ? upArrow : downArrow} alt="arrow" />;
  };

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const menuItems = [
    { title: "Home", link: "/" },
    { title: "Tour" },
    { title: "Download", link: "/download" },
    { title: "Contact Us", link: "/contact-us" },
  ];

  const { pathname } = useLocation();
  const formattedPathname = pathname.replace(/\/+$/, "");

  const { signOut } = useClerk();
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem("token");
    setToken(null);
    navigate("/login");
  }

  const { token, setToken } = useContext(AuthContext);
  const isSignedIn = localStorage.getItem("token");

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "token") {
        if (!event.newValue) {
          setToken(null);
          navigate("/login");
          localStorage.removeItem("token");
          sessionStorage.clear();
        } else if (localStorage.getItem("token") !== token) {
          setToken(null);
          navigate("/login");
          localStorage.removeItem("token");
          sessionStorage.clear();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const isActive = (link) => {
    if (link === "/") {
      return formattedPathname === "";
    } else if (link === "/download") {
      return formattedPathname === "/download";
    } else if (link === "/contact-us") {
      return formattedPathname === "/contact-us";
    } else {
      return (
        formattedPathname === "/home" ||
        formattedPathname === "/ventures" ||
        formattedPathname === "/events" ||
        formattedPathname === "/escape"
      );
    }
  };

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => { }, [isAccordionOpen]);

  return (
    <Navbar
      isBordered
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
      maxWidth="xl"
      style={{ height: "85px" }}
      className="shadow-navShadow bg-[rgba(255,255,255,.9)]"
    >
      <NavbarContent className="flex" justify="start w-fit">
        <NavbarBrand style={{ maxWidth: "125px", maxHeight: "35px" }}>
          <Link to={"/"}>
            <Image src={Logo} style={{ width: "125px", maxHeight: "35px" }} />
          </Link>
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent
        className="hidden md:flex xl:gap-12 lg:gap-6 gap-4"
        justify="center w-fit"
      >
        {menuItems.map((item, idx) => (
          <NavbarItem key={idx}>
            {idx === 1 ? (
              <Dropdown
                classNames={{
                  content: "shadow-frondWorksDark p-2.5",
                  base: "top-[35px]",
                }}
                placement="bottom-start"
              >
                <DropdownTrigger>
                  <button
                    className={`${isActive("/home") ||
                      isActive("/ventures") ||
                      isActive("/events") ||
                      isActive("/escape")
                      ? "bg-clip-text text-transparent bg-mainGradiant font-bold opacity-100"
                      : "opacity-80"
                      } text-lg flex items-center gap-1`}
                  >
                    <span>{item.title}</span>
                    <span>
                      <Image
                        src={downArrow}
                        alt="arrow"
                        className="w-[18px] h-[18px]"
                      />
                    </span>
                  </button>
                </DropdownTrigger>
                <DropdownMenu aria-label="Static Actions">
                  <DropdownItem
                    key="home"
                    textValue="home"
                    className="relative after:absolute after:left-[8px] after:right-[8px] after:h-px after:bg-grayBorder after:bottom-[-2px]"
                  >
                    <Link to={"/home"}>
                      <div className="flex items-center gap-3">
                        <img
                          src={tabsIconsNonActiveList[0]}
                          className="w-[24px] h-[24px]"
                        />
                        <span className="text-lg text-[#333031]">Home</span>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    key="ventures"
                    textValue="ventures"
                    className="relative after:absolute after:left-[8px] after:right-[8px] after:h-px after:bg-grayBorder after:bottom-[-2px]"
                  >
                    <Link to={"/ventures"}>
                      <div className="flex items-center gap-3">
                        <img
                          src={tabsIconsNonActiveList[1]}
                          className="w-[24px] h-[24px]"
                        />
                        <span className="text-lg text-[#333031]">Ventures</span>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    key="events"
                    textValue="events"
                    className="relative after:absolute after:left-[8px] after:right-[8px] after:h-px after:bg-grayBorder after:bottom-[-2px]"
                  >
                    <Link to={"/events"}>
                      <div className="flex items-center gap-3">
                        <img
                          src={tabsIconsNonActiveList[2]}
                          className="w-[24px] h-[24px]"
                        />
                        <span className="text-lg text-[#333031]">Events</span>
                      </div>
                    </Link>
                  </DropdownItem>
                  <DropdownItem
                    key="escapes"
                    textValue="escapes"
                  >
                    <Link to={"/escape"}>
                      <div className="flex items-center gap-3">
                        <img
                          src={tabsIconsNonActiveList[3]}
                          className="w-[24px] h-[24px]"
                        />
                        <span className="text-lg text-[#333031]">Escapes</span>
                      </div>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : (
              <Link
                to={item.link}
                className={`${isActive(item.link)
                  ? "bg-clip-text text-transparent bg-mainGradiant font-bold opacity-100"
                  : "opacity-80"
                  } text-lg`}
              >
                {item.title}
              </Link>
            )}
          </NavbarItem>
        ))}
      </NavbarContent>

      <NavbarContent justify="end w-fit" className="hidden sm:flex">
        {token || isSignedIn ? (
          <NavbarItem className="flex gap-2.5">
            <button
              onClick={() => {
                signOut({ redirectUrl: "/" });
                logout();
              }}
              className={`flex justify-center h-[55.2px] items-center rounded-4xl py-3 px-5 text-lg font-bold bg-mainGradiant text-white`}
            >
              Log Out
            </button>
          </NavbarItem>
        ) : (
          <>
            <NavbarItem className="flex gap-2.5">
              <Link
                to={"/login"}
                className={`flex justify-center h-[55.2px] items-center rounded-4xl py-3 px-5 text-lg font-bold bg-mainGradiant text-white`}
              >
                Log In
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link
                to={"/signup"}
                className={`relative flex justify-center h-[55.2px] no-focus items-center rounded-4xl py-3 px-5 text-lg font-bold gradiantBorder`}
              >
                <span className="bg-clip-text text-transparent bg-mainGradiant">
                  Sign Up
                </span>
              </Link>
            </NavbarItem>
          </>
        )}
      </NavbarContent>

      <NavbarContent className="md:hidden" justify="start w-fit">
        <NavbarMenuToggle
          onClick={() => {
            setIsAccordionOpen(false);
          }}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </NavbarContent>

      <NavbarMenu
        style={{ top: "85px", alignItems: "center" }}
        className={`py-5 ${isAccordionOpen ? "sm:max-h-[328px]" : "sm:max-h-[176px]"
          } ${token
            ? `${isAccordionOpen ? "max-h-[364px]" : "max-h-[212px]"}`
            : `${isAccordionOpen ? "max-h-[400px]" : "max-h-[248px]"}`
          } rounded-b-15px`}
      >
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={index}>
            {index === 1 ? (
              <>
                <Accordion itemClasses={{ trigger: "p-0" }}>
                  <AccordionItem
                    onPress={() => setIsAccordionOpen(!isAccordionOpen)}
                    key="1"
                    aria-label="Tour"
                    title="Tour"
                    disableIndicatorAnimation
                    indicator={renderIndicator}
                  >
                    <Link
                      onClick={() => setIsMenuOpen(false)}
                      className="w-fit block mx-auto mb-2"
                      to={"/home"}
                      size="lg"
                    >
                      Home
                    </Link>
                    <Link
                      onClick={() => setIsMenuOpen(false)}
                      className="w-fit block mx-auto mb-2"
                      to={"/ventures"}
                      size="lg"
                    >
                      Ventures
                    </Link>
                    <Link
                      onClick={() => setIsMenuOpen(false)}
                      className="w-fit block mx-auto mb-2"
                      to={"/events"}
                      size="lg"
                    >
                      Events
                    </Link>
                    <Link
                      onClick={() => setIsMenuOpen(false)}
                      className="w-fit block mx-auto"
                      to={"/escape"}
                      size="lg"
                    >
                      Escapes
                    </Link>
                  </AccordionItem>
                </Accordion>
              </>
            ) : (
              <Link
                onClick={() => setIsMenuOpen(false)}
                className="w-full"
                to={item.link}
                size="lg"
              >
                {item.title}
              </Link>
            )}
          </NavbarMenuItem>
        ))}
        {token || isSignedIn ? (
          <NavbarMenuItem className="sm:hidden block">
            <button
              onClick={() => {
                signOut({ redirectUrl: "/" });
                logout();
                setIsMenuOpen(false);
              }}
            >
              Log Out
            </button>
          </NavbarMenuItem>
        ) : (
          <>
            <NavbarMenuItem className="sm:hidden block">
              <Link to={"/login"} onClick={() => setIsMenuOpen(false)}>
                Log In
              </Link>
            </NavbarMenuItem>
            <NavbarMenuItem className="sm:hidden block">
              <Link to={"/signup"} onClick={() => setIsMenuOpen(false)}>
                Sign Up
              </Link>
            </NavbarMenuItem>
          </>
        )}
      </NavbarMenu>
    </Navbar>
  );
};

export default NavbarComponent;
