import React, { useRef, useState } from "react";
import dollarIcon from "../../Assets/discover/sliders/dollar.svg";
import dealImg from "../../Assets/discover/sliders/deal-img.jpg";
import starIcon from "../../Assets/discover/sliders/star.svg";
import { Image } from "@nextui-org/react";

export default function BestDeals() {
  const sliderRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const preventDrag = (e) => {
    e.preventDefault();
  };

  const handleMouseDown = (e) => {
    const slider = sliderRef.current;
    setIsDragging(true);
    setStartX(e.pageX - slider.offsetLeft);
    setScrollLeft(slider.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const slider = sliderRef.current;
    const x = e.pageX - slider.offsetLeft;
    const walk = (x - startX) * 2;
    slider.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUpOrLeave = () => {
    setIsDragging(false);
  };

  return (
    <section className="best-deals ms-5">
      <div className="flex items-center gap-[5px] mb-2.5">
        <Image
          src={dollarIcon}
          className="w-[24px] h-[24px]"
          alt="Dollar Icon"
        />
        <h3 className="text-lg text-textColor leading-[22px] font-bold">
          Best Deals
        </h3>
      </div>
      <div className="overflow-hidden h-[288px]">
        <div
          ref={sliderRef}
          className="flex gap-5 items-center overflow-x-auto pb-5 cursor-grab active:cursor-grabbing"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUpOrLeave}
          onMouseLeave={handleMouseUpOrLeave}
        >
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[332px] max-w-[332px] h-[288px] bg-white p-[15px] rounded-[14px] border-2 border-solid border-grayBorder"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2.5" }}
              className="w-full h-[169px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2.5">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="flex flex-col gap-[7px] flex-1">
                <div className="rating flex items-center justify-between gap-2.5">
                  <div className="stars flex items-center gap-0.5">
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  </div>
                  <p className="text-cardGrayColor leading-[16.1px] text-sm ">
                    1,234 Reviews
                  </p>
                </div>
                <div className="price flex justify-between items-center gap-2.5">
                  <p>
                    <span className="text-sm leading-[16.1px] text-cardGrayColor me-[5px]">
                      € 120
                    </span>
                    <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                      € 70
                    </span>
                  </p>
                  <p className="text-sm leading-[16.1px] text-cardGrayColor">
                    2,345 Books
                  </p>
                </div>
              </div>
              <div className="py-1.5 px-1 rounded-[5px] bg-blueTimeline w-[31px] h-[30px] flex justify-center items-center">
                <span className="text-base font-bold text-white">8.4</span>
              </div>
            </div>
          </div>
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[332px] max-w-[332px] h-[288px] bg-white p-[15px] rounded-[14px] border-2 border-solid border-grayBorder"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2.5" }}
              className="w-full h-[169px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2.5">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="flex flex-col gap-[7px] flex-1">
                <div className="rating flex items-center justify-between gap-2.5">
                  <div className="stars flex items-center gap-0.5">
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  </div>
                  <p className="text-cardGrayColor leading-[16.1px] text-sm ">
                    1,234 Reviews
                  </p>
                </div>
                <div className="price flex justify-between items-center gap-2.5">
                  <p>
                    <span className="text-sm leading-[16.1px] text-cardGrayColor me-[5px]">
                      € 120
                    </span>
                    <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                      € 70
                    </span>
                  </p>
                  <p className="text-sm leading-[16.1px] text-cardGrayColor">
                    2,345 Books
                  </p>
                </div>
              </div>
              <div className="py-1.5 px-1 rounded-[5px] bg-blueTimeline w-[31px] h-[30px] flex justify-center items-center">
                <span className="text-base font-bold text-white">8.4</span>
              </div>
            </div>
          </div>
          <div
            onMouseDown={(e) => e.preventDefault()}
            className="min-w-[332px] max-w-[332px] h-[288px] bg-white p-[15px] rounded-[14px] border-2 border-solid border-grayBorder me-5"
          >
            <Image
              src={dealImg}
              alt="Deal Name"
              classNames={{ wrapper: "min-w-full mb-2.5" }}
              className="w-full h-[169px] rounded-[10px]"
            />
            <h3 className="text-lg text-textColor leading-[22px] font-bold mb-2.5">
              Ibis Madrid Aeropuerto Barajas
            </h3>
            <div className="flex items-center justify-between gap-2.5">
              <div className="flex flex-col gap-[7px] flex-1">
                <div className="rating flex items-center justify-between gap-2.5">
                  <div className="stars flex items-center gap-0.5">
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                    <Image src={starIcon} className="w-3 h-3" alt="Star Rate" />
                  </div>
                  <p className="text-cardGrayColor leading-[16.1px] text-sm ">
                    1,234 Reviews
                  </p>
                </div>
                <div className="price flex justify-between items-center gap-2.5">
                  <p>
                    <span className="text-sm leading-[16.1px] text-cardGrayColor me-[5px]">
                      € 120
                    </span>
                    <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px] font-bold text-base">
                      € 70
                    </span>
                  </p>
                  <p className="text-sm leading-[16.1px] text-cardGrayColor">
                    2,345 Books
                  </p>
                </div>
              </div>
              <div className="py-1.5 px-1 rounded-[5px] bg-blueTimeline w-[31px] h-[30px] flex justify-center items-center">
                <span className="text-base font-bold text-white">8.4</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
