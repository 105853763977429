import { createContext, useState } from "react";

export let FilterContext = createContext();

export default function FilterContextProvider({ children }) {
  const [frondFilterCategory, setFrondFilterCategory] = useState("");
  const [sharedFilterCategory, setSharedFilterCategory] = useState("");
  const [archiveFilterCategory, setArchiveFilterCategory] = useState("");

  const [frondFilterTags, setFrondFilterTags] = useState([]);
  const [sharedFilterTags, setSharedFilterTags] = useState([]);
  const [archiveFilterTags, setArchiveFilterTags] = useState([]);

  return (
    <FilterContext.Provider value={{ frondFilterCategory, setFrondFilterCategory, sharedFilterCategory, setSharedFilterCategory, archiveFilterCategory, setArchiveFilterCategory, frondFilterTags, setFrondFilterTags, sharedFilterTags, setSharedFilterTags, archiveFilterTags, setArchiveFilterTags }}>
      {children}
    </FilterContext.Provider>
  );
}
