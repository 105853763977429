import { createContext, useState } from "react";

export let SortContext = createContext();

export default function SortContextProvider({ children }) {
  const [frondSortTypes, setFrondSortTypes] = useState("Name (A-Z)");
  const [sharedSortTypes, setSharedSortTypes] = useState("Name (A-Z)");
  const [archiveSortTypes, setArchiveSortTypes] = useState("Name (A-Z)");

  return (
    <SortContext.Provider value={{ frondSortTypes, setFrondSortTypes, sharedSortTypes, setSharedSortTypes, archiveSortTypes, setArchiveSortTypes }}>
      {children}
    </SortContext.Provider>
  );
}
