import { Image, Input } from "@nextui-org/react";
import React, { useState } from "react";
import joinUs from "../../Assets/landing/join-us.svg";
import * as Yup from "yup";
import axios from "axios";
import { useFormik } from "formik";
import CommunityModal from "components/SuccessModals/CommunityModal";

const JoinFrond = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [communityModal, setCommunityModal] = useState(false);

  async function callSubscriber(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);
    setErrorMessage("");
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/subscribe`,
        reqBody
      );

      if (data?.status === 201) {
        setCommunityModal(true);
        communityForm.handleReset();
      }
    } catch (error) {
      if (
        error?.response?.data?.message === "The email has already been taken."
      ) {
        setErrorMessage(
          "This email address is already subscribed to our mailing list"
        );
      } else {
        setErrorMessage(error?.response?.data?.message || "An error occurred");
      }
    }
    setIsLoading(false);
    setIsDisabled(false);
  }

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email required"),
  });

  const communityForm = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: callSubscriber,
  });

  return (
    <>
      <section className="join-us pb-12">
        <div className="container xl:px-44 px-6 mx-auto">
          <div className="flex gap-x-5 gap-y-8 justify-center items-center lg:flex-nowrap flex-wrap">
            <div className="content sm:flex-1">
              <h2 className="text-38px font-bold bg-clip-text text-transparent bg-mainGradiant mb-3">
                Join the frond Community
              </h2>
              <p className="opacity-75 text-lg mb-10">
                Connect with the frond team for news & updates
              </p>
              <form onSubmit={communityForm.handleSubmit}>
                <div className="connect flex gap-4 items-center sm:flex-nowrap flex-wrap">
                  <Input
                    name="email"
                    className="placeholder:text-cardGrayColor placeholder:text-lg placeholder:-ps-1"
                    type="email"
                    placeholder="Email"
                    classNames={{
                      input: "border-none text-lg",
                      inputWrapper:
                        "min-h-[45px] border-1 border-solid border-cardColor bg-white joinFrond-input",
                      mainWrapper: "rounded-[10px]",
                      base: "sm:w-[386px] h-[45px]",
                    }}
                    onChange={communityForm.handleChange}
                    onBlur={communityForm.handleBlur}
                    value={communityForm.values.email}
                    isInvalid={communityForm.errors.email}
                    errorMessage={
                      communityForm.errors.email && communityForm.touched.email
                        ? communityForm.errors.email
                        : null
                    }
                  />
                  <button
                    disabled={
                      !(communityForm.isValid && communityForm.dirty) ||
                      isDisabled
                    }
                    type="submit"
                    className="flex justify-center items-center bg-mainGradiant rounded-[10px] w-[159px] h-[45px] font-bold text-lg text-white"
                  >
                    {isLoading ? (
                      <span className="flex gap-2 justify-center items-center text-white">
                        <span>Loading</span>
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Let’s Connect"
                    )}
                  </button>
                </div>
                {errorMessage && (
                  <div className="text-white bg-offWhite py-3 mt-5 w-fit px-5 text-center rounded-15px text-sm">
                    <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                      {errorMessage}
                    </span>
                  </div>
                )}
              </form>
            </div>
            <div className="join-img w-fit">
              <Image src={joinUs} alt="Join Us" />
            </div>
          </div>
        </div>
      </section>
      <CommunityModal
        isOpen={communityModal}
        onClose={() => {
          setCommunityModal(false);
        }}
      />
    </>
  );
};

export default JoinFrond;
