import { createContext, useState } from "react";

export let SidebarContext = createContext();

export default function SidebarContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <SidebarContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </SidebarContext.Provider>
  );
}
