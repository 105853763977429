import { LoadingContext } from "Context/LoadingContext";
import React, { useContext, useLayoutEffect } from "react";
import interestImg from "../../Assets/profile/interest.png";
import { Image } from "@nextui-org/react";

export default function Interests() {
  const { isInterestsLoaded, setIsInterestsLoaded } =
    useContext(LoadingContext);

  useLayoutEffect(() => {
    if (!isInterestsLoaded) {
      setTimeout(() => {
        setIsInterestsLoaded(true);
      }, 2000);
    }
  }, [isInterestsLoaded]);
  return (
    <div
      className={`hidden lg:block p-5 right-5 lg:min-w-[317px] lg:max-w-[317px] bg-white rounded-4xl border-2 border-solid border-grayBorder fixed z-20 no-focus transition-all duration-400 ${
        !isInterestsLoaded ? "opacity-0" : "opacity-100"
      }`}
    >
      <h2 className="text-lg text-textColor font-bold leading-[20.7px] mb-[15px]">
        Interests
      </h2>
      <div className="grid grid-cols-3 gap-x-2.5 gap-y-5  items-center">
        <div className="flex flex-col items-center gap-2">
          <Image
            src={interestImg}
            classNames={{ wrapper: "rounded-[10px]" }}
            className="size-full object-cover rounded-[10px]"
          />
          <h4>Music</h4>
        </div>
        <div className="flex flex-col items-center gap-2">
          <Image
            src={interestImg}
            classNames={{ wrapper: "rounded-[10px]" }}
            className="size-full object-cover rounded-[10px]"
          />
          <h4>Music</h4>
        </div>
        <div className="flex flex-col items-center gap-2">
          <Image
            src={interestImg}
            classNames={{ wrapper: "rounded-[10px]" }}
            className="size-full object-cover rounded-[10px]"
          />
          <h4>Music</h4>
        </div>
        <div className="flex flex-col items-center gap-2">
          <Image
            src={interestImg}
            classNames={{ wrapper: "rounded-[10px]" }}
            className="size-full object-cover rounded-[10px]"
          />
          <h4>Music</h4>
        </div>
      </div>
    </div>
  );
}
