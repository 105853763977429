import React, { useContext, useEffect, useState } from "react";

// import {Switch, Route} from "react-router-dom";

// URL Routes for pages

// layout
// import { Layout } from "./hocs/Layout";

// Component

// Pages
// import {Activation} from "./pages/Activation/Activation";

import { NextUIProvider, Tab, Tabs } from "@nextui-org/react";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";

import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import Layout from "components/Layout/Layout";

import Login from "components/Login/Login";
import Register from "components/Register/Register";
import Homepage from "pages/Homepage/Homepage";
import ForgetPassword from "components/ForgetPassword/ForgetPassword";
import VerifyPassword from "components/VerifyPassword/VerifyPassword";
import ProtectedLogin from "components/ProtectedRoutes/ProtectedLogin";
import ProtectedVerify from "components/ProtectedRoutes/ProtectedVerify";
import { AuthContext } from "Context/AuthContext";
import ProtectedRoutes from "components/ProtectedRoutes/ProtectedRoutes";
import AuthHomePage from "components/AuthHomePage/AuthHomePage";
import Download from "components/Download/Download";
import ContactUs from "components/ContactUs/ContactUs";
import HowFrondWorks from "components/HowFrondWorks/HowFrondWorks";
import FAQ from "components/FAQ/FAQ";
import Events from "components/Tour/Events";
import HomeComponent from "components/Tour/HomeComponent";
import Ventures from "components/Tour/Ventures";
import Escape from "components/Tour/Escape";
import AuthLayout from "components/AuthLayout/AuthLayout";
import MyFronds from "MyFronds/MyFronds";
import SharedFronds from "components/SharedFronds/SharedFronds";
import Invitations from "components/Invitations/Invitations";
import ArchiveFronds from "components/ArchiveFronds/ArchiveFronds";
import CreateFrond from "pages/CreateAFrond/CreateFrond";
import AboutFrond from "components/AboutFrond/AboutFrond";
import { useAuth, useClerk, useUser } from "@clerk/clerk-react";
import axios from "axios";
import Error404 from "pages/Error404/Error404";
import Error500 from "pages/Error500/Error500";
import CallbackAuth from "components/CallbackAuth/CallbackAuth";
import MainLoader from "pages/MainLoader/MainLoader";
import ResetPassword from "components/ResetPassword/ResetPassword";
import ProtectedReset from "components/ProtectedRoutes/ProtectedReset";
import ProtectedError from "components/ProtectedRoutes/ProtectedError";
import { Error500Context } from "Context/Error500Context";
import AboutFrondDetails from "components/FrondDetails/AboutFrondDetails";
import ScrapbookImages from "components/FrondDetails/Scrapbook/ScrapbookImages";
import DetailsTemplate from "components/FrondDetails/DetailsTemplate";
import ScrapbookTemplate from "components/FrondDetails/Scrapbook/ScrapbookTemplate";
import ScrapbookNotes from "components/FrondDetails/Scrapbook/ScrapbookNotes";
import ScrapbookVoice from "components/FrondDetails/Scrapbook/ScrapbookVoice";
import ScrapbookLinks from "components/FrondDetails/Scrapbook/ScrapbookLinks";
import People from "components/FrondDetails/People/People";
import TasklistTemplate from "components/FrondDetails/Tasklist/TasklistTemplate";
import AllTasks from "components/FrondDetails/Tasklist/AllTasks";
import ActiveTasks from "components/FrondDetails/Tasklist/ActiveTasks";
import CompletedTasks from "components/FrondDetails/Tasklist/CompletedTasks";
import OverdueTasks from "components/FrondDetails/Tasklist/OverdueTasks";
import UpdatesTemplate from "components/FrondDetails/Updates/UpdatesTemplate";
import Discover from "components/FrondDetails/Discover/Discover";
import PrivacySettings from "components/FrondDetails/PrivacySettings/PrivacySettings";
import MyTasks from "components/MyTasks/MyTasks";
import AuthContactUs from "components/AuthContactUs/AuthContactUs";
import CommunityLayout from "components/Community/CommunityLayout";
import DiscoverData from "components/DiscoverScreen/DiscoverData";
import Suggestions from "components/DiscoverScreen/Suggestions";
import DiscoverLayout from "components/DiscoverScreen/DiscoverLayout";
import ProfilePage from "components/Profile/ProfilePage";
// if (!getApps().length) {
//   consttttdddtttt app sss= innitializeApddp(firebaseConfig);
//   getAnalytics(app);
// } else {
//   getApp();
// }

export const App = () => {
  const { clerkUser, user } = useUser();
  const { session } = useClerk();
  axios.defaults.baseURL = "https://frond-admin.code-minds.tech/api";
  const isSignedIn = localStorage.getItem("token");
  const { setToken } = useContext(AuthContext);

  const getData = async function () {
    if (session?.user && !isSignedIn) {
      await axios
        .post("/user", {
          name: user.fullName,
          email: user.primaryEmailAddress.emailAddress,
          password: user.id,
          img_url: user.imageUrl,
        })
        .then(res => {
          let authToken = localStorage.setItem(
            "token",
            res.data.response.data.token,
          );
          setToken(res.data.response.data.token);
          axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
        })
        .catch(err => {});
    }
    // }
    // } catch (err) {}
  };

  useEffect(() => {
    getData();
  }, [user]);

  //

  const { token } = useContext(AuthContext);

  const routes = createBrowserRouter([
    {
      path: "",
      element: token ? <AuthLayout /> : <Layout />,
      children: [
        {
          index: true,
          element: !isSignedIn ? (
            <ProtectedLogin>
              <Homepage />
            </ProtectedLogin>
          ) : (
            <ProtectedRoutes>
              <MyFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "home",
          element: !isSignedIn ? (
            <ProtectedLogin>
              <HomeComponent />
            </ProtectedLogin>
          ) : (
            <ProtectedRoutes>
              <MyFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "shared-fronds",
          element: (
            <ProtectedRoutes>
              <SharedFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "my-fronds",
          element: (
            <ProtectedRoutes>
              <MyFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "my-tasks",
          element: (
            <ProtectedRoutes>
              <MyTasks />
            </ProtectedRoutes>
          ),
        },
        {
          path: "profile",
          element: (
            <ProtectedRoutes>
              <ProfilePage />
            </ProtectedRoutes>
          ),
        },
        {
          path: "invitations",
          element: (
            <ProtectedRoutes>
              <Invitations />
            </ProtectedRoutes>
          ),
        },
        {
          path: "frond",
          element: (
            <ProtectedRoutes>
              <DetailsTemplate />
            </ProtectedRoutes>
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoutes>
                  <AboutFrondDetails />
                </ProtectedRoutes>
              ),
            },
            {
              path: "about/:frondId",
              element: (
                <ProtectedRoutes>
                  <AboutFrondDetails />
                </ProtectedRoutes>
              ),
            },
            {
              path: "scrapbook",
              element: (
                <ProtectedRoutes>
                  <ScrapbookTemplate />
                </ProtectedRoutes>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoutes>
                      <ScrapbookImages />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "images/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <ScrapbookImages />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "notes/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <ScrapbookNotes />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "voice-memos/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <ScrapbookVoice />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "links/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <ScrapbookLinks />
                    </ProtectedRoutes>
                  ),
                },
              ],
            },
            {
              path: "people/:frondId",
              element: (
                <ProtectedRoutes>
                  <People />
                </ProtectedRoutes>
              ),
            },
            {
              path: "tasklist",
              element: (
                <ProtectedRoutes>
                  <TasklistTemplate />
                </ProtectedRoutes>
              ),
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoutes>
                      <AllTasks />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "all/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <AllTasks />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "pending/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <ActiveTasks />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "completed/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <CompletedTasks />
                    </ProtectedRoutes>
                  ),
                },
                {
                  path: "in-progress/:frondId",
                  element: (
                    <ProtectedRoutes>
                      <OverdueTasks />
                    </ProtectedRoutes>
                  ),
                },
              ],
            },
            {
              path: "updates/:frondId",
              element: (
                <ProtectedRoutes>
                  <UpdatesTemplate />
                </ProtectedRoutes>
              ),
            },
            {
              path: "discover/:frondId",
              element: (
                <ProtectedRoutes>
                  <Discover />
                </ProtectedRoutes>
              ),
            },
            {
              path: "settings/:frondId",
              element: (
                <ProtectedRoutes>
                  <PrivacySettings />
                </ProtectedRoutes>
              ),
            },
          ],
        },
        {
          path: "archived-fronds",
          element: (
            <ProtectedRoutes>
              <ArchiveFronds />
            </ProtectedRoutes>
          ),
        },
        {
          path: "create-frond",
          element: (
            <ProtectedRoutes>
              <CreateFrond />
            </ProtectedRoutes>
          ),
        },
        {
          path: "community",
          element: (
            <ProtectedRoutes>
              <CommunityLayout />
            </ProtectedRoutes>
          ),
        },
        {
          path: "discover",
          element: (
            <ProtectedRoutes>
              <DiscoverLayout />
            </ProtectedRoutes>
          ),
          children: [
            {
              index: true,
              element: (
                <ProtectedRoutes>
                  <DiscoverData />
                </ProtectedRoutes>
              ),
            },
            {
              path: "options",
              element: (
                <ProtectedRoutes>
                  <DiscoverData />
                </ProtectedRoutes>
              ),
            },
            {
              path: "suggestions",
              element: (
                <ProtectedRoutes>
                  <Suggestions />
                </ProtectedRoutes>
              ),
            },
            {
              path: "suggestions/:searchOption",
              element: (
                <ProtectedRoutes>
                  <Suggestions />
                </ProtectedRoutes>
              ),
            },
          ],
        },
        {
          path: "login",
          element: (
            <ProtectedLogin>
              <Login />
            </ProtectedLogin>
          ),
        },
        {
          path: "signup",
          element: (
            <ProtectedLogin>
              <Register />
            </ProtectedLogin>
          ),
        },
        {
          path: "forget-password",
          element: (
            <ProtectedLogin>
              <ForgetPassword />
            </ProtectedLogin>
          ),
        },
        {
          path: "reset-password",
          element: (
            <ProtectedLogin>
              <ProtectedReset>
                <ResetPassword />
              </ProtectedReset>
            </ProtectedLogin>
          ),
        },
        {
          path: "verify-password",
          element: (
            <ProtectedLogin>
              <ProtectedVerify>
                <VerifyPassword />
              </ProtectedVerify>
            </ProtectedLogin>
          ),
        },
        {
          path: "events",
          element: (
            <ProtectedLogin>
              <Events />
            </ProtectedLogin>
          ),
        },
        {
          path: "ventures",
          element: (
            <ProtectedLogin>
              <Ventures />
            </ProtectedLogin>
          ),
        },
        {
          path: "escape",
          element: (
            <ProtectedLogin>
              <Escape />
            </ProtectedLogin>
          ),
        },
        {
          path: "download",
          element: (
            <ProtectedLogin>
              <Download />
            </ProtectedLogin>
          ),
        },
        {
          path: "contact-us",

          element: !isSignedIn ? (
            <ProtectedLogin>
              <Homepage />
            </ProtectedLogin>
          ) : (
            <ProtectedRoutes>
              <AuthContactUs />
            </ProtectedRoutes>
          ),
        },
        {
          path: "how-frond-works",
          element: (
            <ProtectedLogin>
              <HowFrondWorks />
            </ProtectedLogin>
          ),
        },
        {
          path: "about-frond",
          element: (
            <ProtectedLogin>
              <AboutFrond />
            </ProtectedLogin>
          ),
        },
        {
          path: "faq",
          element: (
            <ProtectedLogin>
              <FAQ />
            </ProtectedLogin>
          ),
        },
        {
          path: "server-error",
          element: (
            <ProtectedError>
              <Error500 />
            </ProtectedError>
          ),
        },
        {
          path: "loading",
          element: <MainLoader />,
        },
        {
          path: "sso-callback",
          element: <MainLoader />,
        },
        {
          path: "sso-callback#_=_",
          element: <MainLoader />,
        },
        {
          path: "*",
          element: <Error404 />,
        },
      ],
    },
  ]);

  return (
    <>
      <NextUIProvider locale="en-GB">
        <RouterProvider router={routes}></RouterProvider>
      </NextUIProvider>
    </>
  );
};

/*

const clerkUser = useUser();

    const {getToken, isSignedIn} = useAuth();
    const [selected, setSelected] = React.useState("myFronds");
    const {pathname} = useLocation();

    const db = getFirestore();

    const RouteWithSidebar = ({component: Component, ...rest}) => {
        const [loaded, setLoaded] = React.useState(false);
        React.useEffect(() => {
            const timer = setTimeout(() => setLoaded(true), 4000);
            return () => clearTimeout(timer);
        }, []);
        return (
            <Route
                {...rest}
                render={props => (
                    <>
                        <Layout>
                            {!loaded ? (
                                <Loader/>
                            ) : (
                                <>
                                    <Header/>

                                    <Component {...props} />
                                </>
                            )}
                        </Layout>
                    </>
                )}
            />
        );
    };

    const RouteFullPage = ({component: Component, ...rest}) => {
        const [loaded, setLoaded] = React.useState(false);

        React.useEffect(() => {
            const timer = setTimeout(() => setLoaded(true), 4000);
            return () => clearTimeout(timer);
        }, []);
        // console.log(loaded);
        return (
            <Route
                {...rest}
                render={props => (
                    <>
                        <Layout>
                            {!loaded ? (
                                <Loader/>
                            ) : (
                                <>
                                    <Component {...props} />
                                </>
                            )}
                        </Layout>
                    </>
                )}
            />
        );
    };

    useEffect(() => {
        axios.defaults.baseURL = "https://frond-admin.code-minds.tech/api";
        const getData = async function () {
            try {
                // const firebaseToken = clerkUser.user.getSessions();
                const auth = getAuth();

                const token = await getToken({template: "integration_firebase"});
                const userCredentials = await signInWithCustomToken(auth, token);
                if (clerkUser.isSignedIn) {
                    axios
                        .post("/user", {
                            name: clerkUser.user.fullName,
                            email: clerkUser.user.primaryEmailAddress.emailAddress,
                            password: clerkUser.user.id,
                        })
                        .then(res => {
                            let authToken = localStorage.setItem(
                                "token",
                                res.data.response.data.token,
                            );
                            axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
                        })
                        .catch(err => {
                            // console.log(err.response.data.errors);
                        });
                }
            } catch (err) {
                // console.log(err);
            }
        };

        getData();
    }, [clerkUser]);
    // console.log(pathname);
    return (
        <NextUIProvider>
            <ChakraProvider>
                {!isSignedIn ? <Header/> : <AuthNavbar/>}
                {isSignedIn && (
                    <Tabs
                        aria-label="Tabs"
                        selectedKey={pathname}
                        classNames={{
                            cursor: "w-full bg-primaryBlue ",
                            tabContent:
                                "group-data-[selected=true]:text-[#fff] group-data-[selected=false]:text-[#2B2B2A]",
                            base: "sticky top-0",
                        }}
                        variant="light"
                        size="lg"
                        fullWidth
                        radius="lg"
                    >
                        <Tab
                            key="/"
                            id="/"
                            as={Link}
                            href="/"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Target/>

                                    <span>My fronds</span>
                                </div>
                            }
                        />
                        <Tab
                            key="/createfrond"
                            id="/createfrond"
                            href="/createfrond"
                            title={
                                <div className="flex items-center space-x-2">
                                    <SquarePen/>

                                    <span>Create a frond</span>
                                </div>
                            }
                        />
                        <Tab
                            key="sharedFronds"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Share2/>

                                    <span>Shared fronds</span>
                                </div>
                            }
                        />
                        <Tab
                            key="archivedFronds"
                            title={
                                <div className="flex items-center space-x-2">
                                    <ArchiveX/>

                                    <span>Archived fronds</span>
                                </div>
                            }
                        />
                        <Tab
                            key="invitations"
                            title={
                                <div className="flex items-center space-x-2">
                                    <UserPlus/>

                                    <span>Invitations</span>
                                </div>
                            }
                        />
                        <Tab
                            key="discover"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Target/>

                                    <span>Discover</span>
                                </div>
                            }
                        />
                        <Tab
                            key="community"
                            title={
                                <div className="flex items-center space-x-2">
                                    <Users/>

                                    <span>Community</span>
                                </div>
                            }
                        />
                    </Tabs>
                )}
                <Switch>
                    {isSignedIn ? (
                        <>
                            {/* <RouteFullPage
                  component={Dashboard}
                  path={Routes.Home.path}
                  exact
                /> }
                <RouteFullPage component={MyFrond} path={"/"} exact/>
                <RouteFullPage
                    component={CreateAFrond}
                    path={"/createfrond"}
                    exact
                />
            </>
        ) : (
            <>
                <RouteFullPage
                    component={Homepage}
                    path={Routes.Home.path}
                    exact
                />
                <RouteFullPage
                    component={Workpage}
                    path={Routes.Work.path}
                    exact
                />
                <RouteFullPage
                    component={Celebratepage}
                    path={Routes.Celebrate.path}
                    exact
                />
                <RouteFullPage
                    component={Pricingpage}
                    path={Routes.Pricing.path}
                    exact
                />
                <RouteFullPage
                    component={Contactpage}
                    path={Routes.Contact.path}
                    exact
                />
                <RouteFullPage
                    component={Escapepage}
                    path={Routes.Escape.path}
                    exact
                />
                <RouteFullPage
                    component={Homeplaning}
                    path={Routes.Homeplaning.path}
                    exact
                />
            </>
        )}
    </Switch>
</ChakraProvider>
</NextUIProvider>
);
*/
