import React, { useState, useEffect, useContext } from "react";
import "react-h5-audio-player/lib/styles.css";
import AudioPlayer from "react-h5-audio-player";
import dots from "../../../Assets/scrapbook/dots-vertical.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";
import WavesurferPlayer from "@wavesurfer/react";

const ScrapbookVoice = () => {
  const [itemKey, setItemKey] = useState(null);
  const { frondId } = useParams();
  const [frondData, setFrondData] = useState([]);
  const [isDataLoad, setIsDataLoad] = useState(false);
  const { isAddRecord } = useContext(PopUpContext);
  const authToken = localStorage.getItem("token");
  const [targetId, setTargetId] = useState(null);

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const getScrapbookRecords = async () => {
    setIsDataLoad(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-memos/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log(data);
      setFrondData(data?.response?.data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoad(false);
  };

  useEffect(() => {
    getScrapbookRecords();
  }, [frondId]);

  useEffect(() => {
    if (isAddRecord) {
      getScrapbookRecords();
    }
  }, [isAddRecord]);

  const deleteRecord = async () => {
    if (!targetId) return;
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/scrapbook-memos/${frondId}/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      getScrapbookRecords();
      toast("Record deleted successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (itemKey === "delete") {
      deleteRecord();
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const [wavesurfer, setWavesurfer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <section className="scrapbook-voice-notes">
      {frondData?.files?.length > 0 && (
        <div className="flex flex-col gap-[15px]">
          {frondData.files.map((item, idx) => (
            <div
              key={item.id}
              className={`flex justify-between w-full ${
                idx !== frondData.length - 1 &&
                "pb-[15px] border-b-1 border-solid border-b-grayBorder"
              }`}
            >
              <div className="flex flex-col">
                <div className="flex items-start gap-2.5">
                  <Image
                    src={item?.user?.img_url}
                    className="phone:min-w-[45px] phone:min-h-[45px] max-w-[30px] max-h-[30px] rounded-full"
                    alt="Link Image"
                  />
                  <div>
                    <h4 className="text-base text-textColor font-bold leading-[22px] mb-[3px]">
                      {item.user?.name || "-"}
                    </h4>
                    <p className="text-sm text-cardGrayColor mb-2.5">
                      {timeAgo(item.created_at) || "-"}
                    </p>
                    <p className="text-mainBlue leading-[22px] font-bold mb-[8px]">
                      {item.title || "-"}
                    </p>
                  </div>
                </div>
                <div className="phone:ms-[55px]">
                  <AudioPlayer src={item.image} />
                </div>
              </div>
              <Dropdown
                classNames={{
                  content:
                    "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                }}
                placement="left-start"
              >
                <DropdownTrigger>
                  <button
                    className="w-[24px] h-[24px]"
                    onClick={handleDotsClick}
                  >
                    <img src={dots} alt="Dots" />
                  </button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Frond Actions"
                  onAction={(key) => openModal(key, item.id)}
                >
                  <DropdownItem
                    key="delete"
                    className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                    color="danger"
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

export default ScrapbookVoice;
