import React, { useContext } from "react";
import errorImg from "../../Assets/500/500.svg";
import { Image } from "@nextui-org/react";
import { Link } from "react-router-dom";
import { AuthContext } from "Context/AuthContext";

const Error500 = () => {
  const { token } = useContext(AuthContext);
  return (
    <section
      className={`500 flex flex-col gap-2 justify-center items-center ${
        !token
          ? "h-[calc(100vh-85px)]"
          : "h-[calc(100vh-109px)] rounded-4xl border-2 border-solid border-grayBorder"
      } relative`}
    >
      {!token && (
        <>
          <div className="absolute left-0 bottom-0 sm:w-[344px] w-[55%] h-[384.17px] z-10 bg-orangeBuble404"></div>
          <div className="absolute right-0 top-[5%] sm:w-[260px] w-[40%] h-[348px] z-10 bg-print404"></div>
        </>
      )}
      <Image
        src={errorImg}
        alt="500"
        className="w-[239px] h-[239px]"
        classNames={{ wrapper: "relative z-20" }}
      />
      <p className="text-cardGrayColor font-bold text-[22px] text-center leading-[25.3px] px-4 relative z-20">
        Oops! Something went wrong
      </p>
      <p className="text-cardGrayColor font-bold text-lg leading-[20.7px] sm:w-[437px] sm:px-0 px-4 mx-auto text-center relative z-20">
        Try to refresh this page or feel free to
        <Link to={"/contact-us"} className="mx-1 text-darkBlue">
          Contact Us
        </Link>
        if the problem persists
      </p>
    </section>
  );
};

export default Error500;
