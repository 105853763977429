import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = (props) => {
  if (
    localStorage.getItem("token") !== null
  ) {
    return props.children;
  } else {
    return <Navigate to={"/login"}></Navigate>;
  }
};

export default ProtectedRoutes;
