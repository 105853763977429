import { createContext, useState } from "react";

export let SearchFrondContext = createContext();

export default function SearchFrondContextProvider({ children }) {
    const [searchValue, setSearchValue] = useState("");

    return (
        <SearchFrondContext.Provider value={{ searchValue, setSearchValue }}>
            {children}
        </SearchFrondContext.Provider>
    );
}