import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import dollarIcon from "../../Assets/filter/dollar.svg";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import rateGrayStar from "../../Assets/filter/rate-star.svg";
import activeStar from "../../Assets/filter/active-star.svg";
import nonActiveStar from "../../Assets/filter/non-active-star.svg";

const DiscoverFilter = ({ isOpen, onClose }) => {
  const [range, setRange] = useState([0, 1000]);

  useLayoutEffect(() => {
    if (isOpen) {
      setRange([0, 1000]);
    }
    calculateTooltipPosition(0, 0, 1000);
  }, [isOpen]);

  const calculateTooltipPosition = (value, min, max) => {
    const percentage = ((value - min) / (max - min)) * 100;
    const dynamicOffset =
      value <= 100
        ? 38 / 2
        : value <= 200
        ? 42 / 2
        : value <= 500
        ? 44 / 2
        : value <= 700
        ? 52 / 2
        : value <= 700
        ? 58 / 2
        : value <= 950
        ? 58 / 2
        : 64 / 2;
    return `calc(${percentage}% - ${dynamicOffset}px)`;
  };

  const [rates, setRates] = useState([]);

  const toggleRate = (value) => {
    if (rates.includes(value)) {
      setRates(rates.filter((rate) => rate !== value));
    } else {
      setRates([...rates, value]);
    }
  };

  useEffect(() => {
    console.log(rates);
  }, [rates]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] discover-filter-modal",
        footer: "justify-center items-center pb-2",
        body: "border-b-1 border-solid border-grayBorder p-5",
        closeButton: "hidden",
        base: "rounded-[10px] p-5",
      }}
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <ModalBody className="overflow-auto">
              <div className="flex justify-between gap-x-[50px] gap-y-5 sm:flex-nowrap flex-wrap">
                <div className="sort sm:w-auto w-full">
                  <RadioGroup
                    classNames={{
                      label:
                        "text-textColor font-bold leading-[30px] text-base mb-2.5",
                      base: "gap-0",
                      wrapper: "gap-0 ms-2.5",
                    }}
                    label="Sort by"
                    defaultValue="Recommended"
                  >
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter",
                      }}
                      value="Recommended"
                    >
                      Recommended
                    </Radio>
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter",
                      }}
                      value="Deals First"
                    >
                      Deals First
                    </Radio>
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter",
                      }}
                      value="Rating (High - Low)"
                    >
                      Rating (High - Low)
                    </Radio>
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter",
                      }}
                      value="Rating (Low - High)"
                    >
                      Rating (Low - High)
                    </Radio>
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter",
                      }}
                      value="Price (Low - High)"
                    >
                      Price (Low - High)
                    </Radio>
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 border-b-1 border-solid border-grayBorder radio-filter",
                      }}
                      value="Most reviewed"
                    >
                      Most reviewed
                    </Radio>
                    <Radio
                      classNames={{
                        control: "bg-mainGradiant w-3 h-3",
                        label: "text-cardGrayColor leading-[16.1px] text-sm",
                        base: "ps-0 pe-5 py-2.5 m-0 radio-filter",
                      }}
                      value="Best reviewed"
                    >
                      Best reviewed
                    </Radio>
                  </RadioGroup>
                </div>
                <div className="filter sm:flex-1 sm:w-auto w-full">
                  <h3 className="text-textColor font-bold text-base leading-[30px] mb-5">
                    Filter by
                  </h3>
                  <div className="ms-2.5">
                    <div className="flex items-center gap-2.5 mb-[15px]">
                      <Image
                        src={dollarIcon}
                        className="w-[18px] h-[18px]"
                        alt="Price Icon"
                      />
                      <h4 className="text-cardGrayColor leading-[16.1px] text-sm font-bold">
                        Price
                      </h4>
                    </div>

                    <div className="h-[39px] flex flex-col justify-end relative mb-10">
                      <div
                        className="absolute flex justify-center items-center w-[46px] h-[22px] py-[3px] px-1 rounded-[5px] border-1 border-solid border-mainOrange"
                        style={{
                          left: calculateTooltipPosition(range[0], 0, 1000),
                          top: range[1] - range[0] >= 250 ? 0 : 55,
                        }}
                      >
                        <span className="text-sm text-cardGrayColor leading-[16.1px]">
                          €{range[0]}
                        </span>
                      </div>

                      <div
                        className="absolute flex justify-center items-center h-[22px] w-[46px] py-[3px] px-1 rounded-[5px] border-1 border-solid border-mainPink"
                        style={{
                          left: calculateTooltipPosition(range[1], 0, 1000),
                          top: 0,
                        }}
                      >
                        <span className="text-sm text-cardGrayColor leading-[16.1px]">
                          €{range[1]}
                        </span>
                      </div>

                      <RangeSlider
                        onInput={(e) => setRange(e)}
                        defaultValue={[0, 1000]}
                        min={0}
                        max={1000}
                        step={50}
                      />
                    </div>

                    <div className="rate">
                      <div className="flex items-center gap-2.5 mb-[17px]">
                        <Image
                          src={rateGrayStar}
                          className="w-[18px] h-[18px]"
                          alt="Rate"
                        />
                        <h3 className="text-cardGrayColor text-sm font-bold">
                          Rating
                        </h3>
                      </div>
                      <div className="rate-box flex items-center gap-2.5 flex-wrap">
                        {[1, 2, 3, 4, 5].map((value) => (
                          <button
                            key={value}
                            onClick={() => toggleRate(value)}
                            className={`rounded-4xl gradiantBorder smallBorder no-focus transition-all duration-300`}
                          >
                            <div
                              className={`flex justify-center gap-0.5 items-center rounded-4xl bg-white transition-all duration-300 size-full py-2 px-2.5 ${
                                rates.includes(value)
                                  ? "bg-mainGradiant"
                                  : "bg-white"
                              }`}
                            >
                              {Array.from({ length: value }, (_, index) => (
                                <Image
                                  key={index}
                                  src={
                                    rates.includes(value)
                                      ? nonActiveStar
                                      : activeStar
                                  }
                                  className="w-[12px] h-[12px]"
                                  alt={`${value} star`}
                                />
                              ))}
                            </div>
                          </button>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center gap-5">
                <button
                  onClick={closeModal}
                  className="flex justify-center items-center bg-white gradiantBorder no-focus smallBorder rounded-4xl py-3 px-5 w-[90px] h-[42px]"
                >
                  <span className="bg-clip-text bg-mainGradiant text-transparent text-base leading-[18.4px]">
                    Cancel
                  </span>
                </button>
                <button
                  onClick={closeModal}
                  className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-4xl py-3 px-5 w-[110px] h-[42px]"
                >
                  Apply
                </button>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DiscoverFilter;
