import { Input } from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import emailIcon from "../../Assets/auth/sms.svg";
import passIcon from "../../Assets/auth/lock.svg";
import userIcon from "../../Assets/auth/user-square.svg";
import visibleEye from "../../Assets/auth/eye.svg";
import hiddenEye from "../../Assets/auth/eye-slash.svg";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useClerk, useSignIn, useSignUp } from "@clerk/clerk-react";
import { AuthContext } from "Context/AuthContext";
import { OAuthStrategy } from "@clerk/types";
import PrivacyPolicy from "components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "components/TermsOfService/TermsOfService";

const Register = () => {
  const socialIcons = require.context("../../Assets/auth/social", true);
  const socialIconsList = socialIcons.keys().map((image) => socialIcons(image));

  const authInfo = [
    {
      method: "oauth_google",
      link: "https://google.com",
    },
    {
      method: "oauth_facebook",
      link: "https://facebook.com",
    },
    // {
    //     method: "oauth_x",
    //     link: "/",
    // },
    {
      method: "oauth_apple",
      link: "/",
    },
  ];

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const showPolicy = () => {
    setIsModalOpen(true);
  };

  const showTerms = () => {
    setIsTermsModalOpen(true);
  };

  //integration part

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();

  const { signIn } = useSignIn();
  const { signUp, setActive } = useSignUp();

  const {
    setIsVerified,
    setRegisterFname,
    setRegisterLname,
    setRegisterEmail,
    setRegisterPass,
  } = useContext(AuthContext);

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z()]+((\s|-|_)[A-Za-z\(\)]+)*$/gi, "Invalid name")
      .min(3, "Name is too short")
      .max(60, "Name is too long")
      .required("Name required"),
    email: Yup.string().email("Invalid email").required("Email required"),
    password: Yup.string()
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~-])/,
        "Password must contain small, capital, number and special character"
      )
      .min(8, "Weak password")
      .max(30, "Password is too long")
      .required("Password required"),
  });

  const registerForm = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema,
    onSubmit: callRegister,
  });

  const { session } = useClerk();

  async function callRegister(reqBody) {
    if (session) {
      session?.end();
    }
    setErrorMessage("");
    setIsLoading(true);
    setIsDisabled(true);
    const first_name = reqBody.name.split(/[\s-_]+/)[0];
    const last_name =
      reqBody.name.split(" ").length >= 2
        ? reqBody.name.split(/[\s-_]+/)[1]
        : " ";

    try {
      await signUp.create({
        first_name,
        last_name,
        emailAddress: reqBody.email,
        password: reqBody.password,
      });

      // Send the user an email with the verification code
      await signUp.prepareEmailAddressVerification({
        strategy: "email_code",
      });
      setIsVerified(false);
      setRegisterFname(first_name);
      setRegisterLname(last_name);
      setRegisterEmail(reqBody.email);
      setRegisterPass(reqBody.password);
      navigate("/verify-password");
    } catch (error) {
      setErrorMessage(error.errors ? error.errors[0].message : error.message);
      setIsLoading(false);
    }
    setIsDisabled(false);
  }

  // OAuth

  const signInWith = (strategy, link) => {
    return signUp.authenticateWithRedirect({
      strategy,
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/loading",
    });
  };

  async function handleSignIn(strategy, link) {
    if (!signIn || !signUp) return null;

    // If the user has an account in your application, but does not yet
    // have an OAuth account connected to it, you can transfer the OAuth
    // account to the existing user account.
    const userExistsButNeedsToSignIn =
      signUp.verifications.externalAccount.status === "transferable" &&
      signUp.verifications.externalAccount.error?.code ===
        "external_account_exists";

    if (userExistsButNeedsToSignIn) {
      const res = await signIn.create({ transfer: true });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
        console.log("success");
      }
    }

    // If the user has an OAuth account but does not yet
    // have an account in your app, you can create an account
    // for them using the OAuth information.
    const userNeedsToBeCreated =
      signIn.firstFactorVerification.status === "transferable";

    if (userNeedsToBeCreated) {
      const res = await signUp.create({
        transfer: true,
      });

      if (res.status === "complete") {
        setActive({
          session: res.createdSessionId,
        });
      }
    } else {
      // If the user has an account in your application
      // and has an OAuth account connected to it, you can sign them in.
      signInWith(strategy, link);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="signup flex justify-center items-start relative min-h-[calc(100vh-85px)]">
      <div className="absolute bottom-0 left-0 sm:w-[450px] w-[80%] h-[412px] bg-authImg1 bg-cover bg-no-repeat z-0"></div>
      <div className="absolute top-[30px] right-0 w-[214px] h-[287px] bg-authImg2 bg-cover bg-no-repeat z-0"></div>
      <div className="container xl:px-44 sm:px-6 px-4 mx-auto relative z-10">
        <div className="box sm:mx-auto sm:w-[395px] w-auto shadow-authShadow rounded-20px flex flex-col justify-center sm:p-10 p-5 relative z-20 bg-white mb-[85px]">
          <h2 className="font-bold text-[20.72px] mb-6 text-center">
            Seconds to join our family
          </h2>
          <div className="signup-form mb-9">
            {errorMessage && (
              <div className="text-white bg-cardColor py-3 mb-10 text-center rounded-15px text-sm">
                <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
                  {errorMessage}
                </span>
              </div>
            )}
            <form onSubmit={registerForm.handleSubmit}>
              <div className="mb-12">
                <Input
                  name="name"
                  type="text"
                  label="Full Name"
                  variant="bordered"
                  placeholder="Adam Scott"
                  labelPlacement="outside"
                  startContent={<img src={userIcon} alt="user icon" />}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={registerForm.handleChange}
                  onBlur={registerForm.handleBlur}
                  value={registerForm.values.name}
                  isInvalid={registerForm.errors.name}
                  errorMessage={
                    registerForm.errors.name && registerForm.touched.name
                      ? registerForm.errors.name
                      : null
                  }
                />
              </div>
              <div className="mb-12">
                <Input
                  name="email"
                  type="email"
                  label="Email"
                  variant="bordered"
                  placeholder="example@mail.com"
                  labelPlacement="outside"
                  startContent={<img src={emailIcon} alt="email icon" />}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={registerForm.handleChange}
                  onBlur={registerForm.handleBlur}
                  value={registerForm.values.email}
                  isInvalid={registerForm.errors.email}
                  errorMessage={
                    registerForm.errors.email && registerForm.touched.email
                      ? registerForm.errors.email
                      : null
                  }
                />
              </div>
              <div className="mb-10">
                <Input
                  name="password"
                  label="Password"
                  variant="bordered"
                  labelPlacement="outside"
                  placeholder="Minimum 8 characters"
                  startContent={<img src={passIcon} alt="password icon" />}
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                    >
                      {isVisible ? (
                        <img src={hiddenEye} alt="eye icon" />
                      ) : (
                        <img src={visibleEye} alt="hidden eye icon" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                  classNames={{
                    label: "text-[13.81px] font-normal opacity-75 mt-1",
                    input: "border-none py-[10.36px]",
                  }}
                  onChange={registerForm.handleChange}
                  onBlur={registerForm.handleBlur}
                  value={registerForm.values.password}
                  isInvalid={registerForm.errors.password}
                  errorMessage={
                    registerForm.errors.password &&
                    registerForm.touched.password
                      ? registerForm.errors.password
                      : null
                  }
                />
              </div>
              <button
                type="submit"
                disabled={
                  !(registerForm.isValid && registerForm.dirty) || isDisabled
                }
                className="w-full bg-mainGradiant h-[44px] text-white text-[15.54px] font-bold rounded-[12.95px]"
              >
                {isLoading ? (
                  <span className="flex gap-2 justify-center items-center">
                    <i className="fa-solid fa-spinner fa-spin text-white"></i>
                  </span>
                ) : (
                  "Start your plans"
                )}
              </button>
            </form>
          </div>
          <div className="policy mb-8">
            <p className="text-center text-[13.81px]">
              By signing up, I accept
              <div className="inline">
                <button
                  onClick={showTerms}
                  className="mx-1 bg-clip-text text-transparent bg-mainGradiant font-bold"
                >
                  Terms Of Service
                </button>
                <TermsOfService
                  isOpen={isTermsModalOpen}
                  onClose={() => setIsTermsModalOpen(false)}
                />
              </div>
              and acknowledge the
              <div className="inline">
                <button
                  onClick={showPolicy}
                  className="ms-1 bg-clip-text text-transparent bg-mainGradiant font-bold"
                >
                  Privacy Policy
                </button>
                <PrivacyPolicy
                  isOpen={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                />
              </div>
            </p>
          </div>
          <div className="continue text-center flex gap-8 items-center justify-center mb-8">
            <span className="bg-cardGrayColor h-[0.86px] flex-grow"></span>
            <span className="text-cardGrayColor text-[13.81px]">
              Or continue with
            </span>
            <span className="bg-cardGrayColor h-[0.86px] flex-grow"></span>
          </div>
          <div className="social flex sm:flex-nowrap flex-wrap items-center justify-center gap-4 mb-8">
            {socialIconsList.map((icon, idx) => (
              <button
                className="w-[66px] h-[66px]"
                onClick={() => handleSignIn(authInfo[idx].method)}
                key={idx}
              >
                <div className="icon flex justify-center items-center p-[17.26px] rounded-[8.63px] bg-socialIconBg">
                  <img src={icon} alt="Social" />
                </div>
              </button>
            ))}
          </div>
          <div className="sign-up text-center text-[13.81px]">
            Already have an account?
            <Link
              to={"/login"}
              className="ms-1 bg-clip-text text-transparent bg-mainGradiant relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-px after:bg-mainGradiant"
            >
              Log In
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
