import { Error500Context } from "Context/Error500Context";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const ProtectedError = (props) => {
  const { isServerError } = useContext(Error500Context);
  if (isServerError) {
    return props.children;
  } else {
    return <Navigate to={"/"}></Navigate>;
  }
};

export default ProtectedError;
