import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  Radio,
  RadioGroup,
} from "@nextui-org/react";
import { FilterContext } from "Context/FilterContext";
import React, { useContext, useEffect, useState } from "react";
import "react-range-slider-input/dist/style.css";
import checkIcon from "../../Assets/filter/check-icon.svg";
import { Controller, useForm } from "react-hook-form";
import { WithContext as ReactTags } from "react-tag-input";
import RemoveComponent from "../../pages/CreateAFrond/RemoveComponent";

const FilterModal = ({ isOpen, onClose, page }) => {
  const {
    frondFilterCategory,
    setFrondFilterCategory,
    sharedFilterCategory,
    setSharedFilterCategory,
    archiveFilterCategory,
    setArchiveFilterCategory,
    frondFilterTags,
    setFrondFilterTags,
    sharedFilterTags,
    setSharedFilterTags,
    archiveFilterTags,
    setArchiveFilterTags,
  } = useContext(FilterContext);

  const toggleCategories = (value) => {
    if (page === "my-fronds") {
      if (frondFilterCategory.includes(value)) {
        setFrondFilterCategory(
          frondFilterCategory.filter((category) => category !== value)
        );
      } else {
        setFrondFilterCategory([...frondFilterCategory, value]);
      }
    } else if (page === "archive-fronds") {
      if (archiveFilterCategory.includes(value)) {
        setArchiveFilterCategory(
          archiveFilterCategory.filter((category) => category !== value)
        );
      } else {
        setArchiveFilterCategory([...archiveFilterCategory, value]);
      }
    } else if (page === "shared-fronds") {
      if (sharedFilterCategory.includes(value)) {
        setSharedFilterCategory(
          sharedFilterCategory.filter((category) => category !== value)
        );
      } else {
        setSharedFilterCategory([...sharedFilterCategory, value]);
      }
    }
  };

  const categories = ["Home", "Ventures", "Events", "Escapes", "Others"];

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tags: [],
    },
  });

  //Tags

  const [tags, setTags] = useState([]);
  const [tagError, setTagError] = useState("");
  const [tagLimit, setTagLimit] = useState(false);

  useEffect(() => {
    setValue("tags", tags);

    if (tags.length === 10) {
      setTagLimit(true);

      setTimeout(() => {
        setTagLimit(false);
      }, 1500);
    }
  }, [tags, setValue]);

  const handleValidation = (tags) => {
    for (let tag of tags) {
      if (tag.text.length < 2 || tag.text.length > 60) {
        return "Each tag must be between 2 and 60 characters";
      }
      if (
        !/^[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=\/\\]+(\s[A-Za-z0-9\-\/\\\_\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+)*$/gi.test(
          tag.text
        )
      ) {
        return "Tag may contain characters, numbers or special characters and shouldn't end with space";
      }
    }
    return true;
  };

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    setTags(newTags);
    setValue("tags", newTags);

    if (page === "my-fronds") {
      setFrondFilterTags(newTags);
    } else if (page === "archive-fronds") {
      setArchiveFilterTags(newTags);
    } else if (page === "shared-fronds") {
      setSharedFilterTags(newTags);
    }

    const validation = handleValidation(newTags);
    if (validation === true) {
      setTagError("");
    } else {
      setTagError(validation);
      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  const handleAddition = (tag) => {
    const newTags = [...tags, tag];
    const validation = handleValidation(newTags);

    if (validation === true) {
      setTags(newTags);
      setValue("tags", newTags);
      setTagError("");

      if (page === "my-fronds") {
        setFrondFilterTags(newTags);
      } else if (page === "archive-fronds") {
        setArchiveFilterTags(newTags);
      } else if (page === "shared-fronds") {
        setSharedFilterTags(newTags);
      }
    } else {
      setTagError(validation);

      setTimeout(() => {
        setTagError("");
      }, 1500);
    }
  };

  useEffect(() => {
    if (page === "my-fronds") {
      setTags(frondFilterTags);
      setValue("tags", frondFilterTags);
    } else if (page === "archive-fronds") {
      setTags(archiveFilterTags);
      setValue("tags", archiveFilterTags);
    } else if (page === "shared-fronds") {
      setTags(sharedFilterTags);
      setValue("tags", sharedFilterTags);
    }
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] sort-modal",
        footer: "justify-center items-center pb-2",
        body: "border-b-1 border-solid border-grayBorder p-0",
        closeButton: "hidden",
        base: "rounded-[10px]",
      }}
    >
      <ModalContent>
        {(closeModal) => (
          <>
            <ModalBody className="overflow-auto">
              <div className="filter sm:w-auto w-full py-[15px]">
                <h3 className="text-base text-blueTimeline font-bold text-center leading-[18px] pb-2.5 mb-2.5 border-b-1 border-solid border-grayBorder">
                  Filter by
                </h3>
                <div className="flex flex-col gap-2.5 mx-5 pb-2.5 mb-2.5 border-b-1 border-solid border-grayBorder">
                  <h4 className="text-sm leading-[16.1px] text-cardGrayColor font-bold">
                    Category
                  </h4>
                  {categories.map((item, idx) => (
                    <button
                      key={idx}
                      onClick={() => {
                        toggleCategories(item);
                      }}
                      className={"flex items-center gap-2.5 w-fit"}
                    >
                      <div className="w-[18px] h-[18px] relative">
                        <div
                          className={`absolute inset-0 w-[18px] h-[18px] rounded-md flex justify-center items-center transition-all duration-300 bg-red-200 ${
                            page === "my-fronds"
                              ? frondFilterCategory.includes(item)
                                ? "opacity-100"
                                : "opacity-0"
                              : page === "archive-fronds"
                              ? archiveFilterCategory.includes(item)
                                ? "opacity-100"
                                : "opacity-0"
                              : page === "shared-fronds"
                              ? sharedFilterCategory.includes(item)
                                ? "opacity-100"
                                : "opacity-0"
                              : ""
                          }`}
                        >
                          <Image
                            src={checkIcon}
                            className="w-[10.81px] h-[7.21px]"
                            alt="Check"
                          />
                        </div>
                        <div
                          className={`absolute inset-0 w-[18px] h-[18px] rounded-md flex justify-center items-center transition-all duration-300 bg-white border-2 border-solid border-cardGrayColor ${
                            page === "my-fronds"
                              ? !frondFilterCategory.includes(item)
                                ? "opacity-100"
                                : "opacity-0"
                              : page === "archive-fronds"
                              ? !archiveFilterCategory.includes(item)
                                ? "opacity-100"
                                : "opacity-0"
                              : page === "shared-fronds"
                              ? !sharedFilterCategory.includes(item)
                                ? "opacity-100"
                                : "opacity-0"
                              : ""
                          }`}
                        ></div>
                      </div>

                      <h5 className="text-cardGrayColor text-sm leading-[16.1px]">
                        {item}
                      </h5>
                    </button>
                  ))}
                </div>
                <div className="tags filter-tags mx-5">
                  <Controller
                    name="tags"
                    control={control}
                    rules={{ validate: handleValidation }}
                    render={({ field }) => (
                      <div>
                        <label
                          className={`block ${
                            // || tagLimit (if there are tag limit enter that after tagError)
                            tagError ? "text-[#f31260]" : "text-cardGrayColor"
                          } smallPhone:text-sm text-[12px] font-bold mb-2 transition-all duration-400`}
                          htmlFor="tags"
                        >
                          Tags
                        </label>
                        <ReactTags
                          autoFocus={false}
                          {...field}
                          id="tags"
                          tags={tags}
                          handleDelete={handleDelete}
                          handleAddition={handleAddition}
                          allowDragDrop={false}
                          inputFieldPosition="top"
                          separators={["Enter", "Comma"]}
                          placeholder="Filter by tags..."
                          // maxTags={10}
                          removeComponent={RemoveComponent}
                          classNames={{
                            // || tagLimit (if there are tag limit enter that after tagError)
                            tagInputField: `tagInputField bg-white border-[0.86px] w-full px-[18px] border-solid ${
                              tagError ? "invalid-text" : "border-[#E5E4EB]"
                            } rounded-[8px] h-[35px] placeholder:smallPhone:text-sm placeholder:smallPhone:text-[10px] placeholder:font-normal smallPhone:text-sm text-[10px] font-normal transition-all duration-400 no-focus`,
                            tag: `px-2 py-1 break-all bg-mainGradiant rounded-[20px] inline-flex text-white smallPhone:text-xs text-[10px] items-center justify-center gap-1.5 mt-2 me-2
                    `,
                            editTagInput: "flex",
                          }}
                        />
                        {tagError && (
                          <p
                            className={`text-[#f31260] text-xs mt-0.5 transition-opacity duration-400 ${
                              tagError ? "opacity-100" : "opacity-0"
                            }`}
                          >
                            {tagError}
                          </p>
                        )}
                        {/* {tagLimit && (
                <p
                  className={`text-[#f31260] text-xs mt-1 transition-opacity duration-400 ${
                    tagLimit ? "opacity-100" : "opacity-0"
                  }`}
                >
                  Tag limit reached
                </p>
              )} */}
                      </div>
                    )}
                  />
                </div>
              </div>
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default FilterModal;
