import React, { useEffect } from "react";
import CardsTemplate from "./CardsTemplate";

const Events = () => {
  const cardsImgs = require.context("../../Assets/template/events", true);
  const cardsImgsList = cardsImgs.keys().map((image) => cardsImgs(image));
  const cardOne = {
    title:
      "Are you or someone you know planning their wedding and want to ensure that everything comes together perfectly for the special day?",
    content: [
      {
        desc: "Are you juggling your time between looking at venues, guest lists, sorting out the caterer, photographer and make up artist, and not enough time to looking for the perfect dress?",
      },
      {
        desc: "Trying to coordinate all of this can quickly become overwhelming and time consuming, unless of course you have the budget to get a wedding planner, and let’s face it no one knows what you want better than you do",
      },
    ],
    img: cardsImgsList[0],
  };
  const cardTwo = {
    title: "Planning a party shouldn't be a party pooper",
    content: [
      {
        desc: "Sandra, a busy professional, knows the importance of planning. She's turning 40 and wants to throw an epic bash with friends and family before their summer getaway.",
      },
      {
        space: true,
        desc: "But who has time to juggle spreadsheets and endless emails?",
      },
      {
        space: true,
        desc: "Frond helps busy bees like Sandra with:",
      },
      {
        space: true,
        info: "Effortless organisation",
        desc: "Create a detailed event plan, manage guest lists, track RSVPs, and stay on top of everything – all in one place",
      },
      {
        space: true,
        info: "Collaboration made easy",
        desc: "Share planning tasks with friends and family to delegate responsibilities, ensuring a seamless party experience",
      },
      {
        info: "Focus on the fun",
        desc: "Ditch the stress and enjoy celebrating! Frond empowers you to be a guest at your own party",
      },
    ],
    img: cardsImgsList[1],
  };
  const cardThree = {
    title: "Frond brings everyone together",
    content: [
      {
        space: true,
        info: "Effortless sharing",
        desc: "Ditch the email chains! Share your plans, updates, and RSVPs with friends, and family – all in one centralised location",
      },
      {
        space: true,
        info: "Real-time updates",
        desc: "No more information black holes. Everyone has instant access to the latest details, ensuring everyone's on the same page",
      },
      {
        info: "Crystal clear communication",
        desc: "Frond eliminates confusion and keeps everyone informed, guaranteeing a smooth and stress-free planning process for all your celebrations",
      },
    ],
    img: cardsImgsList[2],
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <CardsTemplate
      secName="events"
      mainTitle={"Turn event planning chaos into organised joy with frond"}
      cardOne={cardOne}
      cardTwo={cardTwo}
      cardThree={cardThree}
      subTitle={
        "Say goodbye to email ping-pong! Share your party plans effortlessly with frond"
      }
    />
  );
};

export default Events;
