import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Image,
  ModalFooter,
} from "@nextui-org/react";
import closeBtn from "../../Assets/landing/closeBtn.svg";
import { Link } from "react-router-dom";

const TermsOfService = ({ isOpen, onClose }) => {
  return (
    <Modal
      backdrop="opaque"
      isOpen={isOpen}
      onOpenChange={onClose}
      scrollBehavior="inside"
      placement="center"
      className="terms-modal"
      size="3xl"
      motionProps={{
        variants: {
          enter: {
            y: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: "easeOut",
            },
          },
          exit: {
            y: -20,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        },
      }}
      classNames={{
        header: "text-center font-bold text-xl text-black p-0",
        body: "p-0",
        closeButton: "hidden",
        base: "rounded-4xl max-h-[75vh] mx-2 rights-popUp",
      }}
    >
      <ModalContent>
        <ModalHeader className="flex justify-center items-center relative shadow-privacyShadow py-5 px-5">
          <button onClick={onClose} className="absolute left-5 top-5">
            <Image src={closeBtn} alt="X" />
          </button>
          <span>Terms & Conditions</span>
        </ModalHeader>
        <ModalBody className="text-black px-12 py-[34px]">
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">These Terms</h3>
            <p className="text-base opacity-75 mb-4">
              • What these terms cover. These are the terms and conditions on
              which we supply services to you. These terms and conditions are a
              legal agreement between You and Plannit Limited (trading as
              “Frond”), for use of the Frond service (the “Service”). “You”
              refers to any individual who creates an account on the Service,
              or, if the Service is being used on behalf of an entity by an
              individual authorised to agree to such terms on behalf of such
              entity, then “You” refers to such entity. If You are accessing the
              Service on behalf of Your employer, you represent and warrant that
              You have the authority to agree to these terms and conditions on
              its behalf. If You do not agree with the terms of this Agreement,
              do not use the Service. Frond reserves the right to update and
              change the Agreement from time to time without notice or
              acceptance by You. The Agreement will also be applicable to the
              use of the Service on a trial basis. By using the Service, You
              signify Your irrevocable acceptance of this Agreement.
            </p>
            <p className="text-base opacity-75">
              • Why you should read them. Please read these terms carefully
              before you create an account and start using the Service. These
              terms tell you who we are, how we will provide products to you,
              how you and we may change or end the contract, what to do if there
              is a problem and other important information. If you think that
              there is a mistake in these terms, please contact us to discuss.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              Information about us and how to contact us
            </h3>
            <p className="text-base opacity-75 mb-4">
              • Who we are. We are Plannit Limited trading as Frond, a company
              registered in England and Wales. Our company registration number
              is 10793986 and our registered office is at Kemp House, 152-160
              City Road, LONDON, EC1V 2NX, United Kingdom. Our registered VAT
              number is 281 4276 00.
            </p>
            <p className="text-base opacity-75 mb-4">
              • How to contact us. You can contact us at info@thefrondapp.com
              and by mail to Kemp House, 152-160 City Road, LONDON, EC1V 2NX,
              United Kingdom.
            </p>
            <p className="text-base opacity-75">
              • How we may contact you. If we have to contact you we will do so
              by at the email address you provided to us.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Our Services</h3>
            <p className="text-base opacity-75 mb-4">
              • The Service includes, and is limited to, a service, website, or
              mobile application that allows You access to and use of a single
              Frond account. “Frond” means an online collaboration tool that
              organises projects on the Frond app. The Service may contain
              information, data, text, photographs, videos, audio clips, written
              posts and comments, software, scripts, graphics, and interactive
              features generated, provided, or otherwise made accessible on or
              through the Service (collectively, “Content”). Subject to these
              Terms of Service, Frond grants to You and each user of the Service
              a worldwide, non-exclusive, non-transferable license to use (i.e.,
              to download and display locally) Content solely for purposes of
              using the Service. Use, reproduction, modification, distribution
              or storage of any Content for other than purposes of using the
              Service is expressly prohibited without prior written permission
              from us. You shall not sell, license, rent, or otherwise use or
              exploit any Content for commercial use or in any way that violates
              any third party right.
            </p>
            <p className="text-base opacity-75">
              • Frond may at its sole discretion modify the features of the
              Service from time to time without prior notice. You agree that
              Frond shall not be liable to You or to any third party for any
              modification, suspension, termination or discontinuance of the
              Service.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Eligibility</h3>
            <p className="text-base opacity-75">
              • You must be at least 12 years of age to use this Service. You
              must provide your current, accurate identification, contact, and
              other information that may be required as part of the registration
              process and/or continued use of the Service. You are responsible
              for maintaining the confidentiality of your Service password and
              account, and are responsible for all activities that occur
              thereunder. Frond reserves the right to refuse service to You or
              anyone else at any time without notice for any reason.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Registration</h3>
            <p className="text-base opacity-75 mb-4">
              • In order to use the Service, You must sign-up to Frond. To
              acquire an account for the Service, You must provide Frond with an
              email address and various other pieces of information. You are
              responsible for maintaining the confidentiality of the username
              and password for your account, and are fully responsible for all
              activities that occur within your account.
            </p>
            <p className="text-base opacity-75">
              • You agree to notify Frond of any unauthorised use of your
              account or any other breach of security, and ensure that You
              sign-out from your account at the end of each session. Frond will
              not be liable for any loss or damage arising from any failure by
              You to sign-out of your Frond account or for any damage that You
              may suffer as a result of a third party accessing the internet.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Intellectual Property</h3>
            <p className="text-base opacity-75 mb-4">
              • All intellectual property rights and all other rights, including
              but not limited to all patents, rights to inventions, copyright
              and related rights, trade marks, trade names, domain names,
              database rights, rights in get-up, rights in goodwill or to sue
              for passing off, unfair competition rights, rights in designs,
              rights in computer software, database rights, topography rights,
              moral rights, rights in confidential information (including
              without limitation know-how and trade secrets) and any other
              intellectual property rights, in each case whether registered or
              unregistered, and including without limitation all applications
              for, and renewals or extensions of, such rights, and all similar
              or equivalent rights or forms of protection in any part of the
              world in the Service (the “Intellectual Property Rights”) shall be
              owned by Frond.
            </p>
            <p className="text-base opacity-75 mb-4">
              • You may not reverse engineer, reverse compile or otherwise
              reduce to human readable form any software associated with the
              Service.
            </p>
            <p className="text-base opacity-75">
              • You shall use reasonable endeavours to prevent any infringement
              of Frond’s Intellectual Property Rights in the Service and shall
              promptly report to Frond any such infringement that comes to your
              attention.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              Termination and Suspension
            </h3>
            <p className="text-base opacity-75 mb-4">
              • Frond may, at its sole discretion, at any time and for any
              reason, terminate the Service, terminate this Agreement, or
              suspend or terminate Your Frond account. In the event of
              suspension or termination, Your account will be disabled and You
              may not be granted access to Your account or any files or other
              Content contained in Your account, and Frond may delete Your user
              content, although residual copies of information may remain in our
              system for some time for back-up purposes.
            </p>
            <p className="text-base opacity-75 mb-4">
              • Once your account is cancelled all of your Content will be
              immediately deleted from the Service. Since deletion of all data
              is final please be sure that you do in fact want to cancel your
              account before doing so.
            </p>
            <p className="text-base opacity-75">
              • If You terminate Your Account, via means provided for
              cancellation on the Frond app or website, or via email to Frond,
              and You request that Frond delete the Content and files contained
              in Your Account, Frond will make all reasonable efforts to do so.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Proper Use</h3>
            <p className="text-base opacity-75 mb-4">
              • You agree that you will use the Service in compliance with all
              applicable national and international laws, rules and regulations,
              including any laws regarding the transmission of technical data
              exported from your country of residence.
            </p>
            <p className="text-base opacity-75 mb-4">
              • You shall not, shall not agree to, and shall not authorise or
              encourage any third party to: use the Service to upload, transmit
              or otherwise distribute any content that is unlawful, defamatory,
              harassing, abusive, fraudulent, obscene, contains viruses, or is
              otherwise objectionable as reasonably determined by Frond; use the
              Service for any fraudulent or inappropriate purpose; resell,
              duplicate, reproduce or exploit any part of the Service without
              the express written permission of Frond; use any robot, spider,
              other automated device, or manual process to monitor or copy any
              content from the Service. Violation of any of the foregoing may
              result in immediate termination of this Agreement.
            </p>
            <p className="text-base opacity-75 mb-4">
              • Frond does not claim ownership over any user content submitted
              on or through the Service. Your user content belongs to You.
              However, by uploading any user content to the Service, You agree
              that Frond may store and display (only to You, to the extent that
              You make such user content private) your user content.
            </p>
            <p className="text-base opacity-75 mb-4">
              • You understand that by using the Service, You may be exposed to
              user content that is offensive, indecent or objectionable. Under
              no circumstances will Frond be liable in any way for any user
              content, including, but not limited to, for any errors or
              omissions in any Content, or for any loss or damage of any kind
              incurred as a result of the use of any Content posted, transmitted
              or otherwise made available via the Service.
            </p>
            <p className="text-base opacity-75">
              • You agree that Frond has no responsibility or liability for the
              deletion of, or the failure to store or to transmit, any user
              content and other communications maintained by the Service. You
              acknowledge that Frond may establish general practices and limits
              concerning use of the Service and may modify such practices and
              limits from time to time.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              If there is a problem with the Service
            </h3>
            <p className="text-base opacity-75">
              • If you have any questions or complaints about the Service,
              please contact us. You can write to us at
              <Link to={"mailto:info@thefrondapp.com"} className="ms-1">
                info@thefrondapp.com
              </Link>
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              How we may use your Personal Information
            </h3>
            <p className="text-base opacity-75">
              • How we may use your personal information. We will only use your
              personal information as set out in our privacy policy.[BP2]
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">
              Representations and Warranties
            </h3>
            <p className="text-base opacity-75 mb-4">
              • You represent and warrant that (a) all of the information
              provided by You to Frond to participate in the Service is correct
              and current; and (b) You have all necessary right, power and
              authority to enter into this Agreement and to perform the acts
              required of You hereunder.
            </p>
            <p className="text-base opacity-75 mb-4">
              • You understand and agree that the Service is provided “as is”
              and the entire risk arising out of use, security or performance of
              the Service remains with You. All conditions, representations and
              warranties, whether express, implied, statutory or otherwise,
              including, without limitation, any implied warranty of
              merchantability, fitness for a particular purpose, or
              non-infringement of third party rights, are hereby disclaimed to
              the maximum extent permitted by applicable law.
            </p>
            <p className="text-base opacity-75">
              • You expressly understand and agree that Frond shall not be
              liable for any direct, indirect, incidental, special,
              consequential or exemplary damages, including but not limited to,
              damages for loss of profits, goodwill, use, data or other
              intangible losses (even if Frond has been advised of the
              possibility of such damages), resulting from your usage of the
              Service.
            </p>
          </div>
          <div className="mb-5">
            <h3 className="font-bold text-xl mb-5">Other important Terms</h3>
            <p className="text-base opacity-75 mb-4">
              • We may transfer this agreement to someone else. We may transfer
              our rights and obligations under these terms to another
              organisation. We will always tell you in writing if this happens
              and we will ensure that the transfer will not affect Your rights
              under the contract.
            </p>
            <p className="text-base opacity-75 mb-4">
              • You need our consent to transfer your rights to someone else.
              You may only transfer your rights or your obligations under these
              terms to another person if we agree to this in writing.
            </p>
            <p className="text-base opacity-75 mb-4">
              • Nobody else has any rights under this contract (except someone
              you pass your guarantee on to). This contract is between You and
              Frond. No other person shall have any rights to enforce any of its
              terms.
            </p>
            <p className="text-base opacity-75 mb-4">
              • If a court finds part of this contract illegal, the rest will
              continue in force. Each of the paragraphs of these terms operates
              separately. If any court or relevant authority decides that any of
              them are unlawful, the remaining paragraphs will remain in full
              force and effect.
            </p>
            <p className="text-base opacity-75 mb-4">
              • Even if Frond delays in enforcing this contract, Frond can still
              enforce it later. If Frond do not insist immediately that You do
              anything You are required to do under these terms, or if we delay
              in taking steps against You in respect of your breaking this
              Agreement, that will not mean that You do not have to do those
              things and it will not prevent us taking steps against You at a
              later date.
            </p>
            <p className="text-base opacity-75">
              • Which laws apply to this contract and where you may bring legal
              proceedings. These terms are governed by English law and You can
              bring legal proceedings in respect of the products in the English
              courts.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="shadow-flipPrivacyShadow min-h-[45px] max-h-[45px]"></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TermsOfService;
