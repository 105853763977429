import React, { useEffect, useState } from "react";
import { Image, Input } from "@nextui-org/react";
import { useClerk, useUser } from "@clerk/clerk-react";
import locationIcon from "../../Assets/community/geolocation.svg";
import imgIcon from "../../Assets/community/picture.svg";

export default function AddPost() {
  const [userImg, setUserImg] = useState(null);

  const { session } = useClerk();
  const isSignedIn = localStorage.getItem("token");
  const clerkUser = useUser();

  const getData = () => {
    if (session?.user && isSignedIn) {
      setUserImg(clerkUser.user.imageUrl);
    }
  };

  useEffect(() => {
    getData();
  }, [clerkUser.user]);

  return (
    <div className="bg-white rounded-4xl border-2 border-solid border-grayBorder p-5 w-full no-focus">
      <div className="post flex items-center gap-5 mb-5">
        <Image
          src={userImg}
          className="min-w-[51px] max-w-[51px] min-h-[51px] max-h-[51px] rounded-full"
          alt="userImg"
        />
        <Input
          type="text"
          placeholder="Whats on your mind?"
          classNames={{
            base: "h-[51px] add-post-input",
            input: "border-none p-0",
            inputWrapper:
              "px-[20px] py-2.5 bg-[#F5F6FF] rounded-[40px] in-wrap h-full",
            mainWrapper: "bg-[#F5F6FF] rounded-[40px]",
          }}
        />
      </div>
      <div className="flex items-center justify-center">
        <button className="flex items-center justify-center gap-[15px] w-1/3 border-r-2 border-solid border-grayBorder">
          <Image
            src={locationIcon}
            alt="Location"
            className="w-[24px] h-[24px]"
          />
          <h4 className="text-base font-bold text-blueTimeline leading-[18.4px]">
            Location
          </h4>
        </button>
        <button className="flex items-center justify-center gap-[15px] w-1/3">
          <Image src={imgIcon} alt="Image" className="w-[24px] h-[24px]" />
          <h4 className="text-base font-bold text-blueTimeline leading-[18.4px]">
            Image
          </h4>
        </button>
      </div>
    </div>
  );
}
