import { Image } from "@nextui-org/react";
import React from "react";

const CardsTemplate = ({
  secName,
  mainTitle,
  cardOne,
  cardTwo,
  cardThree,
  subTitle,
}) => {
  return (
    <section className={`${secName} relative`}>
      <div className="intro bg-mainGradiant py-24">
        <h2 className="text-[45px] leading-[63px] font-bold text-white text-center lg:w-1/2 md:w-3/4 mx-auto px-4">
          {mainTitle}
        </h2>
      </div>
      <div className="content py-12">
        <div className="absolute left-0 top-[29%] sm:w-[367px] w-[80%] h-[362px] z-10 bg-templateBuble"></div>
        <div className="absolute left-0 bottom-0 sm:w-[344px] w-[80%] h-[384.17px] z-10 bg-templateOrangeBuble"></div>
        <div className="absolute right-0 bottom-[20%] sm:w-[260px] w-[80%] h-[348px] z-10 bg-templatePrint"></div>
        <div className="container xl:px-20 md:px-3 px-6 mx-auto relative z-20">
          <div className="card bg-white rounded-4xl border-2 border-solid border-cardColor py-[30px] px-[50px] shadow-mainShadow mb-10">
            <div className="flex lg:flex-nowrap flex-wrap gap-8 items-center lg:justify-start justify-center">
              <div className="info">
                <h3 className="bg-clip-text text-transparent bg-mainGradiant font-bold text-[28px] mb-6 leading-[39.2px]">
                  {cardOne.title}
                </h3>
                {cardOne.content.map((item, idx) => (
                  <p
                    key={idx}
                    className={`${
                      item.space && "mb-5"
                    } text-lg opacity-80 leading-[25.2px]`}
                  >
                    {item.info && (
                      <strong className="me-1 font-bold">{item.info}</strong>
                    )}
                    {item.desc}
                  </p>
                ))}
              </div>
              <Image
                src={cardOne.img}
                alt={secName}
                className="xl:min-w-[501px] xl:max-w-[501px] lg:min-w-[401px] lg:max-w-[401px] sm:min-w-[501px] sm:max-w-[501px] sm:h-[369px]"
              />
            </div>
          </div>
          <div className="card bg-white rounded-4xl border-2 border-solid border-cardColor py-[30px] px-[50px] shadow-mainShadow">
            <div className="flex lg:flex-nowrap flex-wrap gap-8 items-center lg:justify-start justify-center">
              <Image
                src={cardTwo.img}
                alt={secName}
                className="object-cover xl:min-w-[501px] xl:max-w-[501px] lg:min-w-[401px] lg:max-w-[401px] sm:min-w-[501px] sm:max-w-[501px] sm:h-[369px]"
              />
              <div className="info">
                <h3 className="bg-clip-text text-transparent bg-mainGradiant font-bold text-[28px] mb-6 leading-[39.2px]">
                  {cardTwo.title}
                </h3>
                {cardTwo.content.map((item, idx) => (
                  <p
                    key={idx}
                    className={`${
                      item.space && "mb-5"
                    } text-lg opacity-80 leading-[25.2px]`}
                  >
                    {item.info && (
                      <strong className="me-1 font-bold">{item.info}:</strong>
                    )}
                    {item.desc}
                  </p>
                ))}
              </div>
            </div>
          </div>
          <h2 className="text-38px font-bold bg-clip-text text-transparent bg-mainGradiant leading-[53.2px] text-center lg:w-4/6 md:w-3/4 mx-auto px-4 my-10">
            {subTitle}
          </h2>
          <div className="card bg-white rounded-4xl border-2 border-solid border-cardColor py-[30px] px-[50px] shadow-mainShadow">
            <div className="flex lg:flex-nowrap flex-wrap gap-8 items-center lg:justify-start justify-center">
              <div className="info">
                <h3 className="bg-clip-text text-transparent bg-mainGradiant font-bold text-[28px] mb-6 leading-[39.2px]">
                  {cardThree.title}
                </h3>
                {cardThree.content.map((item, idx) => (
                  <p
                    key={idx}
                    className={`${
                      item.space && "mb-5"
                    } text-lg opacity-80 leading-[25.2px]`}
                  >
                    {item.info && (
                      <strong className="me-1 font-bold">{item.info}</strong>
                    )}
                    {item.desc}
                  </p>
                ))}
              </div>
              <Image
                src={cardThree.img}
                alt={secName}
                className="xl:min-w-[501px] xl:max-w-[501px] lg:min-w-[401px] lg:max-w-[401px] sm:min-w-[501px] sm:max-w-[501px] sm:h-[369px]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardsTemplate;
