import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Tab,
  Tabs,
} from "@nextui-org/react";
import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import arrowLeft from "../../Assets/frond-details/arrow-left.svg";
import dots from "../../Assets/my-fronds/frond/dots.svg";
import editImgIcon from "../../Assets/frond-details/message-edit.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";
import EditFrondImageModal from "./EditFrondImageModal";
import DeleteModal from "components/MyFrondsFuctions/DeleteModal";
import ArchiveModal from "components/MyFrondsFuctions/ArchiveModal";
import LeaveProjectModal from "components/SharedFrondsFunctions/LeaveProjectsModal";
import ShareFrond from "components/MyFrondsFuctions/ShareFrond";
import { toast } from "react-toastify";
import axios from "axios";
import { PopUpContext } from "Context/PopUpContext";

const CardHeader = ({
  cardImg = "",
  cardTitle = "",
  editId,
  editCategory,
  editDesc,
  editStartDate,
  editEndDate,
  editBudget,
  editCurrecy,
  editTags,
  editImportance,
  editLatitude,
  editLongitude,
  editCountry,
  editCity,
  editLocation,
  getFrondData,
  isArchived,
  isShared,
}) => {
  const { frondId } = useParams();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);
  const [editImagenModal, setEditImageModal] = useState(false);

  const [itemKey, setItemKey] = useState(null);

  const [archiveFrondModal, setArchiveFrondModal] = useState(false);
  const [deleteFrondModal, setDeleteFrondModal] = useState(false);
  const [leaveFrondModal, setLeaveFrondModal] = useState(false);
  const [shareFrondModal, setShareFrondModal] = useState(false);

  useEffect(() => {
    setActiveLink(pathname.split("/")[2]);
  }, [pathname, activeLink]);

  const links = [
    {
      title: "About",
      link: `/frond/about/${frondId}`,
    },
    {
      title: "Scrapbook",
      link: `/frond/scrapbook/images/${frondId}`,
    },
    {
      title: "People",
      link: `/frond/people/${frondId}`,
    },
    {
      title: "Tasklist",
      link: `/frond/tasklist/all/${frondId}`,
    },
    {
      title: "Updates",
      link: `/frond/updates/${frondId}`,
    },
    {
      title: "Discover",
      link: `/frond/discover/${frondId}`,
    },
    {
      title: "Settings",
      link: `/frond/settings/${frondId}`,
    },
  ];

  const navigate = useNavigate();

  const { setIsEditTitle, setIsArchiveProject } = useContext(PopUpContext);

  useEffect(() => {
    if (itemKey === "delete") {
      setDeleteFrondModal(true);
    } else if (itemKey === "archive") {
      setArchiveFrondModal(true);
    } else if (itemKey === "leave") {
      setLeaveFrondModal(true);
    } else if (itemKey === "share") {
      setShareFrondModal(true);
    } else if (itemKey === "unArchive") {
      unArchiveFrond();
    }
  }, [itemKey]);

  const openModal = (key) => {
    setItemKey(key);
  };

  const unArchiveFrond = async () => {
    let authToken = localStorage.getItem("token");
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/restore`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Project restored successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsArchiveProject(true);
      setTimeout(() => {
        setIsArchiveProject(false);
      }, 1000);

      setIsEditTitle(true);

      setTimeout(() => {
        setIsEditTitle(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  return (
    <>
      <div className="frond-title mb-5">
        <div className="card-img relative">
          <div
            className={`absolute inset-0 ${
              cardImg ? "bg-frondDetails" : "bg-textColor"
            } z-20 rounded-[20px]`}
          ></div>
          <div className="absolute top-5 phone:left-5 phone:right-5 left-2.5 right-2.5 flex justify-between items-center z-30 phone:gap-[30px] gap-2.5">
            <div className="flex phone:gap-2.5 gap-[5px] items-center overflow-hidden">
              <button
                onClick={() => navigate(-1)}
                className="phone:min-w-[24px] phone:min-h-[24px] min-w-[12px] min-h-[12px]"
              >
                <Image
                  src={arrowLeft}
                  alt="Arrow"
                  className="phone:min-w-[24px] phone:min-h-[24px] min-w-[12px] min-h-[12px]"
                />
              </button>
              <span className="phone:text-lg text-[12px] font-bold leading-[20.7px] text-white whitespace-nowrap">
                {cardTitle ? cardTitle : "-"}
              </span>
            </div>
            <div className="flex gap-2.5 items-center min-w-fit">
              <button
                onClick={() => setEditImageModal(true)}
                className="flex gap-2.5 items-center shadow-editImgShadow"
              >
                <Image
                  src={editImgIcon}
                  alt="Edit Image"
                  className="phone:min-w-[24px] phone:min-h-[24px] phone:max-w-[24px] phone:max-h-[24px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                />
                <span className="leading-[18.4px] text-white phone:text-base text-[12px]">
                  Edit Image
                </span>
              </button>
              <Dropdown
                classNames={{
                  content: `${
                    !isArchived
                      ? "min-w-[88px] max-w-[88px]"
                      : "min-w-[92px] max-w-[92px]"
                  } py-0 px-[5px] rounded-[10px]`,
                }}
                placement="left-start"
              >
                <DropdownTrigger>
                  <button className="w-[24px] h-[24px] relative z-40">
                    <img
                      src={dots}
                      alt="Dots"
                      className="phone:min-w-[24px] phone:max-w-[24px] phone:min-h-[24px] phone:max-h-[24px] min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]"
                    />
                  </button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Frond Actions"
                  onAction={(key) => openModal(key)}
                >
                  {!isArchived && (
                    <DropdownItem
                      textValue="edit frond"
                      key="archive"
                      className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                    >
                      Archive
                    </DropdownItem>
                  )}
                  {!isShared && (
                    <DropdownItem
                      textValue="edit frond"
                      key="share"
                      className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                    >
                      Share
                    </DropdownItem>
                  )}
                  {isArchived && (
                    <DropdownItem
                      textValue="edit frond"
                      key="unArchive"
                      className="text-cardGrayColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                    >
                      Unarchive
                    </DropdownItem>
                  )}
                  {isShared && (
                    <DropdownItem
                      textValue="edit frond"
                      key="leave"
                      className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                      color="danger"
                    >
                      Leave
                    </DropdownItem>
                  )}
                  {!isShared && (
                    <DropdownItem
                      key="delete"
                      className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                      color="danger"
                    >
                      Delete
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          {cardImg ? (
            <Image
              classNames={{ wrapper: "min-w-full z-10 rounded-[20px]" }}
              src={cardImg}
              alt={cardTitle}
              className="w-full phone:h-[231px] h-[160px] rounded-[20px] bg-left-top bg-no-repeat"
            />
          ) : (
            <div className="flex justify-center phone:h-[231px] h-[160px] items-center">
              <Image
                classNames={{ wrapper: "z-30" }}
                src={placholderIcon}
                alt={cardTitle}
                className="w-[46px] h-[50px]"
              />
            </div>
          )}
        </div>
        <div className="frond-actions relative flex justify-evenly items-center after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-grayBorder after:z-[10] no-focus">
          <Tabs
            selectedKey={activeLink}
            variant="underlined"
            aria-label="Tabs"
            classNames={{
              base: "w-full",
              tabList: "w-full justify-evenly relative z-20",
              tab: "w-fit",
              tabContent:
                "text-cardGrayColor font-normal transition-all duration-100 phone:text-base text-xs leading-[18.4px] group-data-[selected=true]:font-bold group-data-[selected=true]:text-transparent group-data-[selected=true]:bg-mainGradiant group-data-[selected=true]:bg-clip-text",
              cursor:
                "bg-mainGradiant z-20 bottom-[-4px] rounded-[6px] h-[2px] w-full",
            }}
          >
            {links.map((item) => (
              <Tab
                key={item.title.toLowerCase()}
                title={item.title}
                as={Link}
                to={item.link}
              />

              // <Link
              //   to={item.link}
              //   key={idx}
              //   ref={(el) => (linkRefs.current[idx] = el)}
              //   className={`flex justify-evenly items-center p-3 ${
              //     activeLink === item.link ? "text-mainGradiant" : ""
              //   }`}
              // >
              //   <span
              //     className={`${
              //       activeLink === item.link
              //         ? "bg-clip-text text-transparent bg-mainGradiant font-bold"
              //         : "text-cardGrayColor font-normal"
              //     } transition-all duration-100 text-base leading-[18.4px]`}
              //   >
              //     {item.title}
              //   </span>
              // </Link>
            ))}
          </Tabs>
        </div>
      </div>
      {editImagenModal && (
        <EditFrondImageModal
          isOpen={editImagenModal}
          onClose={() => {
            setEditImageModal(false);
          }}
          editId={editId}
          editTitle={cardTitle}
          editCategory={editCategory}
          editImage={cardImg}
          editBudget={editBudget}
          editCurrecy={editCurrecy}
          editDesc={editDesc}
          editStartDate={editStartDate}
          editEndDate={editEndDate}
          editImportance={editImportance}
          editLatitude={editLatitude}
          editLongitude={editLongitude}
          editCountry={editCountry}
          editCity={editCity}
          editLocation={editLocation}
          editTags={editTags}
          getFrondData={getFrondData}
        />
      )}
      {itemKey === "archive" && (
        <ArchiveModal
          isOpen={archiveFrondModal}
          onClose={() => {
            setArchiveFrondModal(false);
          }}
          frondId={frondId}
          flag={true}
        />
      )}
      {itemKey === "delete" && (
        <DeleteModal
          isOpen={deleteFrondModal}
          onClose={() => {
            setDeleteFrondModal(false);
          }}
          flag={true}
          frondId={frondId}
        />
      )}
      {itemKey === "leave" && (
        <LeaveProjectModal
          isOpen={leaveFrondModal}
          onClose={() => {
            setLeaveFrondModal(false);
          }}
          frondId={frondId}
          flag={true}
        />
      )}
      {itemKey === "share" && (
        <ShareFrond
          isOpen={shareFrondModal}
          onClose={() => {
            setShareFrondModal(false);
          }}
          isModalOpen={true}
          frondId={frondId}
        />
      )}
    </>
  );
};

export default CardHeader;
