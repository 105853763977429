import { createContext, useState } from "react";

export let LoadingContext = createContext();

export default function LoadingContextProvider({ children }) {
  const [isSidebarLoaded, setIsidebarLoaded] = useState(false);
  const [isTimelineLoaded, setIsTimelineLoaded] = useState(false);
  const [isTrendingLoaded, setIsTrendingLoaded] = useState(false);
  const [isForYouLoaded, setIsForYouLoaded] = useState(false);
  const [isInterestsLoaded, setIsInterestsLoaded] = useState(false);

  return (
    <LoadingContext.Provider value={{ isSidebarLoaded, setIsidebarLoaded, isTimelineLoaded, setIsTimelineLoaded, isTrendingLoaded, setIsTrendingLoaded, isForYouLoaded, setIsForYouLoaded, isInterestsLoaded, setIsInterestsLoaded }}>
      {children}
    </LoadingContext.Provider>
  );
}
