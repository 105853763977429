import React from "react";
import qr from "../../Assets/landing/goals/qr.png";
import cardImg1 from "../../Assets/landing/goals/1.png";
import cardImg2 from "../../Assets/landing/goals/2.png";
import cardImg3 from "../../Assets/landing/goals/3.png";
import { Image } from "@nextui-org/react";

const Goals = () => {
  return (
    <section className="goals pt-12 relative">
      <div className="container xl:px-20 px-6 mx-auto relative z-20">
        <div className="goals-card flex lg:justify-between justify-center lg:flex-nowrap flex-wrap gap-10 items-center py-10 px-16 bg-[#424C9B1A] shadow-mainShadow border-2 border-solid border-cardColor rounded-4xl">
          <div className="content">
            <h2 className="text-38px font-bold text-textColor leading-[43.7px] mb-6 lg:text-left text-center xl:w-4/6">
              Scan to download frond and start planning now!
            </h2>
            <div className="qr-box sm:w-[399px] lg:mx-0 mx-auto bg-white rounded-4xl p-2.5 border-2 border-solid border-cardColor flex items-center gap-2.5">
              <Image
                src={qr}
                alt="QR Code"
                className="min-w-[128px] h-[128px] object-cover"
              />
              <p className="qr-desc text-lg font-normal text-black opacity-75">
                Start your plans for home, ventures, events, escapes and more
                ...
              </p>
            </div>
          </div>
          <div className="goals-imgs sm:min-w-[401px] max-w-[401px] h-[279px]">
            <div className="layout flex flex-col gap-y-[13px] justify-stretch w-full h-full">
              <div className="w-full h-[149px] rounded-20px gradiantBorder smallBorder">
                <img
                  src={cardImg1}
                  alt="Card Image"
                  className="shadow-goalsShadow size-full object-cover rounded-20px"
                />
              </div>
              <div className="row flex max-w-full gap-x-[11px] justify-between items-center">
                <img
                  src={cardImg2}
                  alt="Card Image"
                  className="shadow-goalsShadow sm:w-[195px] w-[48%] h-[117px] rounded-20px gradiantBorder smallBorder"
                />
                <img
                  src={cardImg3}
                  alt="Card Image"
                  className="shadow-goalsShadow sm:w-[195px] w-[48%] h-[117px] rounded-20px gradiantBorder smallBorder"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Goals;
